.trial {
  padding: var(--spacing-xxl) var(--spacing-xxxl);
  background-color: var(--color-global-background-lighter);
  border: 1px solid var(--color-neutral-border-light);
}

.trial-container {
  margin: var(--spacing-xxl) auto var(--spacing-l);
}

.trial__header {
  text-align: center;
}

.trial__title {
  @include font-sans-xl-strong;
  margin-bottom: var(--spacing-xxs);
}

.trial__subtitle {
  @include font-sans-s-default;
  max-width: 36rem;
  margin: auto;
  padding: 0 2rem;
}

.trial__form {
  display: flex;
  flex-direction: column;

  &.-email {
    margin-top: var(--spacing-xl);
  }

  &.-contact {
    .iti__selected-flag {
      background-color: var(--color-neutral-surface-lighter);
    }

    .trial__help {
      margin: var(--spacing-xs) 0 var(--spacing-xl);

      &.-error {
        margin-top: 0;
      }
    }
  }

  .formfield {
    margin-bottom: 0;
  }

  .trial__optional-field {
    margin-bottom: var(--spacing-xxxl);
  }
}

.trial__formfield {
  &.-error {
    margin-bottom: var(--spacing-xxxl);
  }

  &.-spaced {
    margin-bottom: var(--spacing-xxl);

    &:has(.select) {
      label {
        margin-bottom: var(--spacing-xxs);
      }
    }
  }

  .trial__help {
    margin-top: 0;
  }

  .formfield.-witherror {
    margin-bottom: 0;
  }
}

.trial__form-error {
  order: 0;
  margin-bottom: var(--spacing-xxl);
}

.trial__progress {
  margin-bottom: var(--spacing-m);
}

.trial__steps {
  display: flex;
  justify-content: center;

  a {
    text-decoration: none;

    &.-active,
    &.-completed,
    &.-previous {
      .trial__step {
        cursor: pointer;
      }

      &:hover,
      &:active {
        .trial__step-details {
          .trial__counter {
            background-color: var(--color-global-state-hover-surface-light);
            border: 1px solid var(--color-global-state-hover-border-inverted);
          }
        }
      }

      &:hover {
        .trial__step-details {
          border: 1px solid var(--color-global-state-hover-border-default);
          background-color: var(--color-global-state-hover-surface-default);

          .trial__counter {
            background-color: var(--color-global-state-hover-surface-light);
            color: var(--color-global-state-hover-text-shape-default);
            border: 1px solid var(--color-global-state-hover-border-inverted);
          }

          p {
            color: var(--color-neutral-text-shape-contrast-default);
          }
        }
      }

      &:active {
        .trial__step-details {
          border: 1px solid var(--color-global-state-hover-border-dark);
          background-color: var(--color-global-state-hover-surface-dark);

          .trial__counter {
            background-color: var(--color-global-state-hover-surface-light);
            color: var(--color-global-state-hover-text-shape-dark);
            border: 1px solid var(--color-global-state-hover-border-inverted);
          }

          p {
            color: var(--color-global-state-hover-text-shape-inverted);
          }
        }
      }
    }

    &.-active,
    &.-completed {
      .trial__step {
        .trial__counter {
          border: 1px solid var(--color-brand-abo-border-default);
          background-color: var(--color-brand-abo-surface-default);
          color: var(--color-neutral-text-shape-default);
        }

        p {
          color: var(--color-neutral-text-shape-dark);
        }
      }
    }

    &.-previous {
      .trial__step {
        .trial__counter {
          background-color: var(--color-neutral-surface-dark);
          color: var(--color-neutral-text-shape-inverted);
        }

        p {
          color: var(--color-neutral-text-shape-default);
        }
      }
    }

    &.-inactive {
      pointer-events: none;
    }
  }
}

.trial__step {
  display: flex;
  align-items: center;
}

.trial__step-details {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  min-width: 20rem;
  border-radius: var(--radius-max);
  border: 1px solid var(--color-neutral-border-light);
  background-color: var(--color-neutral-surface-lighter);
  padding: var(--spacing-xs) var(--spacing-l) var(--spacing-xs) var(--spacing-xs);

  .trial__counter {
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-max);
    border: 1px solid var(--color-global-state-disabled-border-default);
    background-color: var(--color-global-state-disabled-surface-default);
    color: var(--color-global-state-disabled-text-shape-default);
    font: var(--font-condensed-body-s-strong);
  }

  p {
    font: var(--font-detail-l-strong);
    color: var(--color-global-state-disabled-text-shape-default);
  }
}

.trial__options {
  display: flex;
  gap: var(--spacing-xl);
  margin-top: var(--spacing-xs);

  &.-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &.-spaced {
    margin-bottom: var(--spacing-xxxl);
  }
}

.trial__option {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--spacing-xs);
  margin-left: 0 !important;
}

.trial__step-separator {
  height: 1px;
  min-width: 2.4rem;
  background-color: var(--color-neutral-border-default);
}

.trial__legal-text,
.trial__help {
  @include font-sans-m-default;
  color: var(--color-neutral-text-shape-light);
  line-height: 1.6;
}

.trial__help {
  &.-options {
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-xxxl);
  }
}

.trial__help-link {
  @include font-sans-m-link;

  &:hover {
    color: var(--color-global-state-hover-text-shape-default);
  }
}

.trial__legal-text {
  margin-top: var(--spacing-l);

  a {
    @include font-sans-m-link;

    &:hover {
      color: var(--color-global-state-hover-text-shape-default);
    }
  }
}

.trial__editions {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-s);
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xxl)
}

.trial__editions-advice {
  @include font-sans-s-default;
  display: flex;
  gap: var(--spacing-xxs);
  margin-top: var(--spacing-s);
}

.trial__editions-advice-icon {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  color: var(--color-neutral-text-shape-light);
}

.form__footer.-trial {
  color: var(--color-neutral-text-shape-light);
  justify-content: flex-start;
  margin-top: var(--spacing-xl);

  a {
    @include font-sans-m-link;
  }
}

.trial__edition-chip {
  display: flex;
  height: fit-content;
}

.preview-block.-edition {
  background-color: var(--color-neutral-surface-contrast-darker);
  color: var(--color-neutral-text-shape-contrast-default);
  @include font-sans-m-default;

  &.-disappearing {
    display: none;
  }
}

.edition-preview__name {
  @include font-edition-xl;
}

.edition-preview__subtitle {
  color: var(--color-brand-primary-text-shape-default);
  @include font-sans-l-strong;
  margin-bottom: .5rem;
}

.edition-preview__tags {
  @include font-sans-s-default;
}

.trial__button {
  width: 100%;
  margin-top: var(--spacing-xl);
}

@media (max-width: $breakpoint-s) {
  .trial__progress {
    max-width: 48rem;
    margin-inline: auto;
  }

  .trial__steps {
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 0;
    z-index: 1;

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background-color: var(--color-neutral-border-default);
      z-index: 1;
    }

    a {
      &.-active {
        &:not(:last-of-type) {
          flex: 1;
        }

        &:last-of-type {
          margin-left: auto;
        }
      }

      &:not(.-active) {
        .trial__step {
          .trial__step-details {
            padding: 0;
          }

          p {
            display: none;
          }
        }
      }
    }

    .trial__step {
      position: relative;
      /* represents line progression on stepper */
      z-index: 4;
    }

    .trial__step-details {
      min-width: auto;
      padding: var(--spacing-s) var(--spacing-xl) var(--spacing-s) var(--spacing-s);
    }
  }

  .trial {
    padding: var(--spacing-m) var(--spacing-xl);
    max-width: 42.8rem;
    margin: auto;

    .form__input.-size-l-m[type="text"],
    .form__input.-size-l-m[type="password"],
    .form__input.-size-l-m[type="email"],
    .form__input.-size-l-m[type="tel"] {
      height: 4rem;
    }
  }

  .trial-container {
    width: 42.8rem;
  }
}

@media (max-width: $breakpoint-mobile) {
  .trial-container {
    width: auto;
  }

  .trial__progress {
    padding-inline: 0;
  }

  .trial__title {
    @include font-sans-l-strong;
  }

  .trial__subtitle {
    @include font-sans-l-default;
  }

  .trial__editions {
    gap: var(--spacing-m);
  }

  .trial__form.-contact {
    .trial__help {
      margin-bottom: var(--spacing-xxl);
    }
  }
}
