.read-more__button-container {
  padding-block: var(--spacing-xl);

  &:not(.-width-stable) {
    min-width: 125px;
    display: flex;
    flex-direction: column;
  }
}

.htmx-request {
  .read-more__text {
    display: none;
  }
}

@media (max-width: $breakpoint-s) {
  .read-more__button-container:not(.-width-stable) {
    min-width: 107px;
  }
}
