.layout-main.-alerts {
  display: flex;
  background-color: var(--color-neutral-surface-lighter);
  height: 100vh;
}

.main-column.-alerts-empty {
  display: flex;
  justify-content: center;
}

.alerts__container {
  padding: var(--spacing-xl);

  &.-small {
    background-color: var(--color-global-background-lighter);
    border-radius: var(--radius-rounded);
  }
}

// list

.alerts {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 45rem;
  flex-shrink: 0;
  z-index: 1;
  box-shadow: 2px 0px 22px 0px rgba(217, 216, 225, 0.80);
}

.alerts__heading, .alert-embedded__header {
  min-height: 5.6rem;
}

.alerts__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-xs);
  padding: var(--spacing-s) var(--spacing-xxl);
  border-bottom: 1px solid var(--color-neutral-border-light);
}

.alerts__title {
  @include font-sans-l-strong;
  color: var(--color-neutral-text-shape-default);
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
}

.alerts__unread-counter {
  @include font-sans-l-default;
  color: var(--color-neutral-text-shape-light);
}

.alerts__list {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  background: var(--color-global-background-light);
  overflow-y: auto;

  margin: 0;
  padding: 0;

  &:has(.alerts__emptystate) {
    padding: var(--spacing-xxxl);
  }
}

.alerts__sublist-title {
  height: 4.4rem;
  max-width: 96rem;
  padding: var(--spacing-m) var(--spacing-xxl);

  position: sticky;
  top: 0;
  z-index: 1;

  border-bottom: 1px solid var(--color-neutral-border-light);
  background: var(--color-neutral-surface-lighter);
  color: var(--color-neutral-text-shape-light);
  @include font-sans-m-semi;
}

.alerts__item {
  padding: var(--spacing-m) var(--spacing-xl) var(--spacing-m) var(--spacing-xxl);
  border-bottom: 1px solid var(--color-neutral-border-light);
  cursor: pointer;
  list-style-type: none;

  &.-unread {
    .alerts__item-unread-dot {
      display: block;
    }
  }

  &.-selected {
    background-color: var(--color-global-state-hover-surface-dark);

    .alerts__item-title,
    .alerts__item-subtitle {
      color: var(--color-global-state-hover-text-shape-inverted);
    }
  }

  &:active {
    background: var(--color-global-state-hover-surface-dark);

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--color-global-state-hover-border-dark);
    }
  }

  &:hover {
    background-color: var(--color-global-state-hover-surface-default);

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--color-global-state-hover-surface-default);
    }
  }

  &:active,
  &:hover,
  &.-selected {
    .alerts__item-unread-dot {
      background-color: var(--color-global-state-hover-text-shape-inverted);
    }

    .alerts__item-title,
    .alerts__item-subtitle {
      color: var(--color-global-state-hover-text-shape-inverted);
    }

    .alerts__item-keyword {
      background: var(--color-global-state-hover-surface-light);
      color: var(--color-global-state-hover-text-shape-dark);
    }
  }
}

.alerts__item-link {
  display: flex;
  gap: var(--spacing-m);
  text-decoration: none;
}

.alerts__button {
  margin: var(--spacing-m) var(--spacing-xl);
}

.alerts__item-unread-wrap {
  display: flex;
  flex-direction: column;
  width: var(--spacing-s);
  padding-top: 0.9rem;
  gap: var(--spacing-m);
  align-self: stretch;
}

.alerts__item-unread-dot {
  display: none;
  flex-shrink: 0;
  width: .8rem;
  height: .8rem;
  border-radius: var(--radius-max);
  background-color: var(--color-global-state-hover-text-shape-default);
}

.alerts__item-content-wrap {
  display: flex;
  padding: var(--spacing-xxxxs) var(--spacing-none);
  flex-direction: column;
  align-items: stretch;
  gap: var(--spacing-xxxs);
  flex: 1 0 0;
  overflow: hidden;

  @include font-sans-m-semi;
  color: var(--color-neutral-text-shape-default);
}

.alerts__item-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: var(--spacing-xxs);
}

.alerts__item-subtitle {
  opacity: 0.6;
}

.alerts__item-keywords-wrap {
  display: flex;
  padding: var(--spacing-xxs) var(--spacing-none);
  gap: var(--spacing-xs);
  flex-wrap: wrap;
}

.alerts__item-keyword {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xxs);
  padding: 0 var(--spacing-xs);
  border-radius: var(--radius-min);
  background: var(--color-global-post-it-surface-light);
  color: var(--color-global-post-it-text-shape-default);

  span {
    @include font-sans-m-default;
    color: var(--color-global-post-it-text-shape-light);
  }
}

// old detail

.alerts__emptystate-placeholder {
  padding: var(--spacing-xxxl) var(--spacing-xl);
  display: flex;
  justify-content: center;
}

.alerts__detail-container {
  flex-grow: 1;
  overflow-y: auto;
  background-color: var(--color-neutral-surface-light);
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#alerts__htmx-indicator.htmx-request {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &::after {
    animation: loader 1.1s infinite linear;
    content: url("/home/runner/work/contexte/contexte/apps/monitoring/static_src/monitoring/../../../ui/static_src/ui/base/images/spinner.svg");
    display: block;
    height: 5rem;
    margin: 0 auto;
    width: 5rem;
  }
}

#alert {
  &:empty {
    .alerts__emptystate-placeholder {
      display: block;
    }
  }

  &:not(:empty) {
    height: 100%;

    & + .alerts__emptystate-placeholder {
      display: none;
    }
  }
}

.alert-embedded {
  background-color: var(--color-neutral-surface-light);
  width: 100%;
  height: 100%;
}

.alert-embedded__toolbar {
  display: none;
}

.alert-embedded__back-title {
  @include font-sans-m-strong;
}

.alert-embedded__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-s);
  padding: var(--spacing-xs) var(--spacing-xxl);
  border-bottom: 1px solid var(--color-neutral-border-light);
  background: var(--color-global-background-light);

  button {
    flex-shrink: 0;
  }
}

.alert-embedded__subject {
  @include font-sans-m-semi;
  color: var(--color-neutral-text-shape-default);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.alert__embedded-detail {
  width: 100%;
  height: calc(99% - var(--spacing-56)); /* height of .alert-embedded__header */
  padding: var(--spacing-xxl);
}

@media screen and (max-width: $breakpoint-m) {
  .alerts {
    width: 36rem;
  }

  .alerts__title {
    gap: var(--spacing-xs);
  }

  .alerts__unread-counter {
    @include font-sans-m-default;
  }
}

@media screen and (max-width: $breakpoint-m) and (min-width: $breakpoint-s) {
  .alerts__heading {
    padding-right: var(--spacing-l);
  }

  .alert__section-block-watch {
    min-width: 4.2rem !important;
    width: 4.2rem;
    aspect-ratio: 1;

    .watch__label {
      display: none !important;
    }

    .watch__icon {
      width: 2em;
      height: 2em;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .alerts__heading,
  .alerts__sublist-title {
    padding-inline: var(--spacing-xl);
  }

  .alert__section-block-watch {
    min-width: 4.2rem !important;
    width: 4.2rem;
    aspect-ratio: 1;

    .watch__label {
      display: none !important;
    }

    .watch__icon {
      width: 2em;
      height: 2em;
    }
  }
}

@media screen and (max-width: $breakpoint-s) {
  .alerts {
    width: 100%;
  }

  .alerts__alert {
    display: flex;
    flex-direction: column;
  }

  .alerts__alert-subject {
    width: unset;
  }

  .alerts__detail-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: var(--spacing-48);
    left: 0;
    transition: left 0.2s ease;
    z-index: 2;

    .alerts__container {
      display: none;
    }

    /* Hide empty state */
    &:has(#alert):has(.alerts__container) {
      width: 0;
      height: 0;
    }
  }

  .alert-embedded__toolbar {
    display: flex;
    align-items: center;
    gap: var(--spacing-s);
    padding: var(--spacing-s) var(--spacing-xl);
    border-bottom: 1px solid var(--color-neutral-border-light);
  }

  button.-secondary.-rounded.-square.-size-s.alert-embedded__close {
    display: inline;
    background-color: var(--color-neutral-surface-contrast-darker);
    color: var(--color-neutral-text-shape-contrast-default);
    border: 1px solid var(--color-neutral-border-contrast-default);
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .alerts__item {
    padding-left: var(--spacing-xl);
  }

  .alert__embedded-detail {
    padding: var(--spacing-none);
  }
}

// detail

.alert__container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxl);
  overflow-y: auto;
  padding: var(--spacing-xxxxl);
  padding-top: var(--spacing-xxxl);

  border-radius: var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-lighter);
  background: var(--color-global-background-lighter);
}

.alert__section-block-header,
.alert__header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

.alert__header {
  padding-bottom: var(--spacing-xxl);
  border-bottom: 1px solid var(--color-neutral-border-light);
}

.alert-detail__section-title-wrap,
.alert__title-wrap {
  display: flex;
  gap: var(--spacing-xl);
  justify-content: space-between;
  align-items: center;
}

.alert__title-wrap {
  margin-right: var(--spacing-l);
}

.alert__title {
  color: var(--color-neutral-text-shape-default);
  @include font-sans-xxl-strong;
}

.alert__settings {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  color: var(--color-neutral-text-shape-default);

  @include font-sans-xl-default;
}

.alert__summary {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
}

.alert__summary-title {
  color: var(--color-feedback-info-text-shape-default);
  @include font-sans-xl-default;

  b {
    @include font-sans-xl-strong;
  }
}

.alert__summary-keywords {
  display: flex;
  gap: var(--spacing-s);
  align-items: flex-start;
  flex-wrap: wrap;
}

.alert__summary-keyword {
  padding: var(--spacing-s) var(--spacing-xxs) var(--spacing-s) var(--spacing-xs);
  border-radius: var(--radius-min);
  background: var(--color-global-post-it-surface-default);
  color: var(--color-global-post-it-text-shape-default);
}

.alert__section-block {
  display: flex;
  gap: var(--spacing-xxl);
  flex-direction: column;
  align-items: stretch;

  padding: var(--spacing-xxl);
  padding-bottom: var(--spacing-xxxl);

  border-radius: var(--radius-min);
  border: 1px solid var(--color-feedback-info-border-lighter);
  background: var(--color-feedback-info-surface-lighter);
}

.alert__section-block-watch {
  min-width: 16rem;
  min-height: 4rem;
  padding: var(--spacing-xs) var(--spacing-m);
  gap: var(--spacing-none);

  border-radius: var(--radius-min);
  border: 1px solid var(--color-neutral-border-light);

  color: var(--color-neutral-text-shape-default);
  @include font-interface-s-semi;

  .watch__label {
    padding-inline: var(--spacing-xs);
  }
}

.alert-detail__section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xl);
  padding: var(--spacing-xl);
  border-radius: var(--radius-min);
  border: 1px solid var(--color-neutral-border-lighter);
  background: var(--color-neutral-surface-lighter);
}

.alert-detail__section-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--spacing-s);
}

.alert-detail__section-name-wrap {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  align-self: stretch;
  flex: 1 0 0;
}

.alert-detail__section-index {
  align-self: flex-start;
  color: var(--color-neutral-text-shape-default);
  @include font-sans-xl-default;
}

.alert-detail__section-name {
  color: var(--color-neutral-text-shape-default);
  @include font-sans-xl-strong;
}

.alert-detail__section-content {
  display: flex;
  padding-bottom: var(--spacing-xl);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xl);
  align-self: stretch;
}

.alert-detail__occurence-type {
  color: var(--color-neutral-text-shape-default);
  @include font-sans-m-semi;
}

.alert-detail__section-occurence {
  max-height: 9.6rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;

  align-self: stretch;
  color: var(--color-neutral-text-shape-default);
  @include font-sans-m-default;
}
