@mixin person-font-color($default-color, $active-color) {
  color: $default-color;

  .person-link:hover &,
  .person-link:focus-visible &,
  .person-link:active & {
    color: $active-color;
  }
}

.directory-header {
  background: var(--color-global-background-paywall-default);
  color: var(--color-neutral-text-shape-contrast-default);
}

.directory-header__column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xxl);
}

.directory-header__heading {
  display: flex;
  gap: var(--spacing-s);
  @include font-serif-xxl-strong;
}

.directory-header__subheading {
  @include font-sans-m-default;
}

.person-directory__container {
  margin-top: var(--spacing-xxxl);
}

.person-directory {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxxl);
}

.person-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: var(--color-neutral-surface-lighter);
  border-bottom: 1px solid var(--color-neutral-border-lighter);
  @include person-font-color(var(--color-neutral-text-shape-default), var(--color-neutral-text-shape-contrast-default));

  .person-link:hover &,
  .person-link:focus-visible & {
    border-bottom: var(--color-global-state-hover-border-dark);
    background: var(--color-global-state-hover-surface-default);
  }

  .person-link:active & {
    border-bottom: var(--color-global-state-hover-border-dark);
    background: var(--color-global-state-hover-surface-dark);
  }
}

.person-row__column {
  padding: var(--spacing-xl);
  justify-content: center;
  align-items: flex-start;

  &.-left,
  &.-mid {
    border-right: 1px solid var(--color-neutral-border-lighter);

    .person-link:hover &,
    .person-link:focus-visible &,
    .person-link:active & {
      border-right: 1px solid var(--color-global-state-hover-border-dark);
    }
  }

  &.-left {
    flex: 0 0 auto;
  }

  &.-mid,
  &.-right {
    flex: 1 0 0;
  }
}

.person-row__avatar {
  border-radius: var(--radius-max);
  border: 1px solid var(--color-neutral-border-lighter);
  display: inline-block;
  box-sizing: content-box;
  width: 100%;
  max-width: 4.8rem;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;

  .person-row__avatar-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &.-default-avatar {
    border: var(--color-global-state-hover-border-light);
    background: var(--color-global-state-hover-surface-light);

    .person-row__avatar-img {
      padding-top: var(--spacing-l);
      padding-bottom: var(--spacing-xxs);
      fill: var(--color-global-state-hover-text-shape-dark);
    }
  }
}

.person-row__name {
  @include font-sans-m-semi;
}

.person-row__political-group {
  @include font-sans-m-default;
}

@media (max-width: $breakpoint-m) {
  .directory-header__column {
    gap: var(--spacing-xl);
    padding-block: var(--spacing-s);
  }
}

@media (max-width: $breakpoint-s) {
  .directory-header__column {
    gap: var(--spacing-xs);
  }

  .directory-header__heading {
    @include font-serif-xl-strong;
  }

  .directory-header__subheading {
    @include font-sans-s-default;
  }

  .person-row__column {
    padding: var(--spacing-s);
  }

  .person-row__avatar {
    max-width: 3.2rem;
  }

  .person-row__name {
    @include font-sans-s-semi;
  }

  .person-row__political-group {
    @include font-sans-s-default;
  }
}

@media (max-width: $breakpoint-mobile) {
  .directory-header__column {
    padding-block: 0;
  }

  .directory-header__subheading {
    @include font-sans-s-default;
  }
}
