.inline-banner {
  display: inline-flex;
  padding: var(--spacing-s) var(--spacing-s) var(--spacing-l) var(--spacing-s);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-s);

  @include font-sans-m-default;

  &.-success {
    background: var(--color-feedback-success-surface-lighter);
    color: var(--color-feedback-success-text-shape-default);
  }

  &.-danger {
    background: var(--color-feedback-danger-surface-light);
    color: var(--color-feedback-danger-text-shape-dark);
  }

  &.-alert {
    background: var(--color-feedback-danger-surface-light);
    color: var(--color-feedback-alert-text-shape-dark);
  }

  &.-info {
    background: var(--color-feedback-info-surface-light);
    color: var(--color-feedback-info-text-shape-dark);
  }
}
