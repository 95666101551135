.account-stage {
  padding-block: var(--spacing-xxxl);
}

.account__header {
  background: var(--color-neutral-surface-contrast-dark);
  border-bottom: 1px solid var(--color-neutral-border-contrast-light);
}

.account__disabled-container {
  background: var(--color-neutral-surface-default);
  padding: var(--spacing-xxl);
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 32.5rem;
}

.account__disabled-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 11.6rem;
  gap: var(--spacing-xl);
}

.account__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.2rem;
}

.account__hello {
  @include font-sans-xl-strong;
  color: var(--color-neutral-text-shape-contrast-default);
}

.account__title-wrap {
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-s);

  .account__title,
  .account__subtitle {
    margin: unset;
  }

}

.account__title {
  @include font-sans-xl-strong;
  margin-bottom: var(--spacing-s);
}

.account__header-back-button {
  display: none;
  padding: var(--spacing-s);
  color: var(--color-neutral-text-shape-contrast-default);
  background-color: var(--color-neutral-surface-contrast-darker);
  border-radius: 2rem;
  border: 1px solid var(--color-neutral-border-contrast-default);

  &:hover {
    border-color: var(--color-global-state-hover-border-default);
    background: var(--color-global-state-hover-surface-default);
  }

  &:active {
    border-color: var(--color-global-state-hover-border-dark);
    background: var(--color-global-state-hover-surface-dark);
  }
}

.account__button-logout {
  border-color: var(--color-neutral-border-contrast-default);
}

.account__subscription {
  padding: 0;
  margin-bottom: var(--spacing-xl); // 150% of 16px
}

.account__subtitle {
  @include font-sans-m-default;
  margin-top: var(--spacing-s);
  font-weight: normal;
  text-wrap: wrap;
}

.account__form {
  margin-top: var(--spacing-xxl);

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }
}

.account__language-content {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

.account__button {
  margin-block: var(--spacing-xxl);
}

.account__mailto {
  color: var(--color-neutral-text-shape-light);

  a:hover {
    color: var(--color-global-state-hover-text-shape-default);
  }
}

.account__optin[type=checkbox] {
  appearance: none;
  position: relative;
  width: 2.2rem;
  height: 2.2rem;
  cursor: pointer;
}

.account__optin[type=checkbox] {
  margin-right: 4rem;
}

.account__newsletters {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxl);
}

.newsletter-toggle {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: var(--spacing-xxxl);
  align-items: center;
  justify-content: space-between;
  border-radius: var(--radius-rounded);
  padding: var(--spacing-xxl);
  color: var(--color-global-state-disabled-text-shape-default);
  background-color: transparent;
  border: 1px solid var(--color-global-state-disabled-border-default);

  &.-active {
    color: var(--color-neutral-text-shape-default);
    background-color: var(--color-neutral-surface-lighter);
    border-color: var(--color-neutral-border-light);
  }
}

.newsletter-toggle__name {
  @include font-edition-3xl;
  font-size: 2.4rem; /* locally overridden in designs */
}

.newsletter-toggle__wrapper {
  @include font-sans-m-default;
}

.diagnostic__title {
  @include font-sans-xl-strong;
}

.diagnostic__content {
  @include font-sans-m-default;
  margin: var(--spacing-m) 0 var(--spacing-xl);
}

.diagnostic__info {
  font-family: monospace;
  font-size: 1.2rem;
  display: block;
  width: 100%;
  height: 4rem;
  padding: var(--spacing-m);
  margin-bottom: var(--spacing-xl);
}

/* Django Hijack */

.djhj {
  bottom: var(--spacing-xl) !important;

  .djhj-notification {
    background: var(--color-neutral-surface-contrast-dark);
    color: var(--color-neutral-text-shape-contrast-default);
    padding-inline: var(--spacing-xl);
  }

  .djhj-message::before {
    content: ' ';
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    font-weight: 500;
    margin-right: var(--spacing-xxs);
    vertical-align: -0.3rem;
    background: transparent url(/home/runner/work/contexte/contexte/apps/users/static_src/users/account/./images/login-as.svg) center center;
    background-size: contain;
  }

  .djhj-button {
    border: 1px solid transparent;
    color: inherit;
    margin-left: var(--spacing-m);
  }
}


/* RESPONSIVE */

@media (max-width: $breakpoint-m) {
  .account__newsletter {
    display: block;
  }

  .newsletter-toggle {
    padding: var(--spacing-xl);
  }

  .newsletter-toggle__name {
    @include font-edition-xl;
  }

  .account-stage {
    padding-block: var(--spacing-xxl);
  }

  .account__hello {
    @include font-sans-s-semi;
  }

  .account__button {
    margin-block: var(--spacing-xl);
  }

  .account__mailto {
    @include font-sans-s-default;
  }

  .account__newsletters {
    gap: var(--spacing-xl);
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .account__header-back-button {
    display: flex;
    margin-left: calc(var(--spacing-m) * -1);
  }

  .account-stage {
    padding-block: var(--spacing-xl);
  }

  .account-stage .sidenav__link.-active::before {
    content: "";
  }

  .account-stage .sidenav__icon {
    display: inline;
  }

  .account__logout.-hide-on-mobile,
  .account__hello.-hide-on-mobile {
    display: none;
  }
}
