.badge {
  display: inline-flex;
  height: fit-content;
  padding: var(--spacing-xxs) var(--spacing-xxxs);
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: var(--spacing-xxs);

  border-radius: var(--radius-min);

  &:has(svg) {
    padding: var(--spacing-xs) var(--spacing-s) var(--spacing-xs) var(--spacing-xs);
  }

  &.-abo {
    color: var(--color-brand-abo-text-shape-contrast-darker);
    background: var(--color-brand-abo-surface-default);
  }

  &.-abo-contrasted {
    color: var(--color-brand-abo-text-shape-contrast-dark);
    background: var(--color-brand-abo-surface-contrast-lighter);
  }

  &.-new {
    color: var(--color-brand-primary-text-shape-lighter);
    background: var(--color-brand-primary-surface-default);
  }

  &.-active {
    color: var(--color-global-state-hover-text-shape-default);
    background: var(--color-global-state-hover-surface-lighter);
  }

  &.-big {
    height: 2rem;
    padding: 0 var(--spacing-xxs);

    .badge__content {
      @include font-condensed-xs-strong;
    }
  }
}

.badge__icon {
  display: flex;
  width: 1.4rem;
  height: 1.5rem;
}

.badge__content {
  @include font-interface-xxs-semi;
  letter-spacing: 0.055rem;
  text-transform: uppercase;
}

.badge__link {
  @include font-sans-m-semi-link;
  color: var(--color-brand-abo-text-shape-dark);

  &:hover {
    color: var(--color-global-state-hover-text-shape-default);
  }
}

@media (max-width: $breakpoint-m) {
  .badge.-big {
    align-self: unset;
    margin-top: var(--spacing-xs);
  }
}
