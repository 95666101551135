.edition-header {
  padding: var(--spacing-xxl) 0;
  background: var(--color-neutral-surface-contrast-dark);
  color: var(--color-neutral-text-shape-contrast-default);
  max-width: 100vw;
}

.edition-header__title {
  @include font-edition-5xl;
  margin-bottom: var(--spacing-xxl);
}

.edition-header__briefing-title {
  @include font-condensed-l-strong;
  color: var(--color-brand-primary-text-shape-default);
}

.edition-header__briefing-date {
  @include font-condensed-l-strong;
  color: var(--color-neutral-text-shape-contrast-default);
}

.edition-header__briefing-subject {
  @include font-sans-s-default;
  color: var(--color-neutral-text-shape-contrast-default);
  margin: var(--spacing-xxs) 0 var(--spacing-l);
}

.edition-header__briefing-link {
  display: none;
  @include font-sans-s-link;
  color: var(--color-neutral-text-shape-contrast-light);

  &:focus-visible, &:hover {
    color: var(--color-neutral-text-shape-contrast-default);
  }
}

.edition-header__description {
  @include font-serif-xl-medium;
  margin-bottom: var(--spacing-xxxl);
}

.edition-header__tags-title {
  color: var(--color-brand-primary-text-shape-default);
  text-transform: uppercase;
  @include font-condensed-l-strong;
}

.edition-header__tags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-s);
  margin-top: var(--spacing-l);
}

/* Responsive */

@media screen and (max-width: $breakpoint-m) {
  .edition-header {
    padding: var(--spacing-xl) 0;
  }

  .edition-header__description {
    @include font-serif-l-medium;
  }

  .edition-header__briefing-button {
    display: none;
  }

  .edition-header__briefing-link {
    display: inline-block;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .edition-header__briefing {
    grid-column: 2;
    width: 100%;
    position: relative;
  }

  .edition-header__tags {
    width: calc(100vw - var(--spacing-xl) * 2);
  }
}
