@use "mixins" as *;

/**
 * This file was generated automaticaly using the `extract-figma-fonts.py` script.
 * Please do not modify it by hand.
 *
 * Generated on 06/02/2025 at 13:40:23 by marie-noellefromage
 */

/* Edition helpers */

.font-pouvoirs {
  font-feature-settings: 'ss09' on, 'liga' off !important;
}

.font-energie {
  font-feature-settings: 'ss08' on, 'liga' off !important;
}

.font-numerique {
  font-feature-settings: 'ss07' on, 'liga' off !important;
}

.font-transports {
  font-feature-settings: 'ss08' on, 'liga' off !important;
}

.font-agro {
  font-feature-settings: 'ss09' on, 'liga' off !important;
}

.font-environnement {
  font-feature-settings: 'ss07' on, 'ss09' on, 'liga' off !important;
}

.font-e-sante {
  font-feature-settings: 'ss08' on, 'liga' off !important;
}

.font-sante {
  font-feature-settings: 'ss08' on, 'liga' off !important;
}

.font-tech {
  font-feature-settings: 'ss06' on, 'liga' off !important;
}

.font-medias {
  font-feature-settings: 'ss07' on, 'liga' off !important;
}

/* Fonts helpers */

.font-serif-5xl-strong {
  @include font-serif-5xl-strong;
}

.font-serif-5xl-medium {
  @include font-serif-5xl-medium;
}

.font-serif-4xl-strong {
  @include font-serif-4xl-strong;
}

.font-serif-4xl-medium {
  @include font-serif-4xl-medium;
}

.font-serif-3xl-strong {
  @include font-serif-3xl-strong;
}

.font-serif-3xl-medium {
  @include font-serif-3xl-medium;
}

.font-serif-xxl-strong {
  @include font-serif-xxl-strong;
}

.font-serif-xxl-medium {
  @include font-serif-xxl-medium;
}

.font-serif-xl-strong {
  @include font-serif-xl-strong;
}

.font-serif-xl-medium {
  @include font-serif-xl-medium;
}

.font-serif-xl-default {
  @include font-serif-xl-default;
}

.font-serif-l-medium {
  @include font-serif-l-medium;
}

.font-serif-l-default {
  @include font-serif-l-default;
}

.font-serif-m-medium {
  @include font-serif-m-medium;
}

.font-serif-m-default {
  @include font-serif-m-default;
}

.font-serif-s-strong {
  @include font-serif-s-strong;
}

.font-serif-s-medium {
  @include font-serif-s-medium;
}

.font-sans-4xl-strong {
  @include font-sans-4xl-strong;
}

.font-sans-4xl-semi {
  @include font-sans-4xl-semi;
}

.font-sans-3xl-strong {
  @include font-sans-3xl-strong;
}

.font-sans-xxl-strong {
  @include font-sans-xxl-strong;
}

.font-sans-xxl-semi {
  @include font-sans-xxl-semi;
}

.font-sans-xxl-default {
  @include font-sans-xxl-default;
}

.font-sans-xxl-strong-link {
  @include font-sans-xxl-strong-link;
}

.font-sans-xl-strong {
  @include font-sans-xl-strong;
}

.font-sans-xl-default {
  @include font-sans-xl-default;
}

.font-sans-xl-strong-link {
  @include font-sans-xl-strong-link;
}

.font-sans-l-strong {
  @include font-sans-l-strong;
}

.font-sans-l-semi {
  @include font-sans-l-semi;
}

.font-sans-l-default {
  @include font-sans-l-default;
}

.font-sans-l-italic {
  @include font-sans-l-italic;
}

.font-sans-l-link {
  @include font-sans-l-link;
}

.font-sans-l-strong-link {
  @include font-sans-l-strong-link;
}

.font-sans-m-strong {
  @include font-sans-m-strong;
}

.font-sans-m-semi {
  @include font-sans-m-semi;
}

.font-sans-m-default {
  @include font-sans-m-default;
}

.font-sans-m-italic {
  @include font-sans-m-italic;
}

.font-sans-m-link {
  @include font-sans-m-link;
}

.font-sans-m-semi-link {
  @include font-sans-m-semi-link;
}

.font-sans-m-strong-link {
  @include font-sans-m-strong-link;
}

.font-sans-s-strong {
  @include font-sans-s-strong;
}

.font-sans-s-semi {
  @include font-sans-s-semi;
}

.font-sans-s-default {
  @include font-sans-s-default;
}

.font-sans-s-italic {
  @include font-sans-s-italic;
}

.font-sans-s-link {
  @include font-sans-s-link;
}

.font-sans-s-semi-link {
  @include font-sans-s-semi-link;
}

.font-sans-xs-semi {
  @include font-sans-xs-semi;
}

.font-sans-xs-default {
  @include font-sans-xs-default;
}

.font-sans-xs-italic {
  @include font-sans-xs-italic;
}

.font-sans-xs-link {
  @include font-sans-xs-link;
}

.font-sans-xs-semi-link {
  @include font-sans-xs-semi-link;
}

.font-sans-xxs-default {
  @include font-sans-xxs-default;
}

.font-condensed-xxl-strong {
  @include font-condensed-xxl-strong;
}

.font-condensed-xl-strong {
  @include font-condensed-xl-strong;
}

.font-condensed-l-strong {
  @include font-condensed-l-strong;
}

.font-condensed-l-default {
  @include font-condensed-l-default;
}

.font-condensed-m-strong {
  @include font-condensed-m-strong;
}

.font-condensed-m-default {
  @include font-condensed-m-default;
}

.font-condensed-s-strong {
  @include font-condensed-s-strong;
}

.font-condensed-s-default {
  @include font-condensed-s-default;
}

.font-condensed-xs-strong {
  @include font-condensed-xs-strong;
}

.font-condensed-xs-default {
  @include font-condensed-xs-default;
}

.font-produkt-5xl {
  @include font-produkt-5xl;
}

.font-produkt-4xl {
  @include font-produkt-4xl;
}

.font-produkt-3xl {
  @include font-produkt-3xl;
}

.font-produkt-xxl {
  @include font-produkt-xxl;
}

.font-produkt-xl {
  @include font-produkt-xl;
}

.font-produkt-l {
  @include font-produkt-l;
}

.font-interface-xl-semi {
  @include font-interface-xl-semi;
}

.font-interface-xl-default {
  @include font-interface-xl-default;
}

.font-interface-m-semi {
  @include font-interface-m-semi;
}

.font-interface-m-default {
  @include font-interface-m-default;
}

.font-interface-s-semi {
  @include font-interface-s-semi;
}

.font-interface-s-default {
  @include font-interface-s-default;
}

.font-interface-xxs-semi {
  @include font-interface-xxs-semi;
}

.font-interface-xxs-default {
  @include font-interface-xxs-default;
}

.font-edition-5xl {
  @include font-edition-5xl;
}

.font-edition-4xl {
  @include font-edition-4xl;
}

.font-edition-3xl {
  @include font-edition-3xl;
}

.font-edition-xxl {
  @include font-edition-xxl;
}

.font-edition-xl {
  @include font-edition-xl;
}

.font-edition-l {
  @include font-edition-l;
}

.font-edition-s {
  @include font-edition-s;
}
