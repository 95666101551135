.editorials__title {
  color: var(--color-neutral-text-shape-light);
  @include font-condensed-l-strong;
}

.editorials {
  display: flex;
  flex-direction: column;
  --editorial-margin: var(--spacing-m);
  padding-top: var(--spacing-xl);

  & {
    gap: var(--editorial-margin)
  }

  &.-timeline {
    padding-top: var(--spacing-none);
    gap: var(--spacing-none);

    .editorials__item-content {
      padding-top: var(--spacing-l);
    }

    .editorials__item-content.-compact {
      padding-bottom: var(--spacing-l);
    }
  }
}

.editorials__item {
  display: flex;
  gap: var(--spacing-xl);

  &:not(.-compact) {
    .briefitem-newscard {
      &:not(:has(.newscard__tag-list)) {
        padding-bottom: calc(var(--editorial-margin) * .5);
      }
    }

    .newscard-wrap {
      &:has(.newscard__thumbnail) {
        padding-top: var(--spacing-xs);
      }

      &:not(:has(.newscard__tag-list)) {
        padding-bottom: calc(var(--editorial-margin) * .5);
      }
    }

    .newscard {
      gap: var(--spacing-s);

      .newscard__article-content {
        gap: var(--spacing-xxs);
        padding-right: var(--spacing-l);
      }

      .newscard__article-col {
        padding-bottom: var(--spacing-none);
      }

      .newscard__article-title {
        @include font-sans-xl-strong;
      }

      .newscard__article-header {
        @include font-sans-m-default;
      }
    }

    .newscard__tag-list {
      margin-top: var(--spacing-xl);
    }
  }

  &:first-child {
    .timeline__date-filler.-top .timeline__date-divider {
      display: none;
    }
  }
}

.timeline__item-date {
  display: flex;
  width: 6rem;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  gap: var(--spacing-xxs);
}

.timeline__date {
  color: var(--color-neutral-text-shape-light);
  @include font-condensed-m-strong;
}

.timeline__date-filler {
  display: flex;
  padding-right: var(--spacing-xxxxs);
  justify-content: flex-end;

  &.-top {
    height: var(--spacing-s);
  }

  &.-bottom {
    flex: 1 0 0;
  }

  &:has(.timeline__date-dot) {
    padding: var(--spacing-none);
  }
}

.timeline__date-divider {
  width: 1px;
  height: 100%;
  background: var(--color-neutral-border-light);
}

.timeline__date-dot {
  width: 3px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--color-neutral-text-shape-light);
}

.read-more__container {
  .timeline__item-date {
    gap: var(--spacing-none);
  }

  .timeline__date-filler {
    &.-top {
      flex: 1 0 0;
    }
  }
}

@media (max-width: $breakpoint-m) {
  .editorials__title {
    @include font-condensed-m-strong;
  }
}
