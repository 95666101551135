@import "swiper/swiper-bundle.min.css";

.paywall-lists-main-wrapper {
  overflow: hidden;
  position: relative;
}

.paywall.-lists {
  button.paywall-lists__toggle-enabled {
    visibility: hidden;
    float: right;
  }

  &.-active {
    position: absolute;
    overflow-y: auto;
    height: auto;
    top: 0;
  }

  &.-active, &.-disabled {
    .paywall-lists__toggle-enabled {
      visibility: visible;
      display: flex;
      transition: transform .2s ease;

      &.-reversed {
        transform: rotate(180deg);
      }
    }
  }

  .paywall__trial {
    gap: 0;
  }

  .paywall__contents {
    padding-top: 0;
    gap: var(--spacing-48);
  }
}

.paywall.-lists {
  .paywall__container {
    padding-bottom: 0;
  }

  .paywall__contents {
    padding-bottom: var(--spacing-l);
  }
}

.paywall-lists__testimonies {
  background: var(--color-neutral-surface-contrast-dark);
  color: var(--color-neutral-text-shape-contrast-default);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--spacing-xxl) 0;
}

.paywall-lists__testimonies-carousel {
  max-width: 72.3rem;
  overflow: hidden;
  position: relative;
  padding: 0 var(--spacing-xxxl);
}

.paywall-lists__testimony {
  max-width: 70.7rem;
  padding: var(--spacing-xxxxl);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxl);
}

.paywall-lists__testimony-author {
  display: flex;
  gap: var(--spacing-m);
  align-items: center;
}

.paywall-lists__testimony-quote {
  @include font-serif-l-medium;
}

.paywall-lists__testimony-name {
  @include font-sans-s-semi;
}

.paywall-lists__testimony-role {
  @include font-sans-s-default;
  opacity: .8;
}

.paywall-lists__testimony-logo {
  height: 4rem;
  width: 4rem;
  border-radius: var(--radius-max);
}

.paywall-lists__about {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxl);
  text-wrap: unset;

  a {
    margin: auto;
  }
}

.paywall-lists__about-title {
  @include font-interface-xl-semi;
  font-size: 3.2rem;
  line-height: 1.25;
  font-weight: 700;
  text-wrap: unset;
  text-align: center;
}

.paywall-lists__about-bricks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xxl);
}

.paywall-lists__about-brick {
  @include font-sans-m-default;
  padding: var(--spacing-xxl) var(--spacing-xxl);
  border-radius: var(--radius-rounded);
  background-color: var(--color-neutral-surface-light);
  border: 1px solid var(--color-neutral-border-light);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  text-align: center;

  &:last-of-type {
    grid-column: span 2;
  }

  h3 {
    @include font-sans-xl-strong;
    color: var(--color-global-state-hover-text-shape-default);
    text-wrap: unset;

    span {
      @include font-edition-5xl;
      display: block;
    }
  }
}

.paywall-lists__cards {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.paywall-lists__card {
  padding: var(--spacing-xxl) var(--spacing-l) var(--spacing-xxl);
  border: 1px solid var(--color-neutral-border-light);
  background-color: var(--color-neutral-surface-light);
  border-radius: var(--radius-rounded);
  @include font-sans-m-default;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);

  p {
    text-align: center;
  }
}

.paywall-lists__card-title {
  @include font-sans-xl-strong;
  text-align: center;
}

@media screen and (max-width: $breakpoint-m) {
  .paywall.-lists {
    .paywall__container {
      padding-bottom: var(--spacing-xl);
    }

    .paywall__contents {
      gap: var(--spacing-xxxxl);
    }
  }

  .paywall.-lists {
    .paywall__container {
      padding-bottom: 0;
    }

    .paywall__contents {
      padding-bottom: var(--spacing-xl);
    }
  }
}

@media screen and (max-width: $breakpoint-s) {
  .paywall.-lists {
    .paywall__trial.-top {
      align-items: flex-start;

      .paywall__title {
        text-align: left;
      }
    }


    .paywall__contents {
      padding-top: 0;
      gap: var(--spacing-xxxl);
    }
  }

  .paywall-lists__testimonies-carousel {
    max-width: 100%;
  }

  .paywall-lists__about {
    gap: var(--spacing-xl);
  }

  .paywall-lists__about-title {
    @include font-sans-xl-strong;
  }

  .paywall-lists__about-bricks {
    grid-template-columns: 1fr;
    gap: var(--spacing-xl);

    .paywall-lists__about-brick {
      &:last-of-type {
        grid-column: unset;
      }
    }
  }

  .paywall__trial.-top,
  .paywall-lists__cards.-top {
    .paywall__statement,
    .paywall-lists__card-title,
    .paywall-lists__card p,
    .paywall-lists__about-title {
      text-align: left;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .paywall {
    &, &.-active, &.-disabled {
      button.paywall-lists__toggle-enabled {
        display: none;
      }
    }

    &.-lists {
      .paywall__contents {
        padding: 0;
      }
    }
  }
}
