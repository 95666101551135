.panel {
  /* Panels are placed in the same cell to overlap themselves. */
  grid-column: 1;
  grid-row: 1;
  z-index: 2;

  /* Panels move using margins. */
  margin-left: 0;
  transition: margin-left 0.5s;

  width: 100%;

  background-color: var(--color-global-background-light);
}

.panel.-appearing {
  animation: showPanel 0.5s;
  animation-fill-mode: both;
}

.panel.-disappearing {
  animation: hidePanel 0.5s;
  animation-fill-mode: both;
}

.panel.-hidingForward {
  animation: hidingForwardPanel 0.5s;
  animation-fill-mode: both;
}

.panel.-bringingBack {
  animation: bringingBackPanel 0.5s;
  animation-fill-mode: both;
}

.panel.-previous,
.panel.-next {
  display: none;
}

@keyframes showPanel {
  0% {
    opacity: 0;
    margin-left: 100%;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}

@keyframes hidePanel {
  0% {
    opacity: 1;
    margin-left: 0;
  }
  100% {
    opacity: 0;
    margin-left: -100%;
  }
}

@keyframes hidingForwardPanel {
  0% {
    opacity: 1;
    margin-left: 0;
  }
  100% {
    opacity: 0;
    margin-left: 100%;
  }
}

@keyframes bringingBackPanel {
  0% {
    opacity: 0;
    margin-left: -100%;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}

.panel-back-button {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1000;

  min-height: unset;
  height: 4rem;
  padding: var(--spacing-s) calc(var(--spacing-s) * 2);

  appearance: none;
  border-radius: var(--radius-max);
  background: var(--color-neutral-surface-contrast-darker);
  border: 1px solid var(--color-neutral-border-contrast-dark);
  color: var(--color-neutral-text-shape-contrast-default);
  @include font-interface-m-semi;
}

.panel.-original .panel-back-button
 {
  display: none;
}

@media screen and (max-width: $breakpoint-s) {
  .panel-back-button {
    &:not(:hover) {
      width: 4.1rem;
      padding: 0;

      .panel-back-button__label {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .panel-back-button {
    position: inherit;
  }
}
