@use "mixins" as *;

/**
 * This file was generated automaticaly using the `extract-figma-colors.py` script.
 * Please do not modify it by hand.
 *
 * Generated on 16/12/2024 at 10:44:14 by marie-noellefromage
 */

:root, :root.ctx-light-mode, ::backdrop, .-force-light-mode {
  --alpha-light: 6.0rem; /* alpha-600 */
  --alpha-max: 10.0rem; /* alpha-1000 */
  --alpha-none: 0.0rem; /* alpha-0 */
  --color-brand-abo-border-contrast-dark: #BA9204; /* color-brand-abo-800 */
  --color-brand-abo-border-contrast-darker: #2E2401; /* color-brand-abo-1100 */
  --color-brand-abo-border-contrast-default: #FFC805; /* color-brand-abo-600 */
  --color-brand-abo-border-contrast-light: #FFF3C8; /* color-brand-abo-200 */
  --color-brand-abo-border-contrast-lighter: #FFFAE7; /* color-brand-abo-100 */
  --color-brand-abo-border-dark: #BA9204; /* color-brand-abo-800 */
  --color-brand-abo-border-darker: #2E2401; /* color-brand-abo-1100 */
  --color-brand-abo-border-default: #FFC805; /* color-brand-abo-600 */
  --color-brand-abo-border-light: #FFF3C8; /* color-brand-abo-200 */
  --color-brand-abo-border-lighter: #FFFAE7; /* color-brand-abo-100 */
  --color-brand-abo-surface-contrast-dark: #BA9204; /* color-brand-abo-800 */
  --color-brand-abo-surface-contrast-darker: #2E2401; /* color-brand-abo-1100 */
  --color-brand-abo-surface-contrast-default: #FFC805; /* color-brand-abo-600 */
  --color-brand-abo-surface-contrast-light: #FFF3C8; /* color-brand-abo-200 */
  --color-brand-abo-surface-contrast-lighter: #FFFAE7; /* color-brand-abo-100 */
  --color-brand-abo-surface-dark: #BA9204; /* color-brand-abo-800 */
  --color-brand-abo-surface-darker: #2E2401; /* color-brand-abo-1100 */
  --color-brand-abo-surface-default: #FFC805; /* color-brand-abo-600 */
  --color-brand-abo-surface-light: #FFF3C8; /* color-brand-abo-200 */
  --color-brand-abo-surface-lighter: #FFFAE7; /* color-brand-abo-100 */
  --color-brand-abo-text-shape-contrast-dark: #5D4902; /* color-brand-abo-1000 */
  --color-brand-abo-text-shape-contrast-darker: #2E2401; /* color-brand-abo-1100 */
  --color-brand-abo-text-shape-contrast-default: #FFC805; /* color-brand-abo-600 */
  --color-brand-abo-text-shape-contrast-inverted: #FFFFFF; /* color-brand-abo-0 */
  --color-brand-abo-text-shape-contrast-light: #FFE68D; /* color-brand-abo-400 */
  --color-brand-abo-text-shape-contrast-lighter: #FFFAE7; /* color-brand-abo-100 */
  --color-brand-abo-text-shape-dark: #5D4902; /* color-brand-abo-1000 */
  --color-brand-abo-text-shape-darker: #2E2401; /* color-brand-abo-1100 */
  --color-brand-abo-text-shape-default: #FFC805; /* color-brand-abo-600 */
  --color-brand-abo-text-shape-inverted: #FFFFFF; /* color-brand-abo-0 */
  --color-brand-abo-text-shape-light: #FFE68D; /* color-brand-abo-400 */
  --color-brand-abo-text-shape-lighter: #FFFAE7; /* color-brand-abo-100 */
  --color-brand-primary-border-dark: #551406; /* color-brand-primary-1100 */
  --color-brand-primary-border-darker: #2A0A03; /* color-brand-primary-1200 */
  --color-brand-primary-border-default: #FF3C11; /* color-brand-primary-700 */
  --color-brand-primary-border-light: #FF9E88; /* color-brand-primary-400 */
  --color-brand-primary-border-lighter: #FFECE7; /* color-brand-primary-100 */
  --color-brand-primary-surface-dark: #551406; /* color-brand-primary-1100 */
  --color-brand-primary-surface-darker: #2A0A03; /* color-brand-primary-1200 */
  --color-brand-primary-surface-default: #FF3C11; /* color-brand-primary-700 */
  --color-brand-primary-surface-light: #FFBEB0; /* color-brand-primary-300 */
  --color-brand-primary-surface-lighter: #FFECE7; /* color-brand-primary-100 */
  --color-brand-primary-text-shape-dark: #D5320E; /* color-brand-primary-800 */
  --color-brand-primary-text-shape-darker: #801E09; /* color-brand-primary-1000 */
  --color-brand-primary-text-shape-default: #FF3C11; /* color-brand-primary-700 */
  --color-brand-primary-text-shape-inverted: #FFFFFF; /* color-brand-primary-0 */
  --color-brand-primary-text-shape-light: #FFDFD7; /* color-brand-primary-200 */
  --color-brand-primary-text-shape-lighter: #FFECE7; /* color-brand-primary-100 */
  --color-feedback-alert-border-contrast-dark: #4F2600; /* color-feedback-alert-900 */
  --color-feedback-alert-border-contrast-darker: #090400; /* color-feedback-alert-1100 */
  --color-feedback-alert-border-contrast-default: #C45F00; /* color-feedback-alert-700 */
  --color-feedback-alert-border-contrast-light: #FBE8D7; /* color-feedback-alert-300 */
  --color-feedback-alert-border-contrast-lighter: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-alert-border-dark: #4F2600; /* color-feedback-alert-900 */
  --color-feedback-alert-border-darker: #090400; /* color-feedback-alert-1100 */
  --color-feedback-alert-border-default: #C45F00; /* color-feedback-alert-700 */
  --color-feedback-alert-border-light: #FBE8D7; /* color-feedback-alert-300 */
  --color-feedback-alert-border-lighter: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-alert-surface-contrast-dark: #854100; /* color-feedback-alert-800 */
  --color-feedback-alert-surface-contrast-darker: #241200; /* color-feedback-alert-1000 */
  --color-feedback-alert-surface-contrast-default: #E88324; /* color-feedback-alert-600 */
  --color-feedback-alert-surface-contrast-light: #FEF9F5; /* color-feedback-alert-200 */
  --color-feedback-alert-surface-contrast-lighter: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-alert-surface-dark: #854100; /* color-feedback-alert-800 */
  --color-feedback-alert-surface-darker: #241200; /* color-feedback-alert-1000 */
  --color-feedback-alert-surface-default: #E88324; /* color-feedback-alert-600 */
  --color-feedback-alert-surface-light: #FEF9F5; /* color-feedback-alert-200 */
  --color-feedback-alert-surface-lighter: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-alert-text-shape-contrast-dark: #241200; /* color-feedback-alert-1000 */
  --color-feedback-alert-text-shape-contrast-darker: #090400; /* color-feedback-alert-1100 */
  --color-feedback-alert-text-shape-contrast-default: #C45F00; /* color-feedback-alert-700 */
  --color-feedback-alert-text-shape-contrast-inverted: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-alert-text-shape-contrast-light: #FBE8D7; /* color-feedback-alert-300 */
  --color-feedback-alert-text-shape-contrast-lighter: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-alert-text-shape-dark: #4F2600; /* color-feedback-alert-900 */
  --color-feedback-alert-text-shape-darker: #241200; /* color-feedback-alert-1000 */
  --color-feedback-alert-text-shape-default: #C45F00; /* color-feedback-alert-700 */
  --color-feedback-alert-text-shape-inverted: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-alert-text-shape-light: #FBE8D7; /* color-feedback-alert-300 */
  --color-feedback-alert-text-shape-lighter: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-danger-border-contrast-dark: #5D0907; /* color-feedback-danger-1000 */
  --color-feedback-danger-border-contrast-darker: #390604; /* color-feedback-danger-1100 */
  --color-feedback-danger-border-contrast-default: #D41C17; /* color-feedback-danger-700 */
  --color-feedback-danger-border-contrast-light: #F3BFBD; /* color-feedback-danger-300 */
  --color-feedback-danger-border-contrast-lighter: #F9DFDE; /* color-feedback-danger-200 */
  --color-feedback-danger-border-dark: #5D0907; /* color-feedback-danger-1000 */
  --color-feedback-danger-border-darker: #390604; /* color-feedback-danger-1100 */
  --color-feedback-danger-border-default: #D41C17; /* color-feedback-danger-700 */
  --color-feedback-danger-border-light: #F3BFBD; /* color-feedback-danger-300 */
  --color-feedback-danger-border-lighter: #F9DFDE; /* color-feedback-danger-200 */
  --color-feedback-danger-surface-contrast-dark: #840D0A; /* color-feedback-danger-900 */
  --color-feedback-danger-surface-contrast-darker: #5D0907; /* color-feedback-danger-1000 */
  --color-feedback-danger-surface-contrast-default: #D41C17; /* color-feedback-danger-700 */
  --color-feedback-danger-surface-contrast-light: #F9DFDE; /* color-feedback-danger-200 */
  --color-feedback-danger-surface-contrast-lighter: #FCEDED; /* color-feedback-danger-100 */
  --color-feedback-danger-surface-dark: #840D0A; /* color-feedback-danger-900 */
  --color-feedback-danger-surface-darker: #5D0907; /* color-feedback-danger-1000 */
  --color-feedback-danger-surface-default: #D41C17; /* color-feedback-danger-700 */
  --color-feedback-danger-surface-light: #F9DFDE; /* color-feedback-danger-200 */
  --color-feedback-danger-surface-lighter: #FCEDED; /* color-feedback-danger-100 */
  --color-feedback-danger-text-shape-contrast-dark: #390604; /* color-feedback-danger-1100 */
  --color-feedback-danger-text-shape-contrast-default: #D41C17; /* color-feedback-danger-700 */
  --color-feedback-danger-text-shape-contrast-light: #FFFFFF; /* color-feedback-danger-0 */
  --color-feedback-danger-text-shape-dark: #390604; /* color-feedback-danger-1100 */
  --color-feedback-danger-text-shape-default: #D41C17; /* color-feedback-danger-700 */
  --color-feedback-danger-text-shape-inverted: #FFFFFF; /* color-feedback-danger-0 */
  --color-feedback-danger-text-shape-light: #F3BFBD; /* color-feedback-danger-300 */
  --color-feedback-info-border-contrast-dark: #001D28; /* color-feedback-info-1000 */
  --color-feedback-info-border-contrast-darker: #001D28; /* color-feedback-info-1000 */
  --color-feedback-info-border-contrast-default: #009FD7; /* color-feedback-info-700 */
  --color-feedback-info-border-contrast-light: #A7E7FE; /* color-feedback-info-400 */
  --color-feedback-info-border-contrast-lighter: #D7F4FE; /* color-feedback-info-300 */
  --color-feedback-info-border-dark: #004057; /* color-feedback-info-900 */
  --color-feedback-info-border-darker: #00070A; /* color-feedback-info-1100 */
  --color-feedback-info-border-default: #009FD7; /* color-feedback-info-700 */
  --color-feedback-info-border-light: #A7E7FE; /* color-feedback-info-400 */
  --color-feedback-info-border-lighter: #D7F4FE; /* color-feedback-info-300 */
  --color-feedback-info-surface-contrast-dark: #004057; /* color-feedback-info-900 */
  --color-feedback-info-surface-contrast-darker: #001D28; /* color-feedback-info-1000 */
  --color-feedback-info-surface-contrast-default: #009FD7; /* color-feedback-info-700 */
  --color-feedback-info-surface-contrast-light: #D7F4FE; /* color-feedback-info-300 */
  --color-feedback-info-surface-contrast-lighter: #F5FCFF; /* color-feedback-info-200 */
  --color-feedback-info-surface-dark: #004057; /* color-feedback-info-900 */
  --color-feedback-info-surface-darker: #001D28; /* color-feedback-info-1000 */
  --color-feedback-info-surface-default: #009FD7; /* color-feedback-info-700 */
  --color-feedback-info-surface-light: #D7F4FE; /* color-feedback-info-300 */
  --color-feedback-info-surface-lighter: #F5FCFF; /* color-feedback-info-200 */
  --color-feedback-info-text-shape-contrast-dark: #004057; /* color-feedback-info-900 */
  --color-feedback-info-text-shape-contrast-darker: #001D28; /* color-feedback-info-1000 */
  --color-feedback-info-text-shape-contrast-default: #006C93; /* color-feedback-info-800 */
  --color-feedback-info-text-shape-contrast-inverted: #F5FCFF; /* color-feedback-info-200 */
  --color-feedback-info-text-shape-contrast-light: #A7E7FE; /* color-feedback-info-400 */
  --color-feedback-info-text-shape-contrast-lighter: #F5FCFF; /* color-feedback-info-200 */
  --color-feedback-info-text-shape-dark: #004057; /* color-feedback-info-900 */
  --color-feedback-info-text-shape-darker: #00070A; /* color-feedback-info-1100 */
  --color-feedback-info-text-shape-default: #006C93; /* color-feedback-info-800 */
  --color-feedback-info-text-shape-inverted: #F5FCFF; /* color-feedback-info-200 */
  --color-feedback-info-text-shape-light: #A7E7FE; /* color-feedback-info-400 */
  --color-feedback-info-text-shape-lighter: #F5FCFF; /* color-feedback-info-200 */
  --color-feedback-success-border-contrast-dark: #023A0B; /* color-feedback-success-900 */
  --color-feedback-success-border-contrast-darker: #011A05; /* color-feedback-success-1000 */
  --color-feedback-success-border-contrast-default: #068F1C; /* color-feedback-success-700 */
  --color-feedback-success-border-contrast-light: #6ECC7D; /* color-feedback-success-500 */
  --color-feedback-success-border-contrast-lighter: #D8F1DC; /* color-feedback-success-300 */
  --color-feedback-success-border-dark: #023A0B; /* color-feedback-success-900 */
  --color-feedback-success-border-darker: #000701; /* color-feedback-success-1100 */
  --color-feedback-success-border-default: #068F1C; /* color-feedback-success-700 */
  --color-feedback-success-border-light: #6ECC7D; /* color-feedback-success-500 */
  --color-feedback-success-border-lighter: #D8F1DC; /* color-feedback-success-300 */
  --color-feedback-success-surface-contrast-dark: #046213; /* color-feedback-success-800 */
  --color-feedback-success-surface-contrast-darker: #011A05; /* color-feedback-success-1000 */
  --color-feedback-success-surface-contrast-default: #068F1C; /* color-feedback-success-700 */
  --color-feedback-success-surface-contrast-light: #A9E1B2; /* color-feedback-success-400 */
  --color-feedback-success-surface-contrast-lighter: #F5FBF6; /* color-feedback-success-200 */
  --color-feedback-success-surface-dark: #046213; /* color-feedback-success-800 */
  --color-feedback-success-surface-darker: #011A05; /* color-feedback-success-1000 */
  --color-feedback-success-surface-default: #068F1C; /* color-feedback-success-700 */
  --color-feedback-success-surface-light: #A9E1B2; /* color-feedback-success-400 */
  --color-feedback-success-surface-lighter: #F5FBF6; /* color-feedback-success-200 */
  --color-feedback-success-text-shape-contrast-dark: #011A05; /* color-feedback-success-1000 */
  --color-feedback-success-text-shape-contrast-default: #046213; /* color-feedback-success-800 */
  --color-feedback-success-text-shape-contrast-inverted: #FCFEFC; /* color-feedback-success-100 */
  --color-feedback-success-text-shape-contrast-light: #2AB341; /* color-feedback-success-600 */
  --color-feedback-success-text-shape-dark: #011A05; /* color-feedback-success-1000 */
  --color-feedback-success-text-shape-default: #046213; /* color-feedback-success-800 */
  --color-feedback-success-text-shape-inverted: #FCFEFC; /* color-feedback-success-100 */
  --color-feedback-success-text-shape-light: #2AB341; /* color-feedback-success-600 */
  --color-global-background-dark: #07002E; /* color-neutral-1000 */
  --color-global-background-darker: #04001A; /* color-neutral-1100 */
  --color-global-background-default: #F0F0F5; /* color-neutral-400 */
  --color-global-background-light: #FBFBFE; /* color-neutral-100 */
  --color-global-background-lighter: #FFFFFF; /* color-neutral-0 */
  --color-global-background-paywall-dark: #03257C; /* color-global-action-800 */
  --color-global-background-paywall-darker: #010C29; /* color-global-action-1000 */
  --color-global-background-paywall-default: #053DCE; /* color-global-action-600 */
  --color-global-background-paywall-light: #E6ECFA; /* color-global-action-100 */
  --color-global-background-paywall-lighter: #F6F9FD; /* color-global-action-50 */
  --color-global-highlight-surface: #F9C83A; /* color-global-highlight-400 */
  --color-global-highlight-text: #1D1707; /* color-global-highlight-1000 */
  --color-global-ia-border-dark: #8A119B; /* color-global-ia-800 */
  --color-global-ia-border-default: #D39EDF; /* color-global-ia-400 */
  --color-global-ia-border-light: #F2E4F5; /* color-global-ia-200 */
  --color-global-ia-surface-dark: #570B62; /* color-global-ia-1000 */
  --color-global-ia-surface-default: #B216C8; /* color-global-ia-600 */
  --color-global-ia-surface-light: #F8F2FA; /* color-global-ia-100 */
  --color-global-ia-text-shape-dark: #730E82; /* color-global-ia-900 */
  --color-global-ia-text-shape-default: #9E14B2; /* color-global-ia-700 */
  --color-global-ia-text-shape-light: #C065D1; /* color-global-ia-500 */
  --color-global-monito-border-dark: #005FAD; /* color-global-color-global-monito-800 */
  --color-global-monito-border-default: #87CBFF; /* color-global-color-global-monito-400 */
  --color-global-monito-border-light: #D5EDFF; /* color-global-color-global-monito-200 */
  --color-global-monito-surface-dark: #01244C; /* color-global-color-global-monito-1000 */
  --color-global-monito-surface-default: #169AFF; /* color-global-color-global-monito-600 */
  --color-global-monito-surface-light: #EFF8FF; /* color-global-color-global-monito-100 */
  --color-global-monito-text-shape-dark: #033A75; /* color-global-color-global-monito-900 */
  --color-global-monito-text-shape-default: #0077D9; /* color-global-color-global-monito-700 */
  --color-global-monito-text-shape-light: #4BB1FF; /* color-global-color-global-monito-500 */
  --color-global-post-it-border-dark: #877F59; /* color-global-post-it-900 */
  --color-global-post-it-border-default: #D7CA8D; /* color-global-post-it-700 */
  --color-global-post-it-border-light: #FBF0C0; /* color-global-post-it-500 */
  --color-global-post-it-surface-dark: #B1A674; /* color-global-post-it-800 */
  --color-global-post-it-surface-default: #FAEBA4; /* color-global-post-it-600 */
  --color-global-post-it-surface-light: #FDF5D6; /* color-global-post-it-400 */
  --color-global-post-it-text-shape-default: #2E2B1E; /* color-global-post-it-1100 */
  --color-global-post-it-text-shape-light: #5C563C; /* color-global-post-it-1000 */
  --color-global-social-bluesky: #0A7AFF; /* raw */
  --color-global-social-facebook: #1877F2; /* raw */
  --color-global-social-linkedin: #0A66C2; /* raw */
  --color-global-social-twitter: #000000; /* raw */
  --color-global-state-disabled-border-contrasted: #29234B; /* color-neutral-800 */
  --color-global-state-disabled-border-default: #F0F0F5; /* color-neutral-400 */
  --color-global-state-disabled-border-inverted: #29234B; /* color-neutral-800 */
  --color-global-state-disabled-surface-contrasted: #29234B; /* color-neutral-800 */
  --color-global-state-disabled-surface-default: #F0F0F5; /* color-neutral-400 */
  --color-global-state-disabled-surface-inverted: #29234B; /* color-neutral-800 */
  --color-global-state-disabled-text-shape-contrasted: #5A5675; /* color-neutral-700 */
  --color-global-state-disabled-text-shape-default: #A8A5B7; /* color-neutral-600 */
  --color-global-state-disabled-text-shape-inverted: #5A5675; /* color-neutral-700 */
  --color-global-state-hover-border-dark: #03257C; /* color-global-action-800 */
  --color-global-state-hover-border-default: #053DCE; /* color-global-action-600 */
  --color-global-state-hover-border-light: #CDD8F5; /* color-global-action-200 */
  --color-global-state-hover-surface-dark: #03257C; /* color-global-action-800 */
  --color-global-state-hover-surface-default: #053DCE; /* color-global-action-600 */
  --color-global-state-hover-surface-light: #E6ECFA; /* color-global-action-100 */
  --color-global-state-hover-surface-lighter: #F6F9FD; /* color-global-action-50 */
  --color-global-state-hover-text-shape-contrast-default: #356DFE; /* color-global-action-500 */
  --color-global-state-hover-text-shape-contrast-inverted: #FFFFFF; /* color-global-action-0 */
  --color-global-state-hover-text-shape-dark: #03257C; /* color-global-action-800 */
  --color-global-state-hover-text-shape-default: #053DCE; /* color-global-action-600 */
  --color-global-state-hover-text-shape-inverted: #FFFFFF; /* color-global-action-0 */
  --color-global-white: #FFFFFF; /* color-neutral-0 */
  --color-neutral-border-contrast-dark: #A8A5B7; /* color-neutral-600 */
  --color-neutral-border-contrast-darker: #D9D8E1; /* color-neutral-500 */
  --color-neutral-border-contrast-default: #5A5675; /* color-neutral-700 */
  --color-neutral-border-contrast-light: #29234B; /* color-neutral-800 */
  --color-neutral-border-contrast-lighter: #120B37; /* color-neutral-900 */
  --color-neutral-border-dark: #29234B; /* color-neutral-800 */
  --color-neutral-border-darker: #120B37; /* color-neutral-900 */
  --color-neutral-border-default: #5A5675; /* color-neutral-700 */
  --color-neutral-border-light: #D9D8E1; /* color-neutral-500 */
  --color-neutral-border-lighter: #F0F0F5; /* color-neutral-400 */
  --color-neutral-surface-contrast-dark: #07002E; /* color-neutral-1000 */
  --color-neutral-surface-contrast-darker: #04001A; /* color-neutral-1100 */
  --color-neutral-surface-contrast-default: #D9D8E1; /* color-neutral-500 */
  --color-neutral-surface-contrast-light: #F0F0F5; /* color-neutral-400 */
  --color-neutral-surface-dark: #07002E; /* color-neutral-1000 */
  --color-neutral-surface-darker: #04001A; /* color-neutral-1100 */
  --color-neutral-surface-default: #F0F0F5; /* color-neutral-400 */
  --color-neutral-surface-light: #F9F9FC; /* color-neutral-300 */
  --color-neutral-surface-lighter: #FFFFFF; /* color-neutral-0 */
  --color-neutral-text-shape-contrast-default: #FFFFFF; /* color-neutral-0 */
  --color-neutral-text-shape-contrast-light: #A8A5B7; /* color-neutral-600 */
  --color-neutral-text-shape-contrast-lighter: #120B37; /* color-neutral-900 */
  --color-neutral-text-shape-dark: #07002E; /* color-neutral-1000 */
  --color-neutral-text-shape-darker: #04001A; /* color-neutral-1100 */
  --color-neutral-text-shape-default: #120B37; /* color-neutral-900 */
  --color-neutral-text-shape-inverted-light: #A8A5B7; /* color-neutral-600 */
  --color-neutral-text-shape-inverted: #FBFBFE; /* color-neutral-100 */
  --color-neutral-text-shape-light: #5A5675; /* color-neutral-700 */
  --color-neutral-text-shape-lighter: #D9D8E1; /* color-neutral-500 */
}

:root.ctx-dark-mode {
  --alpha-light: 6.0rem; /* alpha-600 */
  --alpha-max: 10.0rem; /* alpha-1000 */
  --alpha-none: 0.0rem; /* alpha-0 */
  --color-brand-abo-border-contrast-dark: #BA9204; /* color-brand-abo-800 */
  --color-brand-abo-border-contrast-darker: #2E2401; /* color-brand-abo-1100 */
  --color-brand-abo-border-contrast-default: #FFC805; /* color-brand-abo-600 */
  --color-brand-abo-border-contrast-light: #FFF3C8; /* color-brand-abo-200 */
  --color-brand-abo-border-contrast-lighter: #FFFAE7; /* color-brand-abo-100 */
  --color-brand-abo-border-dark: #FFEDAC; /* color-brand-abo-300 */
  --color-brand-abo-border-darker: #FFF3C8; /* color-brand-abo-200 */
  --color-brand-abo-border-default: #FFC805; /* color-brand-abo-600 */
  --color-brand-abo-border-light: #8B6D03; /* color-brand-abo-900 */
  --color-brand-abo-border-lighter: #2E2401; /* color-brand-abo-1100 */
  --color-brand-abo-surface-contrast-dark: #BA9204; /* color-brand-abo-800 */
  --color-brand-abo-surface-contrast-darker: #2E2401; /* color-brand-abo-1100 */
  --color-brand-abo-surface-contrast-default: #FFC805; /* color-brand-abo-600 */
  --color-brand-abo-surface-contrast-light: #FFF3C8; /* color-brand-abo-200 */
  --color-brand-abo-surface-contrast-lighter: #FFFAE7; /* color-brand-abo-100 */
  --color-brand-abo-surface-dark: #FFF3C8; /* color-brand-abo-200 */
  --color-brand-abo-surface-darker: #FFFAE7; /* color-brand-abo-100 */
  --color-brand-abo-surface-default: #FFC805; /* color-brand-abo-600 */
  --color-brand-abo-surface-light: #BA9204; /* color-brand-abo-800 */
  --color-brand-abo-surface-lighter: #2E2401; /* color-brand-abo-1100 */
  --color-brand-abo-text-shape-contrast-dark: #5D4902; /* color-brand-abo-1000 */
  --color-brand-abo-text-shape-contrast-darker: #2E2401; /* color-brand-abo-1100 */
  --color-brand-abo-text-shape-contrast-default: #FFC805; /* color-brand-abo-600 */
  --color-brand-abo-text-shape-contrast-inverted: #FFFFFF; /* color-brand-abo-0 */
  --color-brand-abo-text-shape-contrast-light: #FFE68D; /* color-brand-abo-400 */
  --color-brand-abo-text-shape-contrast-lighter: #FFFAE7; /* color-brand-abo-100 */
  --color-brand-abo-text-shape-dark: #FFE68D; /* color-brand-abo-400 */
  --color-brand-abo-text-shape-darker: #FFC805; /* color-brand-abo-600 */
  --color-brand-abo-text-shape-default: #FFC805; /* color-brand-abo-600 */
  --color-brand-abo-text-shape-inverted: #FFC805; /* color-brand-abo-600 */
  --color-brand-abo-text-shape-light: #5D4902; /* color-brand-abo-1000 */
  --color-brand-abo-text-shape-lighter: #2E2401; /* color-brand-abo-1100 */
  --color-brand-primary-border-dark: #551406; /* color-brand-primary-1100 */
  --color-brand-primary-border-darker: #2A0A03; /* color-brand-primary-1200 */
  --color-brand-primary-border-default: #FF3C11; /* color-brand-primary-700 */
  --color-brand-primary-border-light: #FF9E88; /* color-brand-primary-400 */
  --color-brand-primary-border-lighter: #FFECE7; /* color-brand-primary-100 */
  --color-brand-primary-surface-dark: #FFBEB0; /* color-brand-primary-300 */
  --color-brand-primary-surface-darker: #FFECE7; /* color-brand-primary-100 */
  --color-brand-primary-surface-default: #FF3C11; /* color-brand-primary-700 */
  --color-brand-primary-surface-light: #551406; /* color-brand-primary-1100 */
  --color-brand-primary-surface-lighter: #2A0A03; /* color-brand-primary-1200 */
  --color-brand-primary-text-shape-dark: #D5320E; /* color-brand-primary-800 */
  --color-brand-primary-text-shape-darker: #801E09; /* color-brand-primary-1000 */
  --color-brand-primary-text-shape-default: #FF3C11; /* color-brand-primary-700 */
  --color-brand-primary-text-shape-inverted: #FFFFFF; /* color-brand-primary-0 */
  --color-brand-primary-text-shape-light: #FFDFD7; /* color-brand-primary-200 */
  --color-brand-primary-text-shape-lighter: #FFECE7; /* color-brand-primary-100 */
  --color-feedback-alert-border-contrast-dark: #4F2600; /* color-feedback-alert-900 */
  --color-feedback-alert-border-contrast-darker: #090400; /* color-feedback-alert-1100 */
  --color-feedback-alert-border-contrast-default: #C45F00; /* color-feedback-alert-700 */
  --color-feedback-alert-border-contrast-light: #FBE8D7; /* color-feedback-alert-300 */
  --color-feedback-alert-border-contrast-lighter: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-alert-border-dark: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-alert-border-darker: #090400; /* color-feedback-alert-1100 */
  --color-feedback-alert-border-default: #C45F00; /* color-feedback-alert-700 */
  --color-feedback-alert-border-light: #4F2600; /* color-feedback-alert-900 */
  --color-feedback-alert-border-lighter: #090400; /* color-feedback-alert-1100 */
  --color-feedback-alert-surface-contrast-dark: #854100; /* color-feedback-alert-800 */
  --color-feedback-alert-surface-contrast-darker: #241200; /* color-feedback-alert-1000 */
  --color-feedback-alert-surface-contrast-default: #E88324; /* color-feedback-alert-600 */
  --color-feedback-alert-surface-contrast-light: #FEF9F5; /* color-feedback-alert-200 */
  --color-feedback-alert-surface-contrast-lighter: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-alert-surface-dark: #FEF9F5; /* color-feedback-alert-200 */
  --color-feedback-alert-surface-darker: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-alert-surface-default: #E88324; /* color-feedback-alert-600 */
  --color-feedback-alert-surface-light: #854100; /* color-feedback-alert-800 */
  --color-feedback-alert-surface-lighter: #241200; /* color-feedback-alert-1000 */
  --color-feedback-alert-text-shape-contrast-dark: #241200; /* color-feedback-alert-1000 */
  --color-feedback-alert-text-shape-contrast-darker: #090400; /* color-feedback-alert-1100 */
  --color-feedback-alert-text-shape-contrast-default: #C45F00; /* color-feedback-alert-700 */
  --color-feedback-alert-text-shape-contrast-inverted: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-alert-text-shape-contrast-light: #FBE8D7; /* color-feedback-alert-300 */
  --color-feedback-alert-text-shape-contrast-lighter: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-alert-text-shape-dark: #FBE8D7; /* color-feedback-alert-300 */
  --color-feedback-alert-text-shape-darker: #FFFDFC; /* color-feedback-alert-100 */
  --color-feedback-alert-text-shape-default: #C45F00; /* color-feedback-alert-700 */
  --color-feedback-alert-text-shape-inverted: #241200; /* color-feedback-alert-1000 */
  --color-feedback-alert-text-shape-light: #4F2600; /* color-feedback-alert-900 */
  --color-feedback-alert-text-shape-lighter: #241200; /* color-feedback-alert-1000 */
  --color-feedback-danger-border-contrast-dark: #5D0907; /* color-feedback-danger-1000 */
  --color-feedback-danger-border-contrast-darker: #390604; /* color-feedback-danger-1100 */
  --color-feedback-danger-border-contrast-default: #D41C17; /* color-feedback-danger-700 */
  --color-feedback-danger-border-contrast-light: #F3BFBD; /* color-feedback-danger-300 */
  --color-feedback-danger-border-contrast-lighter: #F9DFDE; /* color-feedback-danger-200 */
  --color-feedback-danger-border-dark: #F3BFBD; /* color-feedback-danger-300 */
  --color-feedback-danger-border-darker: #F9DFDE; /* color-feedback-danger-200 */
  --color-feedback-danger-border-default: #D41C17; /* color-feedback-danger-700 */
  --color-feedback-danger-border-light: #5D0907; /* color-feedback-danger-1000 */
  --color-feedback-danger-border-lighter: #390604; /* color-feedback-danger-1100 */
  --color-feedback-danger-surface-contrast-dark: #840D0A; /* color-feedback-danger-900 */
  --color-feedback-danger-surface-contrast-darker: #5D0907; /* color-feedback-danger-1000 */
  --color-feedback-danger-surface-contrast-default: #D41C17; /* color-feedback-danger-700 */
  --color-feedback-danger-surface-contrast-light: #F9DFDE; /* color-feedback-danger-200 */
  --color-feedback-danger-surface-contrast-lighter: #FCEDED; /* color-feedback-danger-100 */
  --color-feedback-danger-surface-dark: #F9DFDE; /* color-feedback-danger-200 */
  --color-feedback-danger-surface-darker: #FCEDED; /* color-feedback-danger-100 */
  --color-feedback-danger-surface-default: #D41C17; /* color-feedback-danger-700 */
  --color-feedback-danger-surface-light: #840D0A; /* color-feedback-danger-900 */
  --color-feedback-danger-surface-lighter: #5D0907; /* color-feedback-danger-1000 */
  --color-feedback-danger-text-shape-contrast-dark: #390604; /* color-feedback-danger-1100 */
  --color-feedback-danger-text-shape-contrast-default: #D41C17; /* color-feedback-danger-700 */
  --color-feedback-danger-text-shape-contrast-light: #FFFFFF; /* color-feedback-danger-0 */
  --color-feedback-danger-text-shape-dark: #F3BFBD; /* color-feedback-danger-300 */
  --color-feedback-danger-text-shape-default: #D41C17; /* color-feedback-danger-700 */
  --color-feedback-danger-text-shape-inverted: #FFFFFF; /* color-feedback-danger-0 */
  --color-feedback-danger-text-shape-light: #390604; /* color-feedback-danger-1100 */
  --color-feedback-info-border-contrast-dark: #001D28; /* color-feedback-info-1000 */
  --color-feedback-info-border-contrast-darker: #001D28; /* color-feedback-info-1000 */
  --color-feedback-info-border-contrast-default: #009FD7; /* color-feedback-info-700 */
  --color-feedback-info-border-contrast-light: #A7E7FE; /* color-feedback-info-400 */
  --color-feedback-info-border-contrast-lighter: #D7F4FE; /* color-feedback-info-300 */
  --color-feedback-info-border-dark: #A7E7FE; /* color-feedback-info-400 */
  --color-feedback-info-border-darker: #D7F4FE; /* color-feedback-info-300 */
  --color-feedback-info-border-default: #009FD7; /* color-feedback-info-700 */
  --color-feedback-info-border-light: #004057; /* color-feedback-info-900 */
  --color-feedback-info-border-lighter: #00070A; /* color-feedback-info-1100 */
  --color-feedback-info-surface-contrast-dark: #004057; /* color-feedback-info-900 */
  --color-feedback-info-surface-contrast-darker: #00070A; /* color-feedback-info-1100 */
  --color-feedback-info-surface-contrast-default: #009FD7; /* color-feedback-info-700 */
  --color-feedback-info-surface-contrast-light: #D7F4FE; /* color-feedback-info-300 */
  --color-feedback-info-surface-contrast-lighter: #F5FCFF; /* color-feedback-info-200 */
  --color-feedback-info-surface-dark: #D7F4FE; /* color-feedback-info-300 */
  --color-feedback-info-surface-darker: #F5FCFF; /* color-feedback-info-200 */
  --color-feedback-info-surface-default: #009FD7; /* color-feedback-info-700 */
  --color-feedback-info-surface-light: #006C93; /* color-feedback-info-800 */
  --color-feedback-info-surface-lighter: #001D28; /* color-feedback-info-1000 */
  --color-feedback-info-text-shape-contrast-dark: #004057; /* color-feedback-info-900 */
  --color-feedback-info-text-shape-contrast-darker: #001D28; /* color-feedback-info-1000 */
  --color-feedback-info-text-shape-contrast-default: #006C93; /* color-feedback-info-800 */
  --color-feedback-info-text-shape-contrast-inverted: #F5FCFF; /* color-feedback-info-200 */
  --color-feedback-info-text-shape-contrast-light: #A7E7FE; /* color-feedback-info-400 */
  --color-feedback-info-text-shape-contrast-lighter: #F5FCFF; /* color-feedback-info-200 */
  --color-feedback-info-text-shape-dark: #F5FCFF; /* color-feedback-info-200 */
  --color-feedback-info-text-shape-darker: #F5FCFF; /* color-feedback-info-200 */
  --color-feedback-info-text-shape-default: #009FD7; /* color-feedback-info-700 */
  --color-feedback-info-text-shape-inverted: #001D28; /* color-feedback-info-1000 */
  --color-feedback-info-text-shape-light: #004057; /* color-feedback-info-900 */
  --color-feedback-info-text-shape-lighter: #001D28; /* color-feedback-info-1000 */
  --color-feedback-success-border-contrast-dark: #023A0B; /* color-feedback-success-900 */
  --color-feedback-success-border-contrast-darker: #011A05; /* color-feedback-success-1000 */
  --color-feedback-success-border-contrast-default: #068F1C; /* color-feedback-success-700 */
  --color-feedback-success-border-contrast-light: #6ECC7D; /* color-feedback-success-500 */
  --color-feedback-success-border-contrast-lighter: #D8F1DC; /* color-feedback-success-300 */
  --color-feedback-success-border-dark: #6ECC7D; /* color-feedback-success-500 */
  --color-feedback-success-border-darker: #D8F1DC; /* color-feedback-success-300 */
  --color-feedback-success-border-default: #068F1C; /* color-feedback-success-700 */
  --color-feedback-success-border-light: #046213; /* color-feedback-success-800 */
  --color-feedback-success-border-lighter: #023A0B; /* color-feedback-success-900 */
  --color-feedback-success-surface-contrast-dark: #046213; /* color-feedback-success-800 */
  --color-feedback-success-surface-contrast-darker: #011A05; /* color-feedback-success-1000 */
  --color-feedback-success-surface-contrast-default: #068F1C; /* color-feedback-success-700 */
  --color-feedback-success-surface-contrast-light: #A9E1B2; /* color-feedback-success-400 */
  --color-feedback-success-surface-contrast-lighter: #F5FBF6; /* color-feedback-success-200 */
  --color-feedback-success-surface-dark: #A9E1B2; /* color-feedback-success-400 */
  --color-feedback-success-surface-darker: #F5FBF6; /* color-feedback-success-200 */
  --color-feedback-success-surface-default: #068F1C; /* color-feedback-success-700 */
  --color-feedback-success-surface-light: #046213; /* color-feedback-success-800 */
  --color-feedback-success-surface-lighter: #011A05; /* color-feedback-success-1000 */
  --color-feedback-success-text-shape-contrast-dark: #011A05; /* color-feedback-success-1000 */
  --color-feedback-success-text-shape-contrast-default: #046213; /* color-feedback-success-800 */
  --color-feedback-success-text-shape-contrast-inverted: #FCFEFC; /* color-feedback-success-100 */
  --color-feedback-success-text-shape-contrast-light: #2AB341; /* color-feedback-success-600 */
  --color-feedback-success-text-shape-dark: #2AB341; /* color-feedback-success-600 */
  --color-feedback-success-text-shape-default: #046213; /* color-feedback-success-800 */
  --color-feedback-success-text-shape-inverted: #011A05; /* color-feedback-success-1000 */
  --color-feedback-success-text-shape-light: #011A05; /* color-feedback-success-1000 */
  --color-global-background-dark: #F0F0F5; /* color-neutral-400 */
  --color-global-background-darker: #FFFFFF; /* color-neutral-0 */
  --color-global-background-default: #29234B; /* color-neutral-800 */
  --color-global-background-light: #120B37; /* color-neutral-900 */
  --color-global-background-lighter: #07002E; /* color-neutral-1000 */
  --color-global-background-paywall-dark: #F0F0F5; /* color-neutral-400 */
  --color-global-background-paywall-darker: #FFFFFF; /* color-neutral-0 */
  --color-global-background-paywall-default: #120B37; /* color-neutral-900 */
  --color-global-background-paywall-light: #07002E; /* color-neutral-1000 */
  --color-global-background-paywall-lighter: #07002E; /* color-neutral-1000 */
  --color-global-highlight-surface: #F9C83A; /* color-global-highlight-400 */
  --color-global-highlight-text: #1D1707; /* color-global-highlight-1000 */
  --color-global-ia-border-dark: #F2E4F5; /* color-global-ia-200 */
  --color-global-ia-border-default: #D39EDF; /* color-global-ia-400 */
  --color-global-ia-border-light: #730E82; /* color-global-ia-900 */
  --color-global-ia-surface-dark: #F8F2FA; /* color-global-ia-100 */
  --color-global-ia-surface-default: #8A119B; /* color-global-ia-800 */
  --color-global-ia-surface-light: #570B62; /* color-global-ia-1000 */
  --color-global-ia-text-shape-dark: #F8F2FA; /* color-global-ia-100 */
  --color-global-ia-text-shape-default: #F2E4F5; /* color-global-ia-200 */
  --color-global-ia-text-shape-light: #570B62; /* color-global-ia-1000 */
  --color-global-monito-border-dark: #D5EDFF; /* color-global-color-global-monito-200 */
  --color-global-monito-border-default: #87CBFF; /* color-global-color-global-monito-400 */
  --color-global-monito-border-light: #033A75; /* color-global-color-global-monito-900 */
  --color-global-monito-surface-dark: #EFF8FF; /* color-global-color-global-monito-100 */
  --color-global-monito-surface-default: #005FAD; /* color-global-color-global-monito-800 */
  --color-global-monito-surface-light: #01244C; /* color-global-color-global-monito-1000 */
  --color-global-monito-text-shape-dark: #EFF8FF; /* color-global-color-global-monito-100 */
  --color-global-monito-text-shape-default: #D5EDFF; /* color-global-color-global-monito-200 */
  --color-global-monito-text-shape-light: #01244C; /* color-global-color-global-monito-1000 */
  --color-global-post-it-border-dark: #877F59; /* color-global-post-it-900 */
  --color-global-post-it-border-default: #D7CA8D; /* color-global-post-it-700 */
  --color-global-post-it-border-light: #FBF0C0; /* color-global-post-it-500 */
  --color-global-post-it-surface-dark: #B1A674; /* color-global-post-it-800 */
  --color-global-post-it-surface-default: #FAEBA4; /* color-global-post-it-600 */
  --color-global-post-it-surface-light: #FDF5D6; /* color-global-post-it-400 */
  --color-global-post-it-text-shape-default: #2E2B1E; /* color-global-post-it-1100 */
  --color-global-post-it-text-shape-light: #5C563C; /* color-global-post-it-1000 */
  --color-global-social-bluesky: #0A7AFF; /* raw */
  --color-global-social-facebook: #1877F2; /* raw */
  --color-global-social-linkedin: #0A66C2; /* raw */
  --color-global-social-twitter: #000000; /* raw */
  --color-global-state-disabled-border-contrasted: #29234B; /* color-neutral-800 */
  --color-global-state-disabled-border-default: #29234B; /* color-neutral-800 */
  --color-global-state-disabled-border-inverted: #F0F0F5; /* color-neutral-400 */
  --color-global-state-disabled-surface-contrasted: #29234B; /* color-neutral-800 */
  --color-global-state-disabled-surface-default: #29234B; /* color-neutral-800 */
  --color-global-state-disabled-surface-inverted: #F0F0F5; /* color-neutral-400 */
  --color-global-state-disabled-text-shape-contrasted: #5A5675; /* color-neutral-700 */
  --color-global-state-disabled-text-shape-default: #5A5675; /* color-neutral-700 */
  --color-global-state-disabled-text-shape-inverted: #120B37; /* color-neutral-900 */
  --color-global-state-hover-border-dark: #03257C; /* color-global-action-800 */
  --color-global-state-hover-border-default: #053DCE; /* color-global-action-600 */
  --color-global-state-hover-border-light: #03257C; /* color-global-action-800 */
  --color-global-state-hover-surface-dark: #03257C; /* color-global-action-800 */
  --color-global-state-hover-surface-default: #053DCE; /* color-global-action-600 */
  --color-global-state-hover-surface-light: #03257C; /* color-global-action-800 */
  --color-global-state-hover-surface-lighter: #021852; /* color-global-action-900 */
  --color-global-state-hover-text-shape-contrast-default: #356DFE; /* color-global-action-500 */
  --color-global-state-hover-text-shape-contrast-inverted: #FFFFFF; /* color-global-action-0 */
  --color-global-state-hover-text-shape-dark: #698BE2; /* color-global-action-400 */
  --color-global-state-hover-text-shape-default: #356DFE; /* color-global-action-500 */
  --color-global-state-hover-text-shape-inverted: #FFFFFF; /* color-global-action-0 */
  --color-global-white: #FFFFFF; /* color-neutral-0 */
  --color-neutral-border-contrast-dark: #A8A5B7; /* color-neutral-600 */
  --color-neutral-border-contrast-darker: #D9D8E1; /* color-neutral-500 */
  --color-neutral-border-contrast-default: #5A5675; /* color-neutral-700 */
  --color-neutral-border-contrast-light: #29234B; /* color-neutral-800 */
  --color-neutral-border-contrast-lighter: #120B37; /* color-neutral-900 */
  --color-neutral-border-dark: #D9D8E1; /* color-neutral-500 */
  --color-neutral-border-darker: #F9F9FC; /* color-neutral-300 */
  --color-neutral-border-default: #5A5675; /* color-neutral-700 */
  --color-neutral-border-light: #29234B; /* color-neutral-800 */
  --color-neutral-border-lighter: #120B37; /* color-neutral-900 */
  --color-neutral-surface-contrast-dark: #120B37; /* color-neutral-900 */
  --color-neutral-surface-contrast-darker: #04001A; /* color-neutral-1100 */
  --color-neutral-surface-contrast-default: #D9D8E1; /* color-neutral-500 */
  --color-neutral-surface-contrast-light: #F0F0F5; /* color-neutral-400 */
  --color-neutral-surface-dark: #F9F9FC; /* color-neutral-300 */
  --color-neutral-surface-darker: #FFFFFF; /* color-neutral-0 */
  --color-neutral-surface-default: #29234B; /* color-neutral-800 */
  --color-neutral-surface-light: #07002E; /* color-neutral-1000 */
  --color-neutral-surface-lighter: #04001A; /* color-neutral-1100 */
  --color-neutral-text-shape-contrast-default: #FFFFFF; /* color-neutral-0 */
  --color-neutral-text-shape-contrast-light: #A8A5B7; /* color-neutral-600 */
  --color-neutral-text-shape-contrast-lighter: #120B37; /* color-neutral-900 */
  --color-neutral-text-shape-dark: #FBFBFE; /* color-neutral-200 */
  --color-neutral-text-shape-darker: #FBFBFE; /* color-neutral-100 */
  --color-neutral-text-shape-default: #FBFBFE; /* color-neutral-200 */
  --color-neutral-text-shape-inverted-light: #5A5675; /* color-neutral-700 */
  --color-neutral-text-shape-inverted: #120B37; /* color-neutral-900 */
  --color-neutral-text-shape-light: #A8A5B7; /* color-neutral-600 */
  --color-neutral-text-shape-lighter: #120B37; /* color-neutral-900 */
}

@media (prefers-color-scheme: dark) {
  :root {
    --alpha-light: 6.0rem; /* alpha-600 */
    --alpha-max: 10.0rem; /* alpha-1000 */
    --alpha-none: 0.0rem; /* alpha-0 */
    --color-brand-abo-border-contrast-dark: #BA9204; /* color-brand-abo-800 */
    --color-brand-abo-border-contrast-darker: #2E2401; /* color-brand-abo-1100 */
    --color-brand-abo-border-contrast-default: #FFC805; /* color-brand-abo-600 */
    --color-brand-abo-border-contrast-light: #FFF3C8; /* color-brand-abo-200 */
    --color-brand-abo-border-contrast-lighter: #FFFAE7; /* color-brand-abo-100 */
    --color-brand-abo-border-dark: #FFEDAC; /* color-brand-abo-300 */
    --color-brand-abo-border-darker: #FFF3C8; /* color-brand-abo-200 */
    --color-brand-abo-border-default: #FFC805; /* color-brand-abo-600 */
    --color-brand-abo-border-light: #8B6D03; /* color-brand-abo-900 */
    --color-brand-abo-border-lighter: #2E2401; /* color-brand-abo-1100 */
    --color-brand-abo-surface-contrast-dark: #BA9204; /* color-brand-abo-800 */
    --color-brand-abo-surface-contrast-darker: #2E2401; /* color-brand-abo-1100 */
    --color-brand-abo-surface-contrast-default: #FFC805; /* color-brand-abo-600 */
    --color-brand-abo-surface-contrast-light: #FFF3C8; /* color-brand-abo-200 */
    --color-brand-abo-surface-contrast-lighter: #FFFAE7; /* color-brand-abo-100 */
    --color-brand-abo-surface-dark: #FFF3C8; /* color-brand-abo-200 */
    --color-brand-abo-surface-darker: #FFFAE7; /* color-brand-abo-100 */
    --color-brand-abo-surface-default: #FFC805; /* color-brand-abo-600 */
    --color-brand-abo-surface-light: #BA9204; /* color-brand-abo-800 */
    --color-brand-abo-surface-lighter: #2E2401; /* color-brand-abo-1100 */
    --color-brand-abo-text-shape-contrast-dark: #5D4902; /* color-brand-abo-1000 */
    --color-brand-abo-text-shape-contrast-darker: #2E2401; /* color-brand-abo-1100 */
    --color-brand-abo-text-shape-contrast-default: #FFC805; /* color-brand-abo-600 */
    --color-brand-abo-text-shape-contrast-inverted: #FFFFFF; /* color-brand-abo-0 */
    --color-brand-abo-text-shape-contrast-light: #FFE68D; /* color-brand-abo-400 */
    --color-brand-abo-text-shape-contrast-lighter: #FFFAE7; /* color-brand-abo-100 */
    --color-brand-abo-text-shape-dark: #FFE68D; /* color-brand-abo-400 */
    --color-brand-abo-text-shape-darker: #FFC805; /* color-brand-abo-600 */
    --color-brand-abo-text-shape-default: #FFC805; /* color-brand-abo-600 */
    --color-brand-abo-text-shape-inverted: #FFC805; /* color-brand-abo-600 */
    --color-brand-abo-text-shape-light: #5D4902; /* color-brand-abo-1000 */
    --color-brand-abo-text-shape-lighter: #2E2401; /* color-brand-abo-1100 */
    --color-brand-primary-border-dark: #551406; /* color-brand-primary-1100 */
    --color-brand-primary-border-darker: #2A0A03; /* color-brand-primary-1200 */
    --color-brand-primary-border-default: #FF3C11; /* color-brand-primary-700 */
    --color-brand-primary-border-light: #FF9E88; /* color-brand-primary-400 */
    --color-brand-primary-border-lighter: #FFECE7; /* color-brand-primary-100 */
    --color-brand-primary-surface-dark: #FFBEB0; /* color-brand-primary-300 */
    --color-brand-primary-surface-darker: #FFECE7; /* color-brand-primary-100 */
    --color-brand-primary-surface-default: #FF3C11; /* color-brand-primary-700 */
    --color-brand-primary-surface-light: #551406; /* color-brand-primary-1100 */
    --color-brand-primary-surface-lighter: #2A0A03; /* color-brand-primary-1200 */
    --color-brand-primary-text-shape-dark: #D5320E; /* color-brand-primary-800 */
    --color-brand-primary-text-shape-darker: #801E09; /* color-brand-primary-1000 */
    --color-brand-primary-text-shape-default: #FF3C11; /* color-brand-primary-700 */
    --color-brand-primary-text-shape-inverted: #FFFFFF; /* color-brand-primary-0 */
    --color-brand-primary-text-shape-light: #FFDFD7; /* color-brand-primary-200 */
    --color-brand-primary-text-shape-lighter: #FFECE7; /* color-brand-primary-100 */
    --color-feedback-alert-border-contrast-dark: #4F2600; /* color-feedback-alert-900 */
    --color-feedback-alert-border-contrast-darker: #090400; /* color-feedback-alert-1100 */
    --color-feedback-alert-border-contrast-default: #C45F00; /* color-feedback-alert-700 */
    --color-feedback-alert-border-contrast-light: #FBE8D7; /* color-feedback-alert-300 */
    --color-feedback-alert-border-contrast-lighter: #FFFDFC; /* color-feedback-alert-100 */
    --color-feedback-alert-border-dark: #FFFDFC; /* color-feedback-alert-100 */
    --color-feedback-alert-border-darker: #090400; /* color-feedback-alert-1100 */
    --color-feedback-alert-border-default: #C45F00; /* color-feedback-alert-700 */
    --color-feedback-alert-border-light: #4F2600; /* color-feedback-alert-900 */
    --color-feedback-alert-border-lighter: #090400; /* color-feedback-alert-1100 */
    --color-feedback-alert-surface-contrast-dark: #854100; /* color-feedback-alert-800 */
    --color-feedback-alert-surface-contrast-darker: #241200; /* color-feedback-alert-1000 */
    --color-feedback-alert-surface-contrast-default: #E88324; /* color-feedback-alert-600 */
    --color-feedback-alert-surface-contrast-light: #FEF9F5; /* color-feedback-alert-200 */
    --color-feedback-alert-surface-contrast-lighter: #FFFDFC; /* color-feedback-alert-100 */
    --color-feedback-alert-surface-dark: #FEF9F5; /* color-feedback-alert-200 */
    --color-feedback-alert-surface-darker: #FFFDFC; /* color-feedback-alert-100 */
    --color-feedback-alert-surface-default: #E88324; /* color-feedback-alert-600 */
    --color-feedback-alert-surface-light: #854100; /* color-feedback-alert-800 */
    --color-feedback-alert-surface-lighter: #241200; /* color-feedback-alert-1000 */
    --color-feedback-alert-text-shape-contrast-dark: #241200; /* color-feedback-alert-1000 */
    --color-feedback-alert-text-shape-contrast-darker: #090400; /* color-feedback-alert-1100 */
    --color-feedback-alert-text-shape-contrast-default: #C45F00; /* color-feedback-alert-700 */
    --color-feedback-alert-text-shape-contrast-inverted: #FFFDFC; /* color-feedback-alert-100 */
    --color-feedback-alert-text-shape-contrast-light: #FBE8D7; /* color-feedback-alert-300 */
    --color-feedback-alert-text-shape-contrast-lighter: #FFFDFC; /* color-feedback-alert-100 */
    --color-feedback-alert-text-shape-dark: #FBE8D7; /* color-feedback-alert-300 */
    --color-feedback-alert-text-shape-darker: #FFFDFC; /* color-feedback-alert-100 */
    --color-feedback-alert-text-shape-default: #C45F00; /* color-feedback-alert-700 */
    --color-feedback-alert-text-shape-inverted: #241200; /* color-feedback-alert-1000 */
    --color-feedback-alert-text-shape-light: #4F2600; /* color-feedback-alert-900 */
    --color-feedback-alert-text-shape-lighter: #241200; /* color-feedback-alert-1000 */
    --color-feedback-danger-border-contrast-dark: #5D0907; /* color-feedback-danger-1000 */
    --color-feedback-danger-border-contrast-darker: #390604; /* color-feedback-danger-1100 */
    --color-feedback-danger-border-contrast-default: #D41C17; /* color-feedback-danger-700 */
    --color-feedback-danger-border-contrast-light: #F3BFBD; /* color-feedback-danger-300 */
    --color-feedback-danger-border-contrast-lighter: #F9DFDE; /* color-feedback-danger-200 */
    --color-feedback-danger-border-dark: #F3BFBD; /* color-feedback-danger-300 */
    --color-feedback-danger-border-darker: #F9DFDE; /* color-feedback-danger-200 */
    --color-feedback-danger-border-default: #D41C17; /* color-feedback-danger-700 */
    --color-feedback-danger-border-light: #5D0907; /* color-feedback-danger-1000 */
    --color-feedback-danger-border-lighter: #390604; /* color-feedback-danger-1100 */
    --color-feedback-danger-surface-contrast-dark: #840D0A; /* color-feedback-danger-900 */
    --color-feedback-danger-surface-contrast-darker: #5D0907; /* color-feedback-danger-1000 */
    --color-feedback-danger-surface-contrast-default: #D41C17; /* color-feedback-danger-700 */
    --color-feedback-danger-surface-contrast-light: #F9DFDE; /* color-feedback-danger-200 */
    --color-feedback-danger-surface-contrast-lighter: #FCEDED; /* color-feedback-danger-100 */
    --color-feedback-danger-surface-dark: #F9DFDE; /* color-feedback-danger-200 */
    --color-feedback-danger-surface-darker: #FCEDED; /* color-feedback-danger-100 */
    --color-feedback-danger-surface-default: #D41C17; /* color-feedback-danger-700 */
    --color-feedback-danger-surface-light: #840D0A; /* color-feedback-danger-900 */
    --color-feedback-danger-surface-lighter: #5D0907; /* color-feedback-danger-1000 */
    --color-feedback-danger-text-shape-contrast-dark: #390604; /* color-feedback-danger-1100 */
    --color-feedback-danger-text-shape-contrast-default: #D41C17; /* color-feedback-danger-700 */
    --color-feedback-danger-text-shape-contrast-light: #FFFFFF; /* color-feedback-danger-0 */
    --color-feedback-danger-text-shape-dark: #F3BFBD; /* color-feedback-danger-300 */
    --color-feedback-danger-text-shape-default: #D41C17; /* color-feedback-danger-700 */
    --color-feedback-danger-text-shape-inverted: #FFFFFF; /* color-feedback-danger-0 */
    --color-feedback-danger-text-shape-light: #390604; /* color-feedback-danger-1100 */
    --color-feedback-info-border-contrast-dark: #001D28; /* color-feedback-info-1000 */
    --color-feedback-info-border-contrast-darker: #001D28; /* color-feedback-info-1000 */
    --color-feedback-info-border-contrast-default: #009FD7; /* color-feedback-info-700 */
    --color-feedback-info-border-contrast-light: #A7E7FE; /* color-feedback-info-400 */
    --color-feedback-info-border-contrast-lighter: #D7F4FE; /* color-feedback-info-300 */
    --color-feedback-info-border-dark: #A7E7FE; /* color-feedback-info-400 */
    --color-feedback-info-border-darker: #D7F4FE; /* color-feedback-info-300 */
    --color-feedback-info-border-default: #009FD7; /* color-feedback-info-700 */
    --color-feedback-info-border-light: #004057; /* color-feedback-info-900 */
    --color-feedback-info-border-lighter: #00070A; /* color-feedback-info-1100 */
    --color-feedback-info-surface-contrast-dark: #004057; /* color-feedback-info-900 */
    --color-feedback-info-surface-contrast-darker: #00070A; /* color-feedback-info-1100 */
    --color-feedback-info-surface-contrast-default: #009FD7; /* color-feedback-info-700 */
    --color-feedback-info-surface-contrast-light: #D7F4FE; /* color-feedback-info-300 */
    --color-feedback-info-surface-contrast-lighter: #F5FCFF; /* color-feedback-info-200 */
    --color-feedback-info-surface-dark: #D7F4FE; /* color-feedback-info-300 */
    --color-feedback-info-surface-darker: #F5FCFF; /* color-feedback-info-200 */
    --color-feedback-info-surface-default: #009FD7; /* color-feedback-info-700 */
    --color-feedback-info-surface-light: #006C93; /* color-feedback-info-800 */
    --color-feedback-info-surface-lighter: #001D28; /* color-feedback-info-1000 */
    --color-feedback-info-text-shape-contrast-dark: #004057; /* color-feedback-info-900 */
    --color-feedback-info-text-shape-contrast-darker: #001D28; /* color-feedback-info-1000 */
    --color-feedback-info-text-shape-contrast-default: #006C93; /* color-feedback-info-800 */
    --color-feedback-info-text-shape-contrast-inverted: #F5FCFF; /* color-feedback-info-200 */
    --color-feedback-info-text-shape-contrast-light: #A7E7FE; /* color-feedback-info-400 */
    --color-feedback-info-text-shape-contrast-lighter: #F5FCFF; /* color-feedback-info-200 */
    --color-feedback-info-text-shape-dark: #F5FCFF; /* color-feedback-info-200 */
    --color-feedback-info-text-shape-darker: #F5FCFF; /* color-feedback-info-200 */
    --color-feedback-info-text-shape-default: #009FD7; /* color-feedback-info-700 */
    --color-feedback-info-text-shape-inverted: #001D28; /* color-feedback-info-1000 */
    --color-feedback-info-text-shape-light: #004057; /* color-feedback-info-900 */
    --color-feedback-info-text-shape-lighter: #001D28; /* color-feedback-info-1000 */
    --color-feedback-success-border-contrast-dark: #023A0B; /* color-feedback-success-900 */
    --color-feedback-success-border-contrast-darker: #011A05; /* color-feedback-success-1000 */
    --color-feedback-success-border-contrast-default: #068F1C; /* color-feedback-success-700 */
    --color-feedback-success-border-contrast-light: #6ECC7D; /* color-feedback-success-500 */
    --color-feedback-success-border-contrast-lighter: #D8F1DC; /* color-feedback-success-300 */
    --color-feedback-success-border-dark: #6ECC7D; /* color-feedback-success-500 */
    --color-feedback-success-border-darker: #D8F1DC; /* color-feedback-success-300 */
    --color-feedback-success-border-default: #068F1C; /* color-feedback-success-700 */
    --color-feedback-success-border-light: #046213; /* color-feedback-success-800 */
    --color-feedback-success-border-lighter: #023A0B; /* color-feedback-success-900 */
    --color-feedback-success-surface-contrast-dark: #046213; /* color-feedback-success-800 */
    --color-feedback-success-surface-contrast-darker: #011A05; /* color-feedback-success-1000 */
    --color-feedback-success-surface-contrast-default: #068F1C; /* color-feedback-success-700 */
    --color-feedback-success-surface-contrast-light: #A9E1B2; /* color-feedback-success-400 */
    --color-feedback-success-surface-contrast-lighter: #F5FBF6; /* color-feedback-success-200 */
    --color-feedback-success-surface-dark: #A9E1B2; /* color-feedback-success-400 */
    --color-feedback-success-surface-darker: #F5FBF6; /* color-feedback-success-200 */
    --color-feedback-success-surface-default: #068F1C; /* color-feedback-success-700 */
    --color-feedback-success-surface-light: #046213; /* color-feedback-success-800 */
    --color-feedback-success-surface-lighter: #011A05; /* color-feedback-success-1000 */
    --color-feedback-success-text-shape-contrast-dark: #011A05; /* color-feedback-success-1000 */
    --color-feedback-success-text-shape-contrast-default: #046213; /* color-feedback-success-800 */
    --color-feedback-success-text-shape-contrast-inverted: #FCFEFC; /* color-feedback-success-100 */
    --color-feedback-success-text-shape-contrast-light: #2AB341; /* color-feedback-success-600 */
    --color-feedback-success-text-shape-dark: #2AB341; /* color-feedback-success-600 */
    --color-feedback-success-text-shape-default: #046213; /* color-feedback-success-800 */
    --color-feedback-success-text-shape-inverted: #011A05; /* color-feedback-success-1000 */
    --color-feedback-success-text-shape-light: #011A05; /* color-feedback-success-1000 */
    --color-global-background-dark: #F0F0F5; /* color-neutral-400 */
    --color-global-background-darker: #FFFFFF; /* color-neutral-0 */
    --color-global-background-default: #29234B; /* color-neutral-800 */
    --color-global-background-light: #120B37; /* color-neutral-900 */
    --color-global-background-lighter: #07002E; /* color-neutral-1000 */
    --color-global-background-paywall-dark: #F0F0F5; /* color-neutral-400 */
    --color-global-background-paywall-darker: #FFFFFF; /* color-neutral-0 */
    --color-global-background-paywall-default: #120B37; /* color-neutral-900 */
    --color-global-background-paywall-light: #07002E; /* color-neutral-1000 */
    --color-global-background-paywall-lighter: #07002E; /* color-neutral-1000 */
    --color-global-highlight-surface: #F9C83A; /* color-global-highlight-400 */
    --color-global-highlight-text: #1D1707; /* color-global-highlight-1000 */
    --color-global-ia-border-dark: #F2E4F5; /* color-global-ia-200 */
    --color-global-ia-border-default: #D39EDF; /* color-global-ia-400 */
    --color-global-ia-border-light: #730E82; /* color-global-ia-900 */
    --color-global-ia-surface-dark: #F8F2FA; /* color-global-ia-100 */
    --color-global-ia-surface-default: #8A119B; /* color-global-ia-800 */
    --color-global-ia-surface-light: #570B62; /* color-global-ia-1000 */
    --color-global-ia-text-shape-dark: #F8F2FA; /* color-global-ia-100 */
    --color-global-ia-text-shape-default: #F2E4F5; /* color-global-ia-200 */
    --color-global-ia-text-shape-light: #570B62; /* color-global-ia-1000 */
    --color-global-monito-border-dark: #D5EDFF; /* color-global-color-global-monito-200 */
    --color-global-monito-border-default: #87CBFF; /* color-global-color-global-monito-400 */
    --color-global-monito-border-light: #033A75; /* color-global-color-global-monito-900 */
    --color-global-monito-surface-dark: #EFF8FF; /* color-global-color-global-monito-100 */
    --color-global-monito-surface-default: #005FAD; /* color-global-color-global-monito-800 */
    --color-global-monito-surface-light: #01244C; /* color-global-color-global-monito-1000 */
    --color-global-monito-text-shape-dark: #EFF8FF; /* color-global-color-global-monito-100 */
    --color-global-monito-text-shape-default: #D5EDFF; /* color-global-color-global-monito-200 */
    --color-global-monito-text-shape-light: #01244C; /* color-global-color-global-monito-1000 */
    --color-global-post-it-border-dark: #877F59; /* color-global-post-it-900 */
    --color-global-post-it-border-default: #D7CA8D; /* color-global-post-it-700 */
    --color-global-post-it-border-light: #FBF0C0; /* color-global-post-it-500 */
    --color-global-post-it-surface-dark: #B1A674; /* color-global-post-it-800 */
    --color-global-post-it-surface-default: #FAEBA4; /* color-global-post-it-600 */
    --color-global-post-it-surface-light: #FDF5D6; /* color-global-post-it-400 */
    --color-global-post-it-text-shape-default: #2E2B1E; /* color-global-post-it-1100 */
    --color-global-post-it-text-shape-light: #5C563C; /* color-global-post-it-1000 */
    --color-global-social-bluesky: #0A7AFF; /* raw */
    --color-global-social-facebook: #1877F2; /* raw */
    --color-global-social-linkedin: #0A66C2; /* raw */
    --color-global-social-twitter: #000000; /* raw */
    --color-global-state-disabled-border-contrasted: #29234B; /* color-neutral-800 */
    --color-global-state-disabled-border-default: #29234B; /* color-neutral-800 */
    --color-global-state-disabled-border-inverted: #F0F0F5; /* color-neutral-400 */
    --color-global-state-disabled-surface-contrasted: #29234B; /* color-neutral-800 */
    --color-global-state-disabled-surface-default: #29234B; /* color-neutral-800 */
    --color-global-state-disabled-surface-inverted: #F0F0F5; /* color-neutral-400 */
    --color-global-state-disabled-text-shape-contrasted: #5A5675; /* color-neutral-700 */
    --color-global-state-disabled-text-shape-default: #5A5675; /* color-neutral-700 */
    --color-global-state-disabled-text-shape-inverted: #120B37; /* color-neutral-900 */
    --color-global-state-hover-border-dark: #03257C; /* color-global-action-800 */
    --color-global-state-hover-border-default: #053DCE; /* color-global-action-600 */
    --color-global-state-hover-border-light: #03257C; /* color-global-action-800 */
    --color-global-state-hover-surface-dark: #03257C; /* color-global-action-800 */
    --color-global-state-hover-surface-default: #053DCE; /* color-global-action-600 */
    --color-global-state-hover-surface-light: #03257C; /* color-global-action-800 */
    --color-global-state-hover-surface-lighter: #021852; /* color-global-action-900 */
    --color-global-state-hover-text-shape-contrast-default: #356DFE; /* color-global-action-500 */
    --color-global-state-hover-text-shape-contrast-inverted: #FFFFFF; /* color-global-action-0 */
    --color-global-state-hover-text-shape-dark: #698BE2; /* color-global-action-400 */
    --color-global-state-hover-text-shape-default: #356DFE; /* color-global-action-500 */
    --color-global-state-hover-text-shape-inverted: #FFFFFF; /* color-global-action-0 */
    --color-global-white: #FFFFFF; /* color-neutral-0 */
    --color-neutral-border-contrast-dark: #A8A5B7; /* color-neutral-600 */
    --color-neutral-border-contrast-darker: #D9D8E1; /* color-neutral-500 */
    --color-neutral-border-contrast-default: #5A5675; /* color-neutral-700 */
    --color-neutral-border-contrast-light: #29234B; /* color-neutral-800 */
    --color-neutral-border-contrast-lighter: #120B37; /* color-neutral-900 */
    --color-neutral-border-dark: #D9D8E1; /* color-neutral-500 */
    --color-neutral-border-darker: #F9F9FC; /* color-neutral-300 */
    --color-neutral-border-default: #5A5675; /* color-neutral-700 */
    --color-neutral-border-light: #29234B; /* color-neutral-800 */
    --color-neutral-border-lighter: #120B37; /* color-neutral-900 */
    --color-neutral-surface-contrast-dark: #120B37; /* color-neutral-900 */
    --color-neutral-surface-contrast-darker: #04001A; /* color-neutral-1100 */
    --color-neutral-surface-contrast-default: #D9D8E1; /* color-neutral-500 */
    --color-neutral-surface-contrast-light: #F0F0F5; /* color-neutral-400 */
    --color-neutral-surface-dark: #F9F9FC; /* color-neutral-300 */
    --color-neutral-surface-darker: #FFFFFF; /* color-neutral-0 */
    --color-neutral-surface-default: #29234B; /* color-neutral-800 */
    --color-neutral-surface-light: #07002E; /* color-neutral-1000 */
    --color-neutral-surface-lighter: #04001A; /* color-neutral-1100 */
    --color-neutral-text-shape-contrast-default: #FFFFFF; /* color-neutral-0 */
    --color-neutral-text-shape-contrast-light: #A8A5B7; /* color-neutral-600 */
    --color-neutral-text-shape-contrast-lighter: #120B37; /* color-neutral-900 */
    --color-neutral-text-shape-dark: #FBFBFE; /* color-neutral-200 */
    --color-neutral-text-shape-darker: #FBFBFE; /* color-neutral-100 */
    --color-neutral-text-shape-default: #FBFBFE; /* color-neutral-200 */
    --color-neutral-text-shape-inverted-light: #5A5675; /* color-neutral-700 */
    --color-neutral-text-shape-inverted: #120B37; /* color-neutral-900 */
    --color-neutral-text-shape-light: #A8A5B7; /* color-neutral-600 */
    --color-neutral-text-shape-lighter: #120B37; /* color-neutral-900 */
  }
}
