.layout-team {
  background: var(--color-neutral-surface-contrast-dark);
  padding: var(--spacing-xxl) 0 var(--spacing-48) 0;
  max-width: none;
}

.team__title {
  color: var(--color-neutral-text-shape-contrast-default);
  @include font-condensed-l-strong;
}

.team__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-xxl);
  margin-top: var(--spacing-xl);
}

.team__member {
  display: flex;
  border-radius: var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-contrast-light);
  background: var(--color-neutral-surface-contrast-dark);

  padding: var(--spacing-xl);
  align-items: center;
  gap: var(--spacing-xl);
}

.team__member-avatar {
  width: 8.8rem;
  height: 8.8rem;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: var(--radius-max);
  border: 1px solid var(--color-neutral-border-contrast-light);
}

.team__member-name-column {
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-l);
  flex-grow: 1;
}

.team__member-name-container {
  flex-grow: 1;
}

.team__member-name {
  color: var(--color-neutral-text-shape-contrast-default);
  @include font-sans-m-strong;
}

.team__member-role {
  color: var(--color-neutral-text-shape-contrast-light);
  @include font-sans-xs-default;
}

.team__member-button {
  white-space: normal;

  &.-secondary{
    background: var(--color-neutral-surface-contrast-light);
    color: var(--color-neutral-text-shape-contrast-lighter);
  }
}

/* Responsive */

@media screen and (max-width: $breakpoint-m) {
  .team__title {
    @include font-condensed-m-strong;
  }

  .team__grid {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
  }

  .team__member {
    flex-direction: column;
    padding: var(--spacing-l);
    gap: var(--spacing-xxs);
  }

  .team__member-name-column {
    gap: var(--spacing-xl);
    align-items: center;
    text-align: center;
  }

  .team__member-name {
    @include font-sans-s-semi;
  }

  .team__member-role {
    @include font-interface-xxs-default;
    margin-top: var(--spacing-xxs);
  }
}

@media screen and (max-width: $breakpoint-s) {
  .layout-team {
    padding: var(--spacing-xxxl) var(--spacing-xl) var(--spacing-xxxxl) var(--spacing-xl);
  }

  .team__grid {
    gap: var(--spacing-l);
  }

  .team__member-avatar {
    width: 4.8rem;
    height: 4.8rem;
  }

  .team__member-name {
    @include font-sans-xs-semi;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .layout-team {
    padding: var(--spacing-xl) var(--spacing-xl) var(--spacing-xxxl) var(--spacing-xl);

  }

  .team__grid {
    display: flex;
    flex-direction: column;
  }

  .team__member {
    flex-direction: row;
    padding: var(--spacing-s);
    gap: var(--spacing-l);
  }

  .team__member-avatar {
    width: 3.2rem;
    height: 3.2rem;
  }

  .team__member-name-column {
    text-align: left;
    flex-direction: row;
  }

  .team__member-button {
    white-space: nowrap;
  }
}
