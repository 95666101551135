.preview-block {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-xl);
  gap: var(--spacing-l);
  text-decoration: none;
  color: var(--color-neutral-text-shape-default);

  &[href]:hover .preview-block__title {
    color: var(--color-global-state-hover-text-shape-default);
  }
}

@media screen and (max-width: $breakpoint-m) {
  .preview-block {
    font-size: 1rem;
  }
}

.preview-block__tweet {
  padding: 0;

  .twitter-tweet {
    margin: 0 !important;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .preview-block {
    pointer-events: unset;
    position: fixed;
    width: 100%;
    top: unset;
    bottom: 0;
    left: 0;
    right: 0;
    transform: none;
    max-height: 33dvh;
    overflow-y: auto;
    box-shadow: 0 -0.5rem 8px rgba(0, 0, 0, 0.25);
    border: 0 none;
    background-color: var(--color-neutral-surface-lighter);
  }
}

.preview-block__doc {
  background: var(--color-feedback-info-surface-darker);
  padding-block: var(--spacing-s);
  display: flex;
  justify-content: center;
  height: 12rem;

  img {
    height: 100%;
  }
}

.preview-block__title {
  @include font-sans-s-semi;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.preview-block__editorial-nature {
  @include font-condensed-s-strong;
  color: var(--color-brand-primary-text-shape-default);
}

.preview-block__transcript {
  display: inline-flex;
  padding: var(--spacing-xxs) var(--spacing-s) var(--spacing-xxs) var(--spacing-xxs);
  margin-bottom: var(--spacing-xs);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs);
  border-radius: var(--radius-min);
  background: var(--color-global-ia-surface-light);
  color: var(--color-global-ia-text-shape-default, #9E14B2);
  @include font-interface-xxs-default;
}

.preview-block__subtitle {
  @include font-sans-xs-default;
  color: var(--color-neutral-text-shape-default);
}

.preview-block__footer {
  @include font-sans-xs-default;
  color: var(--color-neutral-text-shape-light);
}

.preview-block__footer-bullet {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background: var(--color-neutral-text-shape-lighter);
  border-radius: var(--radius-max);
  margin-inline: var(--spacing-xxxs);
  vertical-align: 2px;
}

.preview-block__bill-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xxs);
  align-self: stretch;
  text-decoration: none;

  &[href]:hover .preview-block__title {
    color: var(--color-global-state-hover-text-shape-default);
  }
}

.preview-block__bill {
  color: var(--color-global-monito-text-shape-default);
  @include font-condensed-s-strong;
}

.watch.preview-block__watch-button {
  border: 1px solid var(--color-neutral-border-light);
  @include font-interface-s-semi;
  padding: var(--spacing-xs) var(--spacing-m);
  border-radius: var(--radius-min);
  color: var(--color-neutral-text-shape-default);
  min-height: 4rem;
  min-width: 9.4rem;

  .watch__icon {
    margin-right: var(--spacing-s);
  }

  .loading-spinner {
    opacity: 0.4;
  }

  &.-watched {
    &:hover {
      .watch__label.-watched {
        display: unset !important;
      }

      .watch__icon {
        stroke: var(--color-global-white);
        fill: var(--color-global-white) !important;
      }
    }
  }

  &:not(.-watched) {
    stroke: var(--color-global-white);
  }

  &:hover {
    background-color: var(--color-global-state-hover-surface-default);
    color: var(--color-global-state-hover-text-shape-inverted);
    border-color: var(--color-global-state-hover-surface-default);

    .watch__label.-hover.-watched {
      display: none !important;
    }

    &:not(.-watched) .watch__icon {
      stroke: var(--color-global-white);
      fill: transparent;
    }
  }
}

.preview-block__external-header {
  display: flex;
  margin: calc(-1 * var(--spacing-xl));
  margin-bottom: 0;
  padding: var(--spacing-l) var(--spacing-xl);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  border-bottom: 1px solid var(--color-neutral-border-light);
  color: var(--color-feedback-info-text-shape-default);
}

.preview-block__external:not(:last-of-type) {
  padding-bottom: 0.3rem;
}

.preview-block__domain {
  @include font-sans-s-semi;
  flex-grow: 1;
  direction: rtl;
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.preview-block__favicon {
  flex-shrink: 0;
  border: 1px solid var(--color-neutral-border-lighter);
  height: 1.8rem;
  width: 1.8rem;
  padding: 0.2rem;
}

.preview-block__icon-external {
  flex-shrink: 0;
  fill: currentColor;
}

.preview-block__snippet {
  margin-top: var(--spacing-xxs);
  color: var(--color-neutral-text-shape-default);

  @include font-sans-xs-default;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.preview-block__geo {
  @include font-condensed-s-strong;
  color: var(--color-brand-primary-text-shape-default);
  margin-right: 0.25rem;
}

.preview-block__image,
.preview-block__doc {
  margin: calc(-1 * var(--spacing-xl));
  margin-bottom: 0;
  max-width: none;
  object-fit: cover;
}

.preview-block__image {
  height: 15rem;
}

@media screen and (max-width: $breakpoint-mobile) {
  .preview-block__editorial-nature {
    @include font-condensed-xs-strong;
  }
}
