/*____________________ Main column ________________________*/

.homepage {
  max-width: $breakpoint-l;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-none);
  padding: var(--spacing-88) var(--spacing-xxl) 0;

  &, .newscard {
    --left-part-max-width: 68.8rem;
    --reverse-left-part-min-width: 30rem;
    --right-part-min-width: 36rem;
  }

  .layout-stage.-with-banner {
    grid-template-columns: 0 1fr 0;

    .main-column {
      width: 100%;
      padding: var(--spacing-none);
    }
  }

  /*____________________ Superuser data ________________________*/

  .homepage-board {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
  }

  .homepage-board__data {
    color: var(--color-neutral-text-shape-inverted);
    padding: var(--spacing-xxxxs) var(--spacing-m);
    border-radius: var(--radius-min);
    background-color: var(--color-neutral-text-shape-default);

    &.-highlighted {
      background-color: var(--color-feedback-info-surface-default);
    }

    &.-high {
      background-color: var(--color-feedback-success-surface-default);
    }

    &.-medium {
      background-color: var(--color-feedback-alert-surface-default);
    }

    &.-low {
      background-color: var(--color-feedback-danger-surface-default);
    }

  }
}

/*____________________ Generic ________________________*/

.homepage__banner {
  max-width: 72.3rem;
  width: 100%;

  .banner__container {
    padding-top: var(--spacing-xxl);
    margin-bottom: calc(-1* var(--spacing-xxl));
  }
}

.homepage__card {
  max-width: 123.2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: var(--spacing-xxl) var(--spacing-xxl);
  padding: var(--spacing-xxl);

  border-radius: var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-light);
  background: var(--color-global-background-lighter);

  &.-top-article {
    padding: var(--spacing-none);
  }

  a {
    text-decoration: none;

    &:hover {
      .card__title {
        color: var(--color-global-state-hover-text-shape-default);
      }
    }
  }
}

.card-ticker {
  background: none;
  padding: var(--spacing-xl) var(--spacing-xxl);
  background: var(--color-global-background-default);
}

/*____________________ Tag block ________________________*/

.card-tag-block {
  &.-top-article {
    border: none;
    background: none;
  }
}

.tag-block__columns {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-xxxl);

  padding: var(--spacing-none);
  align-self: stretch;
}

.tag-block__newscard {
  flex: 2 2 0;
  max-width: var(--left-part-max-width);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxl);

  .newscard {
    gap: var(--spacing-xl);
  }

  .thumbnail {
    --copyright-line-clamp: 19;
  }

  &.-simple-article {
    max-width: none;
    width: 100%;

    .newscard {
      gap: var(--spacing-xxxl);
    }
  }
}

.block-multi-article {
  display: grid;
  gap: var(--spacing-xxl) var(--spacing-xxl);
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;

  .newscard {
    gap: var(--spacing-s);

  }

  .thumbnail {
    --copyright-line-clamp: 9;
  }

  .newscard__article-title {
    @include font-sans-xl-strong;
  }
}

.tag-block__column-focus {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxl);
  flex: 1 2 0;
  align-self: stretch;
  min-width: var(--right-part-min-width);

  .editorials__item-content {
    max-width: 67.5rem;
  }
}

.tag-block__focus-name {
  color: var(--color-neutral-text-shape-light);
  @include font-sans-xl-strong;

  &:hover {
    color: var(--color-global-state-hover-text-shape-default);
  }
}

/*____________________ Briefitem ________________________*/

.card-briefitem {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--spacing-l) var(--spacing-l);

  .briefitem__geography {
    color: var(--color-brand-primary-text-shape-default, #FF3C11);
    @include font-condensed-xxl-strong;
  }
}

/*____________________ Responsive ________________________*/

@media screen and (min-width: $breakpoint-m) {
  .homepage__card {
    &.-top-article {
      padding-top: var(--spacing-xxl);
    }

    .newscard.-horizontal.-top-article {
      align-items: flex-end;

      .newscard__thumbnail {
        flex-direction: column-reverse;
      }

      .newscard__wrap-text {
        flex-direction: column-reverse;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-m) {
  .homepage {
    padding: calc(var(--spacing-88) + var(--spacing-xl)) var(--spacing-xl) var(--spacing-xl);
    gap: var(--spacing-xxl);

    &, .newscard {
      --left-part-max-width: 51.1rem;
      --reverse-left-part-min-width: 20rem;
    }
  }

  .homepage__banner {
    max-width: 100%;
    margin: var(--spacing-none);

    .banner__container {
      padding-top: 0;
      margin-bottom: 0;
    }
  }

  .homepage__card {
    max-width: 100%;
    margin: var(--spacing-none);
    padding: var(--spacing-xl);

    .newscard {
      --horizontal-chapo-line-clamp: 5;
      gap: var(--spacing-xxl);
    }

    .tag-block__columns {
      gap: var(--spacing-xxl);
    }

    .block-multi-article {
      .newscard {
        gap: var(--spacing-s);
      }
    }
  }

  .card-ticker {
    padding: var(--spacing-l) var(--spacing-xl);

    .ticker {
      --briefing-content-height: 4.2rem;
      gap: var(--spacing-s);
    }

    .ticker__cta-text {
      @include font-produkt-l;
    }

    .ticker__briefing-edition {
      padding: 1.1rem var(--spacing-l) 1.6rem var(--spacing-l);
    }

    .ticker__kicker {
      display: none;
      padding: 0.7rem var(--spacing-l) 0.9rem var(--spacing-l);
      padding: var(--spacing-s) var(--spacing-l);
    }

    .ticker__briefitem-content {
      padding: 0.7rem var(--spacing-l) 0.9rem var(--spacing-l);
    }
  }

  .card-briefitem {
    gap: var(--spacing-s) var(--spacing-s);
    padding: var(--spacing-xl) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);

    .briefitem__geography {
      @include font-condensed-xl-strong;
    }

    .briefitem-newscard__wrap-text .briefitem-newscard__content .briefitem-newscard__title {
      @include font-sans-xl-strong;
    }
  }

  .tag-block__newscard {
    gap: var(--spacing-xl);

    .thumbnail {
      --copyright-line-clamp: 14;
    }

    .-top-article {
      .newscard__article-title {
        @include font-sans-xxl-strong;
      }
    }

    .newscard__article-title {
      @include font-sans-xxl-strong;
    }

    .newscard__article-header {
      @include font-serif-m-medium;
    }

    &.-simple-article {
      .newscard {
        gap: var(--spacing-xxl);
      }
    }
  }

  .block-multi-article {
    gap: var(--spacing-xl);

    .thumbnail {
      --copyright-line-clamp: 6;
    }

    .newscard__article-title {
      @include font-sans-l-strong;
    }
  }
}

@media screen and (max-width: $breakpoint-s) {
  .homepage {
    gap: var(--spacing-xl);
    --left-part-max-width: 100%;
    --right-part-min-width: var(--spacing-none);
  }

  .card-tag-block {
    padding: var(--spacing-l) var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);

    // if tag-less article, with not image
    &:not(:has(.tag-block__column-focus)):not(:has(.thumbnail)) {
      padding-top: var(--spacing-xl);
    }
  }

  .homepage__card:not(.-top-article) {
    .tag-block__column-newscard .newscard__article-title {
      @include font-sans-xl-strong;
    }
  }

  .tag-block__columns {
    flex-direction: column;
    gap: var(--spacing-xxl);

    .tag-block__column-newscard {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-s);
      align-self: stretch;
    }
  }

  .card-briefitem {
    padding: var(--spacing-xl) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);

    .briefitem__geography {
      @include font-condensed-l-strong;
    }

    .briefitem-newscard__title {
      @include font-sans-l-strong;
    }
  }

  .tag-block__newscard .thumbnail {
    --copyright-line-clamp: 19;
  }

  .block-multi-article .thumbnail {
    --copyright-line-clamp: 9;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .homepage {
    padding: calc(var(--spacing-64) + var(--spacing-xl)) var(--spacing-xl) var(--spacing-xl);
  }

  .card-tag-block {
    padding: var(--spacing-l) var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);
  }

  .card-briefitem {
    padding: var(--spacing-l) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);
  }

  .tag-block__newscard .thumbnail {
    --copyright-line-clamp: 12;
  }

  .block-multi-article {
    grid-template-columns: 1fr;

    .thumbnail {
      --copyright-line-clamp: 9;
    }

    .newscard__article-title {
      @include font-sans-xl-strong;
    }
  }

  .card-briefitem {
    padding: var(--spacing-l) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);
  }

  .card-ticker {
    padding: var(--spacing-l) var(--spacing-xl);

    .ticker__briefing-container {
      display: none;
    }
  }
}
