/*____________________ Ticker ________________________*/

.ticker {
  width: 100%;

  --briefing-content-height: 5.6rem;

  display: flex;
  text-decoration: none;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xl);
  flex: 1 0 0;

  .ticker__briefing-container {
    height: var(--briefing-content-height);
  }

  // useful for animation
  .ticker__briefing {
    height: var(--briefing-content-height);
  }
}

.ticker__cta {
  display: flex;
  padding: var(--spacing-none) var(--spacing-none);
  align-items: center;
  gap: var(--spacing-s);

  &:hover,
  &:focus {
    .ticker__cta-chevron.-off-hover {
      display: none;
    }

    .ticker__cta-chevron.-on-hover {
      display: flex;
    }
  }

  &:hover {
    .ticker__cta-text, .ticker__cta-text .-red {
      color: var(--color-global-state-hover-text-shape-default);
    }
  }

  &:focus {
    .ticker__cta-text, .ticker__cta-text .-red {
      color: var(--color-global-state-hover-text-shape-dark);
    }

    .ticker__cta-chevron.-on-hover {
      background: var(--color-global-state-hover-surface-dark);
    }
  }
}

.ticker__cta-text {
  display: flex;
  align-items: stretch;
  gap: var(--spacing-xxs);

  color: var(--color-neutral-text-shape-default);
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'ss09' on, 'ss08' on, 'ss07' on, 'liga' off;
  @include font-produkt-xl;

  .-red {
    color: var(--color-brand-primary-text-shape-default);
  }
}

.ticker__cta-chevron {
  display: flex;
  width: 2.4rem;
  height: 2.4rem;
  padding: var(--spacing-xxs);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-none);
  border-radius: var(--radius-max);
  border: 1px solid var(--color-neutral-border-light);
  background: var(--color-global-background-light);

  svg {
    width: var(--font-size-s);
    height: var(--font-size-s);
  }

  &.-on-hover {
    display: none;
    background: var(--color-global-state-hover-surface-default);;
  }
}

.ticker__briefing-container,
.ticker__kicker-container,
.ticker__briefitem-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  align-self: stretch;
}

.ticker__briefing-box,
.ticker__kicker-box,
.ticker__briefitem-box {
  height: 100%;
  display: flex;
  padding: var(--spacing-none);
  align-items: center;
  gap: var(--spacing-none);
  justify-content: flex-start;
}

.ticker__briefing-container {
  border-radius: var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-light);
  background: var(--color-neutral-surface-lighter);

  &:hover {
    border: 1px solid var(--color-global-state-hover-border-default);
    background: var(--color-global-state-hover-surface-default);
  }

  &:focus {
    border: 1px solid var(--color-global-state-hover-border-dark);
    background: var(--color-global-state-hover-surface-dark);
  }

  &:hover,
  &:focus {
    .ticker__briefing-edition,
    .ticker__kicker,
    .ticker__briefitem-geo,
    .ticker__briefitem-title {
      color: var(--color-global-state-hover-text-shape-inverted);
    }

    .ticker__briefing-edition,
    .ticker__kicker {
      border-right: 1px solid var(--color-global-state-hover-border-light);
    }
  }
}

.ticker__briefing-edition {
  display: flex;
  padding: var(--spacing-xl) var(--spacing-xl) 2.1rem var(--spacing-xl);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-right: 1px solid var(--color-neutral-border-light);
  text-align: center;
  text-wrap: nowrap;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'ss07' on, 'ss09' on, 'liga' off;

  color: var(--color-neutral-text-shape-default);
  @include font-edition-xl;
}

.ticker__kicker {
  display: flex;
  padding: 0 var(--spacing-xl) var(--spacing-xxxs) var(--spacing-xl);
  align-items: center;
  border-right: 1px solid var(--color-neutral-border-light);
  align-self: stretch;

  text-wrap: nowrap;
  color: var(--color-neutral-text-shape-light);
  @include font-sans-l-strong
}

.ticker__briefitem-content {
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: var(--spacing-none) var(--spacing-xl) var(--spacing-xxxxs) var(--spacing-xl);
  gap: var(--spacing-s);
}

.ticker__briefitem-geo {
  color: var(--color-brand-primary-text-shape-default);
  text-wrap: nowrap;
  @include font-condensed-l-strong;
}

.ticker__briefitem-title {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  color: var(--color-neutral-text-shape-default);
  @include font-sans-l-semi;
}

.ticker__briefitem {
  position: absolute;
  width: 100%;
}

.ticker__kicker-content {
  background: var(--color-global-background-light);
}

// Ticker animation

.ticker__hidden {
  opacity: 0;
}

@keyframes slide-up {
  from {
    margin-top: 0;
  }
  to {
    margin-top: calc(var(--briefing-content-height) * -1);
  }
}

.ticker .ticker__slide-up {
  animation: slide-up 800ms cubic-bezier(0.4, 0, 0.2, 1) both;
  border-bottom: 1px solid var(--color-global-state-hover-border-light);
}

:root.ctx-dark-mode .ticker {
  .ticker__cta-chevron.-off-hover {
    display: none;
  }

  .ticker__cta:not(:hover):not(:focus) {
    .ticker__cta-chevron.-on-hover {
      display: flex;
      background-color: var(--color-global-background-light);
    }
  }
}

@media (prefers-color-scheme: dark) {
  :root:not(.ctx-light-mode) .ticker {
    .ticker__cta-chevron.-off-hover {
      display: none;
    }

    .ticker__cta:not(:hover):not(:focus) {
      .ticker__cta-chevron.-on-hover {
        display: flex;
        background-color: var(--color-global-background-light);
      }
    }
  }
}
