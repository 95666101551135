.dropdown {
  position: relative;
}

.dropdown__button {
  cursor: pointer;
  user-select: none;
}

.dropdown__body {
  position: absolute;
  top: 2.5rem;
  z-index: 99;
}

.dropdown__body.-min_width {
  min-width: 24rem;
}

.dropdown__body.-full-width {
  width: 100%;
}

.dropdown__body.-right {
  right: 0;
}

.dropdown:not(.-open) .dropdown__body {
  display: none;
}
