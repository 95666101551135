/* Article */
.editorial__article-nature {
  display: block;
  color: var(--color-brand-primary-text-shape-default);

  &.-header {
    @include font-sans-xxl-strong;
    margin-bottom: var(--spacing-l);
  }
}

.editorial__article-restriction {
  margin-top: 0.5rem;
  border-left: 3px solid var(--color-abo-surface-dark-default);
  padding-left: 0.5rem;
}

.article-related-tags {
  display: flex;
  margin-top: var(--spacing-l);
  gap: var(--spacing-xxs);
  border-top: 1px solid var(--color-neutral-border-light);
  padding: var(--spacing-m) 0;
  flex-wrap: wrap;
  /* Truncate tags when needed */
  max-width: 85vw;

  @include font-sans-s-default;
}

.article-related-tags__tag {
  &,
  &:visited {
    color: var(--color-neutral-text-shape-light);

    &:hover,
    &:focus-visible {
      color: var(--color-global-state-hover-text-shape-default);
    }
  }

  text-decoration: none;

  &.-up-to-date {
    color: var(--color-neutral-text-shape-default);

    &:hover,
    &:focus-visible {
      color: var(--color-global-state-hover-text-shape-default);
    }
  }

  &:focus-visible, &:hover {
    text-decoration: underline;
  }
}

.article-related-tag__separator {
  color: var(--color-neutral-text-shape-light);
}


/* Inverted skin */

.editorial__article.-inverted {
  & {
    color: var(--color-neutral-text-shape-contrast-default);
  }
}

/* Responsive */

@media screen and (max-width: $breakpoint-m) {
  .editorial__article-nature.-header {
    @include font-sans-xl-strong;
  }


}

@media screen and (max-width: $breakpoint-mobile) {
  .editorial__article-nature.-preview {
    @include font-condensed-s-strong;
  }
}
