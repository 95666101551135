.facet .dropdown__body {
  top: 4rem;
}

.facet__heading {
  background-color: var(--color-lois--white-dark);
  border: 1px solid var(--color-lois--gray-blue-alpha-30);
  border-radius: 50px;
  color: var(--color-lois--gray-green);
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.7;
  margin: 0;
  min-width: 8rem;
  user-select: none;
  padding: 0.5rem 0.8rem;
  text-align: center;
  transition: all 300ms ease;
}

.facet__heading:hover {
  background-color: var(--color-lois--gray-light);
  border: 1px solid var(--color-lois--gray-blue-alpha-60);
}

.facet__heading.-selected {
  background-color: var(--color-lois--gray-lightest);
  border: 2px solid var(--color-lois--gray-blue-alpha-60);
}

.facet__heading.-selected:hover {
  background-color: var(--color-lois--gray-light);
  border: 2px solid var(--color-lois--gray-blue-alpha-60);
}

.facet__checkbox {
  display: none;
}

.facetselect__options {
  background-color: var(--color-lois--white);
  box-shadow: rgba(67, 90, 111, 0.3) 0 0 1px, rgba(67, 90, 111, 0.47) 0 8px 10px -4px;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
}

.facet__container {
  background-color: var(--color-lois--white);
  box-shadow: rgba(5, 90, 138, 0.14) 0 1px 11px, rgba(43, 134, 185, 0.09) 0 15px 18px;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  font-weight: 400;
}

.facet__searchbox {
  padding: 0.8rem;
  border-radius: 5px;
}

.facet__label {
  align-items: center;
  background-color: var(--color-lois--white-dark);
  display: flex;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem 0.9rem;
}

.facet__label::before {
  content: url("/home/runner/work/contexte/contexte/apps/ui/static_src/ui/components/facet/images/mag.svg");
  opacity: 0.5;
  margin-top: 0.2rem;
}

.facet__searchfield {
  background-color: transparent;
  border: none;
  width: 100%;
}

.facet__searchfield::placeholder {
  color: var(--color-lois--gray-green-light);
  font-size: 1.2rem;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.facet__searchfield:hover::placeholder {
  color: var(--color-lois--gray-green);
  transition: color 300ms ease;
}

.facet__searchfield:focus-within::placeholder {
  opacity: 0;
}

.facet__searchfield:focus-visible {
  outline: none;
}

.facet__values {
  background-color: var(--color-lois--white);
  margin: 0;
  max-height: 40vh;
  overflow-y: auto;
  padding: 0;
}

.facetselect__option {
  cursor: pointer;
  padding: 1rem 1.5rem;
  transition: background-color 150ms ease;
  white-space: nowrap;
  width: 100%;
}

.facetselect__option.-active::before {
  content: url("/home/runner/work/contexte/contexte/apps/ui/static_src/ui/components/facet/./images/tick.svg");
}

.facetselect__option:hover {
  background-color: var(--color-lois--gray-lightest);
}

.facetselect__option.facet__item {
  align-items: center;
  background-color: var(--color-lois--white);
  border-bottom: 1px solid var(--color-lois--gray-lightest);
  display: flex;
  gap: 3rem;
  justify-content: space-between;
  padding: 1.5rem 1.1rem;
  width: 100%;
  transition: background-color 300ms ease;
}

.facetselect__option.facet__item.-selected {
  background-color: var(--color-lois--white-dark);
}

.facetselect__option.facet__item:hover {
  background-color: var(--color-lois--gray-light);
}

.facetselect__option.facet__divider {
  color: var(--color-lois--gray-green-light);
  cursor: default;
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 0.6rem;
}

.facetselect__option.facet__divider:hover {
  background-color: var(--color-lois--white);
}

.facet__name {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.facet__name.-selected {
  align-items: center;
  display: flex;
  font-weight: 600;
  gap: 0.5rem;
}

.facet__name.-selected::before {
  background-color: black;
  content: "";
  height: 1.2rem;
  width: 1.2rem;
  mask-image: url("/home/runner/work/contexte/contexte/apps/ui/static_src/ui/components/facet/./images/tick.svg");
  mask-size: cover;
}

.facet__results {
  color: var(--color-lois--gray-green-light);
  font-size: 1.1rem;
}
