@use "mixins" as *;
.layout-stage {
  display: grid;
  justify-content: space-between;
  max-width: var(--breakpoint-l);
  margin: auto;
  grid-template-columns: 22rem minmax(0, 72.3rem) 22rem;
  padding: var(--spacing-xxl);
  row-gap: var(--spacing-xxl);

  &.-no-padding-block {
    padding-block: 0;
  }

  &.-no-padding-inline {
    padding-inline: 0;
  }

  &.-no-bottom-padding {
    padding-bottom: 0;
  }

  &.-full-width {
    max-width: unset;
  }

  &.-with-banner {
    width: 100%;
    padding-top: var(--spacing-none);
    padding-bottom: var(--spacing-none);
  }

  &.-with-banner.-full-width {
    grid-template-columns: 0 minmax(0, 1fr) 0;
  }

  &.-main-col-only {
    grid-template-columns: 0 minmax(0, 1fr) 0;
  }

  &.-tight {
    grid-template-columns: 1fr minmax(0, 42.8rem) 1fr;
    gap: var(--spacing-xxxl);

    .right-column {
      width: 22rem;
    }
  }

  &.-with-z-index {
    position: relative;
    z-index: 10;
  }
}

.main-column {
  grid-column: 2;
  padding: 0 var(--spacing-xxl);

  &.-no-padding {
    padding: var(--spacing-none);
  }
}

.left-column {
  grid-column: 1;
}

.right-column {
  grid-column: 3;
  position: relative;
}

@media screen and (max-width: $breakpoint-m) {
  .layout-stage {
      grid-template-columns: 17rem minmax(0, 1fr) 17rem;
      padding-block: var(--spacing-xl);
      padding-inline: var(--spacing-xl);

    &.-tight {
      .right-column {
        width: 17rem;
      }
    }
  }

  .main-column {
    padding-inline: var(--spacing-xl);
  }
}

@media screen and (max-width: $breakpoint-s) {
  .layout-stage {
    grid-template-columns: 4rem minmax(0, 1fr) minmax(auto, 17rem);

    &.-with-banner {
      grid-template-columns: 0 minmax(0, 1fr) 0;
    }
  }

  .layout-stage.-no-right-col {
    grid-template-columns: 17rem minmax(0, 1fr);

    & .right-column {
      display: none;
    }
  }

  .layout-stage.-tight {
    grid-template-columns: 0 minmax(0, 1fr) 0;
    gap: var(--spacing-l);

    .right-column {
      display: none;
    }
  }

  .main-column {
    padding-inline: var(--spacing-xl);
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .left-column, .right-column {
    overflow: hidden;
  }

  .layout-stage, .layout-stage.-no-right-col {
    grid-template-columns: 0 minmax(0, 1fr) 0;

    &.-tight {
      padding-inline: 0;
    }
  }

  .left-column.-stack-on-mobile {
    grid-column: 2;
    order: 1;
  }

  .main-column {
    padding-inline: 0;
  }
}

@media print {
  :root  {
    font-size: 55%;
  }

  .layout-stage.-printed {
    display: unset;

    .main-column {
      padding-inline: var(--spacing-56);
    }
  }

  .layout-stage .-screen-only,
  .layout-stage.-screen-only {
    display: none;
  }
}
