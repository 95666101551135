.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  border-radius: var(--radius-min);
  text-decoration: none;
  cursor: pointer;

  svg {
    flex-shrink: 0;
  }

  /* States */

  &,
  &.-primary,
  &.-secondary,
  &.-tertiary {
    &:hover,
    &:focus-visible,
    &.-disabled,
    &[disabled] {
      text-decoration: none;
    }

    @include button-hover-active;
    @include button-level-transparency;

    &.-disabled,
    &[disabled] {
      cursor: default;
      border: 1px solid transparent;
      background: var(--color-global-state-disabled-surface-default);
      color: var(--color-global-state-disabled-text-shape-default);
      text-decoration: none !important;
      cursor: not-allowed;

      &.-inverted {
        border: 1px solid var(--color-border-inverted);
        background: var(--color-global-state-disabled-surface-inverted);
      }
    }
  }


  /* Levels */
  &,
  &.-primary {
    background: var(--color-neutral-surface-dark);
    color: var(--color-neutral-text-shape-inverted);

    &.-contrasted{
      border: 1px solid var(--color-neutral-border-contrast-lighter);
      background: var(--color-neutral-surface-contrast-dark);
      color: var(--color-neutral-text-shape-contrast-default);
    }

    &.-inverted {
      border: 1px solid var(--color-neutral-border-lighter);
      background: var(--color-neutral-surface-light);
      color: var(--color-neutral-text-shape-dark);

      &.-contrasted {
        border: 1px solid var(--color-neutral-border-contrast-darker);
        background: var(--color-neutral-surface-contrast-light);
        color: var(--color-neutral-text-shape-contrast-lighter);
      }
    }
  }

  &.-secondary,
  &.-tertiary {
    color: var(--color-neutral-text-shape-default);

    &.-contrasted{
      color: var(--color-neutral-text-shape-contrast-lighter);
    }

    &.-inverted {
      color: var(--color-neutral-text-shape-inverted);

      &.-contrasted{
        color: var(--color-neutral-text-shape-contrast-default);
      }
    }
  }

  &.-secondary {
    border: 1px solid var(--color-neutral-border-light);

    &.-contrasted {
      border: 1px solid var(--color-neutral-border-contrast-dark);
    }

    &.-inverted {
      border: 1px solid var(--color-neutral-border-dark);

      &.-contrasted {
        border: 1px solid var(--color-neutral-border-contrast-default);
      }
    }
  }

  /* Sizes */

  &.-size-xl {
    @include button-size-xl;
    @include font-interface-xl-semi;
  }

  &,
  &.-size-m {
    @include button-size-m;
    @include font-interface-m-semi;
  }

  &.-size-s {
    @include button-size-s;
    @include font-interface-s-semi;
  }

  &.-size-xxs {
    @include button-size-xxs;
    @include font-interface-xxs-semi;
  }

  /* Rounded */

  &.-rounded {
    border-radius: var(--radius-max);

    &.-square {
      align-items: center;
    }
  }

  /* Skins */

  &.-destructive:not([disabled], .-disabled) {
    @include button-hover-active;
    @include button-level-transparency;

    &,
    &.-primary {
      border: 1px solid transparent;
      background: var(--color-feedback-danger-surface-default);
      color: var(--color-global-white);
    }

    &.-secondary {
      border: 1px solid var(--color-feedback-danger-border-default);
    }

    &.-secondary,
    &.-tertiary {
      color: var(--color-feedback-danger-text-shape-default);
    }
  }

  &.-abo:not([disabled], .-disabled) {
    @include button-hover-active;
    @include button-level-transparency;

    &,
    &.-primary {
      border: 1px solid transparent;
      background: var(--color-brand-abo-surface-default);
      color: var(--color-brand-abo-text-shape-contrast-darker);

      &.-contrasted {
        border: 1px solid var(--color-brand-abo-border-contrast-default);
        background: var(--color-brand-abo-surface-contrast-default);
      }
    }

    &.-secondary {
      border: 1px solid var(--color-brand-abo-border-default);

      &.-contrasted {
        border: 1px solid var(--color-brand-abo-border-contrast-default);
      }
    }

    &.-secondary,
    &.-tertiary {
      color: var(--color-brand-abo-text-shape-darker);

      &.-contrasted {
        color: var(--color-brand-abo-text-shape-contrast-default);
      }
    }
  }

  &.-success {
    &.-primary {
      @include button-hover-success;
    }
  }
}

/* Responsive */

@media (max-width: $breakpoint-l) {
  .button {
    &.-size-s-below-breakpoint-l {
      @include button-size-s;
      @include font-interface-s-semi;
    }
  }
}
@media (max-width: $breakpoint-m) {
  .button {
    &.-size-s-below-breakpoint-m {
      @include button-size-s;
      @include font-interface-s-semi;
    }

    &.-size-xxs-below-breakpoint-m {
      @include button-size-xxs;
      @include font-interface-xxs-semi;
    }
  }
}
