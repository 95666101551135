@use "mixins" as *;
@font-face {
  font-family: 'Tiempos Text';
  src: url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/tiempos-text/./tiempos-text-medium.woff2) format('woff2');
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos Text';
  src: url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/tiempos-text/./tiempos-text-medium-italic.woff2) format('woff2');
  font-weight:  500;
  font-style:   italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos Text';
  src: url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/tiempos-text/./tiempos-text-bold.woff2) format('woff2');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
  font-display: swap;
}
