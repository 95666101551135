.briefitem-summary-container {
  position: absolute;
  width: 100%;
  transition: margin-top 250ms linear;
}

.briefitem-summary {
  background-color: var(--color-neutral-surface-lighter);
  border: 1px solid var(--color-neutral-border-lighter);
  border-radius: var(--radius-popin);
  padding-block: var(--spacing-s);
}

.briefitem-summary__type {
  @include font-sans-xs-semi;
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  padding: var(--spacing-s) var(--spacing-xl);


  &.-red {
    color: var(--color-brand-primary-text-shape-default);

    svg {
      background: var(--color-brand-primary-surface-lighter);
      fill: var(--color-brand-primary-text-shape-default);
    }

  }

  &.-blue {
    color: var(--color-global-monito-text-shape-default);

    svg {
      background-color: var(--color-global-monito-surface-light);
      fill: var(--color-global-monito-text-shape-default);
    }
  }

  &.-purple {
    color: var(--color-global-ia-text-shape-default);

    svg {
      background-color: var(--color-global-ia-surface-light);
      fill: var(--color-global-ia-text-shape-default);
    }
  }

  &.-grey {
    color: var(--color-neutral-text-shape-light);

    svg {
      background-color: transparent;
      fill: var(--color-neutral-text-shape-light);
    }
  }
}

.briefitem-summary__icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  padding: 0.4rem;
  border-radius: 50%;
}

.briefitem-summary__sources {
  display: flex;
  flex-direction: column;
}

.briefitem-summary__source {
  @include font-sans-xs-default;
  text-decoration: none;
  padding: var(--spacing-s) var(--spacing-xl);
  overflow-wrap: break-word;

  &:hover,
  &.-active {
    background: var(--color-global-state-hover-surface-light);
  }

  &:active {
    color: var(--color-global-state-hover-text-shape-dark);
  }
}

.briefitem-summary__geography,
.briefitem-summary__institution,
.briefitem-summary__subtype {
  @include font-condensed-xs-strong;
}

.briefitem-summary__geography,
.briefitem-summary__subtype {
  color: var(--color-brand-primary-text-shape-default);
}

.briefitem-summary__geography {
  margin-right: var(--spacing-xxxs);
}

.briefitem-summary__subtype {
  display: block;
}

.briefitem-summary__transcript {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.briefitem-summary__institution {
  color: var(--color-neutral-text-shape-light);
}

.briefitem-summary__bill {
  @include font-condensed-xs-strong;
  display: block;
  color: var(--color-global-monito-text-shape-default);
  padding: var(--spacing-xxs) var(--spacing-xl) 0;
}

.briefitem-summary__discussion-item {
  display: grid;
  gap: var(--spacing-xs);
}
