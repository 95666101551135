/* LAYOUT */

body:has(.layout-main.-augmented-briefing) {
  overscroll-behavior-y: none;
}

.layout-main.-augmented-briefing {
  --scroll-padding-top: 12rem;
  --briefing-toc-top-margin: 7rem;

  &:has(.briefing.-subscribed):has(.briefing.-unsubscribed) {
    .briefing.-alerts {
      margin-bottom: var(--spacing-72);
    }
  }

  &:not(:has(.briefing.-subscribed)) {
    .briefing.-alerts {
      --scroll-padding-top: 36rem;
      margin-top: var(--spacing-72);
    }
  }

  &:not(:has(.briefing.-unsubscribed)) {
    .briefing.-alerts {
      --scroll-padding-top: 30rem;
    }
  }
}

.layout-stage.-augmented-briefing {
  padding-top: var(--spacing-48);
}

.briefing {
  margin-bottom: 3.2rem;

  &.-alerts {
    border-top: 4px solid var(--color-neutral-border-light);
    padding-top: var(--spacing-xxl);
  }
}

/* HEADER */

.briefing-header__container.-scrolled {
  position: fixed;
  top: var(--spacing-48);
  z-index: 15;
  width: 100%;

  .briefing-header,
  .briefing-nav-sticky-container {
    height: 4.8rem;
  }

  .layout-stage {
    padding-block: var(--spacing-m);
  }

  .main-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .briefing-header__title {
    @include font-produkt-xxl;
    margin-top: 0;
    margin-bottom: 0;
  }

  .briefing-header__subtitle {
    display: none;
  }

  form {
    text-align: right;
  }

  .briefing-nav-sticky-container {
    position: relative;
    z-index: 17;
    background: var(--color-global-state-hover-surface-light);
  }
}

.briefing-header__container:not(.-scrolled) {
  .briefing-header {
    position: relative;
    z-index: 20;
    padding: var(--spacing-48) 0 var(--spacing-xl);
  }

  .briefing-nav-sticky-container {
    position: relative;
    z-index: 10;
    background: var(--color-global-background-light);

    .main-column {
      background: var(--color-global-background-light);
      padding: var(--spacing-xl) 0 var(--spacing-l);
      border-bottom: 1px solid var(--color-neutral-border-light);
      margin-inline: var(--spacing-xxxl);
    }
  }
}

.briefing-header {
  background-color: var(--color-global-background-paywall-default);
  color: var(--color-neutral-text-shape-contrast-default);
}

.briefing-header__title {
  @include font-produkt-4xl;
  margin-top: -0.5rem;
  margin-bottom: var(--spacing-xs);
  cursor: pointer;

  span {
    font-weight: 400;
  }
}

.briefing-header__subtitle {
  @include font-serif-m-default;
  margin-bottom: var(--spacing-l);
}

/* BRIEFING */

.briefing {
  &.-subscribed {
    .briefing__read-previous {
      padding-top: var(--spacing-xxxl);
    }
  }

  &.-unsubscribed {
    .briefing__section:not(:first-of-type) {
      margin-top: calc(-0.9 * var(--scroll-padding-top));
    }

    .briefing__read-previous {
      padding-top: var(--spacing-l);
    }
  }
}

.briefing__edition-name {
  font: normal 900 4.2rem/1 var(--font-the-future);
  transition: all .2s ease;
  margin-bottom: var(--spacing-xxxl);
  /* Add extra margin to align first kicker with TOC */
  padding-bottom: var(--spacing-xxxl);
}

.briefing__edition-name,
.briefing .briefitem-newscard {
  /* Workaround of scroll-padding-top: var(--scroll-padding-top); for all browsers */
  padding-top: var(--scroll-padding-top);
  margin-top: calc(-1 * var(--scroll-padding-top));
}

.briefing__prefix {
  text-transform: none;
  color: var(--color-brand-primary-text-shape-default);
}

.briefing__for {
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.5;
  color: var(--color-neutral-text-shape-light);
}

.briefing__section {
  @include font-sans-xl-strong;
  margin-bottom: var(--spacing-xxxl);
  color: var(--color-neutral-text-shape-light);
  /* Reproduce the behavior of scroll-padding-top: var(--scroll-padding-top); for all browsers */
  padding-top: var(--scroll-padding-top);
  margin-top: calc(-1 * var(--scroll-padding-top));
}

.briefing__intro {
  color: var(--color-neutral-text-shape-light);
  padding-bottom: var(--spacing-xxl);
  margin-bottom: var(--spacing-xxl);
  border-bottom: 1px solid var(--color-neutral-border-light);
}

.briefing__outro {
  @include font-sans-s-default;
  color: var(--color-neutral-text-shape-light);
  margin-bottom: var(--spacing-72);
}

.briefing__intro a:visited,
.briefing__outro a:visited {
  color: var(--color-neutral-text-shape-light)
}

.briefing__intro a:hover,
.briefing__intro a:focus-visible,
.briefing__outro a:hover,
.briefing__outro a:focus-visible {
  color: var(--color-global-state-hover-text-shape-default);
}

.briefing__outro + .briefing__read-previous {
  margin-top: calc(-1 * var(--spacing-72));
  margin-bottom: var(--spacing-72);
}

.briefing__agenda p {
  margin-bottom: var(--spacing-m);
}

/* Datepicker */

.briefing-datepicker {
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xxl);
}

.button.button .briefing-datepicker__icon {
  width: 1.6rem;
  height: 1.6rem;
  margin-top: var(--spacing-xxxxs);
}

.button.briefing-datepicker__label {
  position: relative;

  &:hover {
    background-color: var(--color-global-state-hover-surface-dark) !important;
    border: 1px solid var(--color-global-state-hover-border-dark) !important;
  }
}

.briefing-datepicker__input {
  position: absolute;
  bottom: 0;
  opacity: 0;
  width: 0;
  height: 0;
  border: none;
  padding: 0;
  margin-right: -0.4rem;
}

/* Briefing TOC */

.briefing-toc {
  height: calc(100vh - var(--spacing-48) - var(--briefing-toc-top-margin));
  overflow-y: auto;
  position: sticky;
  top: calc(var(--spacing-48) + var(--briefing-toc-top-margin));
  transition: opacity 0.2s, tranform 0.2s;
}

.briefing-toc__section {
  @include font-condensed-s-strong;
  color: var(--color-neutral-text-shape-light);
  margin-bottom: var(--spacing-m);
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-neutral-border-light);

  &:not(:first-of-type) {
    margin-top: var(--spacing-xxl);
  }
}

.briefing-toc__brief a.briefing-toc__link {
  display: none;
}

.briefing-toc__brief.-current a.briefing-toc__link {
  display: flex;
}

a.briefing-toc__brief-name {
  @include font-edition-l;
  display: flex;
  align-items: center;
  margin: var(--spacing-xxs) 0 var(--spacing-s);
  text-decoration: none;
  border-radius: var(--radius-min);
  /* Fake text-edge: cap; */
  padding-bottom: var(--spacing-xxxs);

  &:hover,
  &:active {
    color: var(--color-global-state-hover-text-shape-default);
  }

  &.-alerts {
    @include font-sans-l-strong;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: var(--spacing-l) 0 var(--spacing-s);

    svg {
      width: 1.4rem;
      height: 1.4rem;
      margin-top: var(--spacing-xxxxs);
    }
  }
}

a.briefing-toc__link {
  @include font-sans-s-default;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-block: var(--spacing-xxs);
  gap: var(--spacing-xs);
  border-radius: var(--radius-min);

  &:last-of-type {
    margin-bottom: var(--spacing-l);
  }

  &:hover,
  &.-current {
    color: var(--color-global-state-hover-text-shape-default);
  }

  &:active {
    color: var(--color-global-state-hover-text-shape-dark);
  }
}

.briefing .briefitem-newscard {
  margin-bottom: var(--spacing-xxxl);

  &:not(:has(.briefitem-newscard__body)) {
    margin-bottom: var(--spacing-xl);
  }
}

/* Briefing Nav [S-Mobile] */

.briefing-nav-sticky-container {
  display: none;
}

.briefing-nav__logo {
  @include font-edition-xl;
  display: inline-flex;
  padding-top: 0;
  gap: var(--spacing-s);
  align-items: center;
  user-select: none;

  &:hover {
    cursor: pointer;

    .briefing-nav__button {
      border: 1px solid var(--color-global-state-hover-border-default);
      background: var(--color-global-state-hover-surface-default);
      color: var(--color-global-white);
    }
  }
}

.briefing-nav__logo-title {
  @include font-produkt-xxl;
  color: var(--color-brand-primary-text-shape-default);
}

.briefing-nav__button {
  margin-top: var(--spacing-xxs);
}

.briefing-datepicker.-mobile {
  margin: 0 0 0 -0.5rem;
}

.briefing-nav__dropdown {
  display: inline-block;
}

.briefing-nav__section {
  @include font-condensed-s-strong;
  color: var(--color-neutral-text-shape-light);
  padding: var(--spacing-m) var(--spacing-xs) var(--spacing-xxs);

  &.-others {
    border-top: 1px solid var(--color-neutral-border-light);
    padding-top: var(--spacing-xl);
    margin-top: var(--spacing-xs);
  }
}

.briefing-nav__menu {
  width: max-content;
  border-radius: var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-light);
  background: var(--color-global-background-lighter);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  padding: var(--spacing-xs);
  flex-direction: column;
  align-items: stretch;
  top: 100%;
  margin-top: var(--spacing-l);
  right: -7.2rem;
}

.briefing-nav__menu-item {
  min-height: 4rem;
  padding: var(--spacing-s) var(--spacing-xl) var(--spacing-s) var(--spacing-xs);
  border-radius: var(--radius-min);
  @include font-sans-s-default;
  text-decoration: none;

  &:hover {
    color: var(--color-global-state-hover-text-shape-inverted);
    background: var(--color-global-state-hover-surface-default, #2159D3);
  }
}

/* Responsive */

@media screen and (max-width: $breakpoint-l) {
  .layout-main.-augmented-briefing {
    &:not(:has(.briefing.-unsubscribed)) {
      .briefing.-alerts {
        --scroll-padding-top: 22rem;
      }
    }

    &:not(:has(.briefing.-subscribed)) {
      .briefing.-alerts {
        --scroll-padding-top: 31.5rem;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-m) {

  /* LAYOUT */

  .layout-main.-augmented-briefing {
    --briefing-toc-top-margin: 7rem;
    --scroll-padding-top: 12.5rem;
  }

  .layout-stage.-augmented-briefing {
    padding-top: var(--spacing-xl);

    .main-column {
      padding-top: var(--spacing-s);
    }

    &:has(.briefing.-subscribed) {
      .briefing.-alerts {
        --scroll-padding-top: 12rem;
      }
    }
  }

  /* BRIEFING */

  .briefing__edition-name {
    font-size: 2.4rem;
  }

  .briefing__edition-name {
    padding-bottom: 0;
  }

  /* BRIEFING TOC */

  .briefing-datepicker__time-year {
    display: none;
  }

  .briefing-toc__brief.-current a.briefing-toc__brief-name {
    margin-bottom: var(--spacing-xxs);
  }

  a.briefing-toc__brief-name {
    @include font-edition-s;
    min-height: 2.4rem;
    margin: var(--spacing-xxs) 0 var(--spacing-xs);
    padding-bottom: 0;

    &.-alerts {
      @include font-sans-s-strong;
      padding: var(--spacing-s) 0 var(--spacing-xxs);

      svg {
        margin-top: 0;
      }
    }
  }

  a.briefing-toc__link {
    @include font-sans-xs-default;
    min-height: unset;

    &:last-of-type {
      margin-bottom: var(--spacing-l);
    }
  }

  .briefing-toc__section {
    margin-bottom: 0;

    &:not(:first-of-type) {
      margin-top: var(--spacing-xl);
    }
  }
}

@media screen and (max-width: $breakpoint-s) {

  /* LAYOUT */

  .layout-main.-augmented-briefing {
    --scroll-padding-top: 9rem;
    --briefing-toc-top-margin: 6rem;

    &:not(:has(.briefing.-subscribed)) {
      .briefing.-alerts {
        --scroll-padding-top: 16.5rem;
      }
    }

    .briefing.-alerts {
      --scroll-padding-top: 11rem;
    }

    .briefing:has(.briefing__intro) {
      --scroll-padding-top: 11rem;
    }

    &:not(:has(.briefing.-unsubscribed)) {
      .briefing.-alerts {
        margin-bottom: var(--spacing-64);
      }
    }

    .briefing:first-of-type .briefing__edition-name {
      margin-top: calc(-1 * 16.4rem);
    }

    .briefing__edition-name {
      margin-top: calc(-1 * 9rem);
    }
  }

  /* BRIEFING HEADER */

  .briefing-header__container:not(.-scrolled) {
    .briefing-header {
      padding-top: var(--spacing-xxxl);
      padding-bottom: var(--spacing-s);
    }
  }

  .briefing-header__container.-scrolled {
    .main-column {
      padding-inline: var(--spacing-xl) !important;
    }

    .briefing-header__title {
      @include font-produkt-xl;
    }

    .briefing-nav-sticky-container .main-column {
      margin-inline: 0;
    }
  }

  /* BRIEFING TOC */

  .briefing-toc {
    display: none;
  }

  .briefing-datepicker__time-year {
    display: unset;
  }

  /* BRIEFING NAV */

  .briefing-nav-sticky-container {
    display: block;
  }

  .briefing-nav-container {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    row-gap: var(--spacing-l);
  }

  .briefing-nav__dropdown-wrapper {
    flex-grow: 1;
  }

  .briefing-datepicker {
    flex-shrink: 0;
  }

  /* BRIEFING */


  .briefing__intro {
    margin-top: var(--spacing-xxl);
  }

  .briefing__section {
    margin-top: calc(var(--spacing-xxl) + var(--scroll-padding-top) * -1);
  }
}

@media screen and (max-width: $breakpoint-mobile) {

    /* LAYOUT */

    .layout-main.-augmented-briefing {
      --scroll-padding-top: 8rem;

      &:not(:has(.briefing.-subscribed)) {
        .briefing.-alerts {
          --scroll-padding-top: 12rem;
        }
      }
    }

    /* HEADER */

  .briefing-header__container:not(.-scrolled) {
    .briefing-header {
      padding-top: var(--spacing-s);
      padding-bottom: 0;
    }

    .briefing-nav-sticky-container .main-column {
      margin-inline: var(--spacing-xl);
    }

    .briefing-header__title {
      @include font-produkt-3xl;
    }

    .briefing-header__subtitle {
      @include font-serif-s-medium;
    }
  }

  .briefing-header__container.-scrolled {
    .main-column {
      padding-inline: 0 !important;
    }

    .briefing-datepicker__label svg {
      display: none;
    }
  }
}

@media print {
  .briefing {
    --scroll-padding-top: 0;
  }

  .briefing-header__container.-scrolled {
    display: none;
  }

  .briefing-header__container:not(.-scrolled) .briefing-header {
    background-color: var(--color-global-background-light);
    padding-top: var(--spacing-l);
    padding-bottom: 0;
  }

  .briefing-header,
  .briefing-datepicker__label {
    color: var(--color-neutral-text-shape-default) !important;
  }

  .briefing__edition-name {
    margin-top: var(--scroll-padding-top);
    margin-bottom: 0;
  }

  .briefing-toc {
    display: none;
  }

  .briefing__read-previous {
    display: none;
  }
}
