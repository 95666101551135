.alerts__container:not(.-small) {
  margin-top: var(--spacing-xxxl);

  .alerts__empty-content {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  .alerts__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.alerts__container.-small {
  border-radius: var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-lighter);
}

.alerts__empty-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--spacing-xxxl);
  padding-bottom: var(--spacing-xl);
}

.alerts__empty-message-wrap {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  max-width: 55rem;
}

.alerts__empty-title {
  @include font-sans-m-semi;
}

.alerts__empty-message {
  @include font-sans-m-default;
}

.alerts__empty-image {
  width: 18rem;
  height: 18rem;
}

@media screen and (max-width: $breakpoint-s) {
  .alerts__empty-title {
    @include font-sans-s-semi;
  }

  .alerts__empty-message {
    @include font-sans-s-default;
  }

  .alerts__empty-image {
    width: 9rem;
    height: 9rem;
  }
}
