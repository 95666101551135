.briefitem-newscard {
  width: 100%;
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-s);

  &.-compact {
    .briefitem-newscard__geography {
      @include font-condensed-m-strong;
    }

    .briefitem-newscard__title {
      padding-left: .25rem;
      padding-right: .3rem;
      @include font-sans-m-strong;
    }

    .briefitem-newscard__body {
      @include font-sans-m-default;
    }

    .briefitem-newscard__similar {
      @include font-sans-s-default;
    }

    .newscard__tag-list {
      margin-top: var(--spacing-none);
    }
  }

  .editorial-edition, .editorial-typology {
    color: var(--color-neutral-text-shape-light);
  }
}

.briefitem-toolbar {
  display: flex;
  height: 4rem;
  margin-top: var(--spacing-xxs);
  padding: var(--spacing-s);
  border-radius: var(--radius-popin);
  border: 1px solid var(--color-neutral-border-lighter);
  background: var(--color-global-background-light);
  margin-right: 0;
  user-select: none;
}

.briefitem-toolbar__button-container {
  display: flex;
  align-items: center;
}

.briefitem-toolbar__button {
  @include font-interface-xxs-semi;
  display: flex;
  width: fit-content;
  padding: var(--spacing-xxs) var(--spacing-xs);
  outline: none;
  border-radius: var(--radius-rounded);
  border: 1px solid transparent;
  cursor: pointer;
  color: var(--color-neutral-text-shape-light);

  &:hover {
    border: 1px solid var(--color-global-state-hover-border-default);
    background-color: var(--color-global-state-hover-surface-default);
    color: var(--color-global-state-hover-text-shape-inverted);
  }

  &:active {
    border: 1px solid var(--color-global-state-hover-border-dark);
    background-color: var(--color-global-state-hover-surface-dark);
    color: var(--color-global-state-hover-text-shape-inverted);
  }
}

.briefitem-toolbar__item {
  display: flex;
  gap: var(--spacing-xxs);
  text-decoration: none;
}

.briefitem-toolbar__icon {
  width: 1.4rem;
  height: 1.4rem;
  align-self: center;
}

.briefitem-toolbar__divider {
  height: 2rem;
  border-left: 1px solid var(--color-neutral-border-light);
  margin-inline: var(--spacing-xxs);
}

.briefitem-newscard__wrap-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-l);
  align-self: stretch;
}

.briefitem-newscard__geography {
  color: var(--color-brand-primary-text-shape-default);
  @include font-condensed-xxl-strong;
}

.briefitem-newscard__title {
  color: var(--color-neutral-text-shape-default);
  @include font-sans-xxl-strong;

  text-decoration: none;

  &:hover {
    color: var(--color-global-state-hover-text-shape-default);
  }
}

.briefitem-newscard__body {
  color: var(--color-neutral-text-shape-default);
  @include font-sans-l-default;

  ul {
    padding-left: 2.2rem;
    list-style: none;
  }

  /* Round grey dots as custom markers for lists */
  li::before {
    content: "";
    display: block;
    position: absolute;
    background-color: var(--color-neutral-text-shape-light);
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    margin-left: -2.2rem;
    margin-top: 1rem;
  }

  a {
    text-decoration-color: var(--color-global-state-hover-border-default);

    &:hover {
      color: var(--color-global-state-hover-text-shape-default);
    }

    &:active {
      color: var(--color-global-state-hover-text-shape-dark);
    }

    &.-strong {
      background: var(--color-global-state-hover-surface-light);

      &:hover,
      &.-active {
        background: var(--color-global-state-hover-surface-default);
        color: var(--color-global-state-hover-text-shape-inverted);
      }

      &:active {
        background: var(--color-global-state-hover-surface-dark);
        text-decoration-color: var(--color-global-state-hover-surface-dark);
        color: var(--color-global-state-hover-text-shape-inverted);
      }
    }
  }
}

.briefitem-newscard__similar {
  align-self: stretch;
  color: var(--color-neutral-text-shape-light);
  @include font-sans-s-default;
}

/* Augmented Briefing theme */

.briefitem-newscard {
  .briefitem-newscard__wrap-text {
    gap: var(--spacing-s);
  }


  .newscard__tag-list {
    padding: 0;
    border-top: none;
  }
}

@media (min-width: $breakpoint-m) {
  // Large font size above breakpoint M
  .briefitem-newscard.-large {
    .briefitem-newscard__geography {
      @include font-condensed-l-strong;
    }

    .briefitem-newscard__title {
      @include font-sans-l-strong;
    }

    .briefitem-newscard__body {
      @include font-sans-l-default;
    }
  }
}
