@mixin button-size-s {
  min-height: 4rem;
  min-width: 3.2rem;
  padding: calc(var(--spacing-xs) - 0.1rem) calc(var(--spacing-m) + var(--spacing-xs));
  gap: calc(var(--spacing-xs) * 2);

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0 calc(0rem - var(--spacing-xs));
  }

  &.-square {
    width: 4rem;
    height: 4rem;
    min-width: unset;
    min-height: unset;
    padding: 0;
  }
}

@mixin button-size-xxs {
  min-height: 2.4rem;
  padding: calc(var(--spacing-xxs) - 0.1rem) calc(var(--spacing-xs) + var(--spacing-xxs));
  gap: calc(var(--spacing-xxs) * 2);

  svg {
    width: 1.4rem;
    height: 1.4rem;
    margin: 0 calc(0rem - var(--spacing-xxs));
  }

  &.-square {
    width: 2.4rem;
    min-height: unset;
    height: 2.4rem;
    padding: 0;
  }
}

@mixin button-size-m {
  min-height: 4.8rem;
  padding: calc(var(--spacing-l) - 0.1rem) calc(var(--spacing-m) + var(--spacing-s));
  gap: calc(var(--spacing-s) * 2);

  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin: 0 calc(0rem - var(--spacing-s));
  }

  &.-square {
    width: 4.8rem;
    min-height: unset;
    height: 4.8rem;
    padding: 0;
  }
}

@mixin button-size-xl {
  min-height: 5.6rem;
  padding: var(--spacing-l) calc(var(--spacing-l)*2);
  gap: calc(var(--spacing-l) * 2);

  svg {
    width: 3.2rem;
    height: 3.2rem;
    margin: 0 calc(0rem - var(--spacing-l));
  }

  &.-square {
    width: 5.6rem;
    min-height: unset;
    height: 5.6rem;
    padding: 0;
  }
}

@mixin button-hover-active {
  &:not([disabled], .-disabled) {

    &,
    &.-contrasted,
    &.-inverted,
    &.-inverted.-contrasted {

      &:hover,
      &:focus-visible {
        border: 1px solid transparent;
        background: var(--color-global-state-hover-surface-default);
        color: var(--color-global-white);
      }

      &:active {
        border: 1px solid transparent;
        background: var(--color-global-state-hover-surface-dark);
        color: var(--color-global-white);
      }
    }
  }
}

@mixin button-hover-success {
  svg {
    fill: var(--color-neutral-surface-contrast-default);
  }

  &.-inverted {
    &:hover {
      border: 1px solid var(--color-feedback-success-border-default);
      background: var(--color-feedback-success-surface-default);

      svg {
        fill: var(--color-neutral-surface-contrast-light);
      }
    }
  }
}

@mixin button-level-transparency {
  &,
  &.-primary {
    border: 1px solid transparent;
  }

  &.-secondary,
  &.-tertiary {
    background: transparent;

    &.-contrasted{
      background: transparent;
      color: var(--color-neutral-text-shape-contrast-default);
    }

    &.-inverted {
      background: transparent;

      &.-contrasted{
        background: transparent;
      }
    }
  }
  &.-tertiary,
  &.-contrast,
  &.-inverted,
  &.-inverted.-contrasted{
    border: 1px solid transparent;
  }
}
