@use "mixins" as *;
:root {
  --spacing-48: 4.8rem; /* spacing-3000 */
  --spacing-56: 5.6rem; /* spacing-3500 */
  --spacing-64: 6.4rem; /* spacing-4000 */
  --spacing-72: 7.2rem; /* spacing-4500 */
  --spacing-80: 8.0rem; /* spacing-5000 */
  --spacing-88: 8.8rem; /* spacing-5500 */
  --spacing-96: 9.6rem; /* spacing-6000 */
  --spacing-l: 1.2rem; /* spacing-750 */
  --spacing-m: 1.0rem; /* spacing-625 */
  --spacing-none: 0rem; /* raw */
  --spacing-s: 0.8rem; /* spacing-500 */
  --spacing-xl: 1.6rem; /* spacing-1000 */
  --spacing-xs: 0.6rem; /* spacing-375 */
  --spacing-xxl: 2.4rem; /* spacing-1500 */
  --spacing-xxs: 0.4rem; /* spacing-250 */
  --spacing-xxxl: 3.2rem; /* spacing-2000 */
  --spacing-xxxs: 0.2rem; /* spacing-125 */
  --spacing-xxxxl: 4.0rem; /* spacing-2500 */
  --spacing-xxxxs: 0.1rem; /* spacing-62 */
}
