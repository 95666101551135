.select__button {
  @include font-interface-m-semi;

  min-height: 4.8rem;
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  justify-content: space-between;
  width: 100%;
  color: var(--color-neutral-text-shape-light);
  border: 1px solid var(--color-neutral-border-light);
  background-color: var(--color-neutral-surface-lighter);
  border-radius: var(--radius-rounded);
  padding: var(--spacing-xs) var(--spacing-s) var(--spacing-xs) var(--spacing-xl);

  .select.-small & {
    @include font-interface-s-semi;
    min-height: 4rem;
    padding: var(--spacing-xs) var(--spacing-s) var(--spacing-xs) var(--spacing-m);
  }

  .select.-contrasted & {
    color: var(--color-neutral-text-shape-contrast-default);
    border-color: var(--color-neutral-border-contrast-dark);
    background: var(--color-neutral-surface-contrast-dark);
  }

  .select:hover &,
  .select:focus-visible &,
  .select.-open & {
    background-color: var(--color-neutral-surface-light);
    border-color: var(--color-global-state-hover-border-default);
    outline: none;
  }

  .select.-contrasted:hover &,
  .select.-contrasted:focus-visible &,
  .select.-contrasted.-open & {
    background-color: var(--color-neutral-surface-contrast-dark);
    border-color: var(--color-global-state-hover-border-default);
    outline: none;
  }

  .select.-error & {
    border-color: var(--color-feedback-danger-text-shape-default);
  }

  .select.-inverted.-error & {
    border-color: var(--color-feedback-danger-border-contrast-dark);
  }
}

.select__chevron {
  width: 2.4rem;
  height: 2.4rem;
  margin-top: var(--spacing-xxs);
  transform: rotate(0deg);

  .select.-open & {
    transform: rotate(180deg);
    margin-top: 0;
  }

  .select.-small & {
    width: 2rem;
    height: 2rem;
    margin-top: 0;
  }
}

.select__options {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  color: var(--color-neutral-text-shape-default);
  border: 1px solid var(--color-neutral-border-light);
  background-color: var(--color-global-background-lighter);
  border-radius: var(--radius-rounded);
  box-shadow: 0px 0px var(--spacing-xs) 0px rgba(7, 0, 46, 0.15);
  padding: var(--spacing-xs);
  top: calc(100% - 1px);
  margin-top: var(--spacing-s);

  .select.-small & {
    margin-top: var(--spacing-xs);
  }

  .select.-contrasted & {
    color: var(--color-neutral-text-shape-contrast-default);
    border: 1px solid var(--color-neutral-border-contrast-default);
    background: var(--color-neutral-surface-contrast-dark);
  }
}

.select__option {
  @include font-sans-m-default;
  cursor: pointer;
  padding: var(--spacing-m) var(--spacing-l);
  white-space: nowrap;
  width: 100%;

  &.-active {
    color: var(--color-global-state-hover-text-shape-default);
    background-color: var(--color-global-state-hover-surface-light);
  }

  .select.-contrasted &.-active {
    color: var(--color-global-state-hover-text-shape-inverted);
    background-color: var(--color-global-state-hover-surface-dark);
  }

  &:hover,
  &:focus-visible,
  .select.-contrasted &:hover,
  .select.-contrasted &:focus-visible {
    color: var(--color-global-white);
    background-color: var(--color-global-state-hover-surface-default);
    outline: none;
  }
}

.select__input {
  display: none;
}

@media (max-width: $breakpoint-m) {
  .select__option {
    @include font-sans-s-default;
    padding-block: var(--spacing-s);
  }

  .select__button {
    @include font-interface-s-default;
  }
}
