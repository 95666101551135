/* Spinner inspired from https://nzbin.github.io/three-dots/ */
.loading-spinner {
  position: relative;

  &,
  &::before,
  &::after {
    display: inline-block;
    width: .8rem;
    height: .8rem;
    border-radius: var(--radius-max);
    background-color: var(--color-neutral-surface-darker);
    animation: dot-flashing 1.4s infinite linear 0s both;
  }

  &.-contrasted {
    &,
    &::before,
    &::after {
      background-color: var(--color-neutral-surface-lighter);
    }
  }

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -1.2rem;
    animation-delay: 0s;
  }

  & {
    animation-delay: 0.2s;
  }

  &::after {
    left: 1.2rem;
    animation-delay: 0.4s;
  }
}

@keyframes dot-flashing {
  0% {
    opacity: 0.5;
  }
  30% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0.5;
  }
}

.htmx-request {
  .loading-spinner {
    display: inline-block !important;
  }
}
