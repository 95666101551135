.article-container {
  --article-section-margin: var(--spacing-72);

  .banner {
    margin-bottom: var(--spacing-xxxl);
  }
}

.article-header {
  padding: var(--spacing-xxl) 0;
  background: var(--color-neutral-surface-contrast-darker);
  margin-bottom: var(--spacing-s);
}

.article-header__title {
  color: var(--color-neutral-text-shape-contrast-default);
  @include font-serif-3xl-strong;
}

.article-header__teaser {
  color: var(--color-neutral-text-shape-contrast-default);
  @include font-serif-xl-medium;
  margin: var(--spacing-l) 0;
}

.article-header__data-lines {
  margin: var(--spacing-xxl) 0 0;
}

.article-header__data-line {
  color: var(--color-neutral-text-shape-contrast-default);
  @include font-sans-s-default;
  padding: var(--spacing-m) 0;
  border-top: 1px solid var(--color-neutral-border-dark);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);

  svg {
    color: var(--color-neutral-text-shape-contrast-light);
    flex-shrink: 0;
  }
}

.article-header__tags {
  display: flex;
  align-items: flex-end;
  gap: 0.8rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--spacing-m) 0;
}

.article-separator {
  margin: var(--spacing-xxl) 0;

  &:first-of-type {
    margin-top: 0;
  }
}

.article-illustration-stage {
  padding-bottom: 0;
}

.article-illustration-caption {
  color: var(--color-neutral-text-shape-light);
  @include font-sans-xs-default;
  margin-top: var(--spacing-s);
}

.article-separator {
  background-color: var(--color-neutral-border-light);
  height: 0.1rem;
  border: 0;
}

/* Article body */

.article-body {
  color: var(--color-neutral-text-shape-default);
  @include font-sans-l-default;
  margin-bottom: var(--spacing-xxxl);
}

.article-body a {
  text-decoration-color: var(--color-global-state-hover-border-default);
}

.article-body a:hover,
.article-body a:focus-visible {
  color: var(--color-global-state-hover-text-shape-default);
}

.article-body > p,
.article-body li {
  margin-bottom: var(--spacing-xxl);
}

.article-body li::marker {
  color: var(--color-neutral-text-shape-light);
}

.article-body h2 {
  @include font-sans-xl-strong;
  margin: var(--spacing-xxl) 0;
  padding-top: var(--spacing-s);
  scroll-margin: 7rem;

  a,
  a:hover {
    text-decoration: none;
    color: inherit;
  }
}

.article-body blockquote {
  border-left: 4px solid var(--color-brand-primary-border-default);
  margin: var(--spacing-xxl) 0;
  padding: var(--spacing-l) var(--spacing-xl) var(--spacing-xl) var(--spacing-xxl);
  color: var(--color-neutral-text-shape-light);
}

.article-body figcaption {
  color: var(--color-neutral-text-shape-light);
  @include font-sans-xs-default;
  margin-block: var(--spacing-s);
}

.article-body p.read-also {
  @include font-sans-s-default;
  border-top: 1px solid var(--color-neutral-border-light);
  border-bottom: 1px solid var(--color-neutral-border-light);
  padding: var(--spacing-xl) var(--spacing-48) var(--spacing-xxl) 0;
}

.article-body p.question {
  font-size: 1.05em;
  font-weight: 600;
  margin-top: 6rem;
}

.article-body__framed {
  padding: var(--spacing-xl) var(--spacing-xxl) var(--spacing-xxl) var(--spacing-xxl);
  border-radius: var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-light);
  background: var(--color-neutral-surface-lighter);

  .article-body__framed-title {
    padding: 0;
    margin: 0 0 var(--spacing-s) 0;
  }

  p {
    margin-top: var(--spacing-s);
  }
}

.article-body__pdf-viewer,
.article-body__pdf-viewer-holder {
  height: 108rem;
}

.article-body__pdf-viewer-holder {
  margin: var(--spacing-xxl) auto;
  position: relative;
  border: 1px solid var(--color-neutral-border-default);
  border-radius: var(--radius-rounded);

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    filter: blur(3px);
  }

  a {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
  }
}

@supports (-webkit-touch-callout: none) {
  // Hide embed on mobile safari to show the viewer holder behind it
  .article-body__pdf-viewer {
    display: none;
  }
}

.article-body__pdf-viewer {
  position: absolute;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  width: calc(100vw - 2 * var(--spacing-xxl));
  max-width: calc(var(--breakpoint-l) - 2 * var(--spacing-xxl));
  border: 1px solid var(--color-neutral-border-default);
  border-radius: var(--radius-rounded);
  z-index: 1000;
}

/* Data viz */

.article-header-content,
.article-raw-text,
.article-text,
.article-authors-twitter-container,
.aside-column {
  /* 👆 Those selectors are required by dataviz */
  --comment: 'Keep me!';
}

.article-header-content,
.article-raw-text {
  width: calc(100vw - 2 * var(--spacing-xxl));
  max-width: var(--breakpoint-l);
  margin: auto;
}

.article-raw-text>div {
  margin-top: var(--spacing-xxl);
}

/* Article footer */

.article-authors {
  @include font-sans-s-default;
  line-height: 1.3;
  display: flex;
  padding: var(--spacing-xs) 0 0 0;
  align-items: center;
  align-content: center;
  gap: var(--spacing-s);
  min-height: 4.4rem;

  a {
    @include font-sans-s-semi;
  }

  .article-illustration-stage + .layout-stage &,
  .article-raw-text + .layout-stage & {
    border-top: 1px solid var(--color-neutral-border-light);
  }
}

.article-authors__avatars {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.article-authors__avatar {
  width: 3.2rem;
  height: 3.2rem;
  object-fit: cover;
  border-radius: var(--radius-max);
  border: 1px solid var(--color-global-background-light);
  background-color: var(--color-global-background-light);

  &:not(:first-child) {
    margin-left: calc(-1 * var(--spacing-xs));
  }
}

.article-ressources {
  margin-bottom: var(--article-section-margin);

  ul {
    padding-left: var(--spacing-xl);
  }

  li:not(:first-child) {
    margin-top: var(--spacing-xs);
  }
}

.article-related-tags {
  margin-bottom: var(--article-section-margin);
}

.article-related-tags__tags {
  display: flex;
  margin-top: var(--spacing-xl);
  align-items: center;
  gap: var(--spacing-s);
  flex-wrap: wrap;
  /* Truncate tags when needed */
  max-width: 85vw;
}

/* Responsive */

@media screen and (max-width: $breakpoint-m) {
  .article-container {
    --article-section-margin: var(--spacing-48);
  }

  .article-header__title {
    @include font-serif-xxl-strong;
  }

  .article-header__teaser {
    @include font-serif-l-medium;
  }

  .article-body blockquote {
    padding: var(--spacing-s) var(--spacing-xl) var(--spacing-m) var(--spacing-xxl);
  }

  .article-body__framed {
    padding: var(--spacing-xl) var(--spacing-xl) var(--spacing-xxl) var(--spacing-xl);
  }

  .article-body__pdf-viewer,
  .article-body__pdf-viewer-holder {
    height: 79.3rem;
  }
}

@media screen and (max-width: $breakpoint-s) {
  .article-header__tags {
    display: none;
  }

  .article-container {
    --article-section-margin: var(--spacing-xxxl);
  }

  .article-authors__avatars {
    display: none;
  }

  .article-body {
    @include font-sans-m-default;
  }

  .article-body p.read-also {
    padding: var(--spacing-xl) var(--spacing-xxxl) var(--spacing-xxl) var(--spacing-none);
  }

  .article-body blockquote {
    padding: var(--spacing-xxs) var(--spacing-xl) var(--spacing-xs) var(--spacing-xl);
  }

  .article-body__pdf-viewer,
  .article-body__pdf-viewer-holder {
    height: 61.8rem;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .article-container {
    --article-section-margin: var(--spacing-xxl);
  }

  .article-authors {
    line-height: 1.3;
    padding: var(--spacing-xs) 0;
  }

  .article-body__pdf-viewer,
  .article-body__pdf-viewer-holder {
    height: 44.7rem;
  }
}

@media print {
  .article.layout-stage {
    display: grid;
    justify-content: left;
    grid-template-columns: auto;
    padding: 0;
    margin-inline: 0;
  }

  .article-header {
    padding: var(--spacing-xxl) var(--spacing-xxxl) 0;
    margin-bottom: 0;
    background-color: var(--color-global-background-light);
  }

  .article-header__title,
  .article-header__teaser,
  .article-header__data-line {
    color: var(--color-neutral-text-shape-default);
  }

  .article-header__data-line {
    border-top: none;
    padding: 0;
  }

  .article-header__data-line.-printed,
  .article-header__data-line svg,
  .article-header__tags,
  .article-illustration-stage,
  .dz-js-wrapper,
  .dz-sticky,
  .article-related-tags,
  .article-related,
  .article-ressources {
    display: none;
  }

  .article-authors {
    .article-illustration-stage + .layout-stage &,
    .article-raw-text + .layout-stage & {
      border-top: none;
      padding: 0 var(--spacing-xxl);
    }
  }
}
