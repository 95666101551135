// Header
header.layout-stage {
  margin-bottom: var(--spacing-s);
}

.subscription-confirmation__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xl);
  max-width: 500px;
  margin: auto;

  h1 {
    @include font-serif-xl-strong;
  }

  img {
    max-width: 12rem;
  }
}

.subscription-confirmation__header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-s);
  color: var(--color-neutral-text-shape-default);
  text-align: center;
}

// Content
.subscription-confirmation__content {
  background: var(--color-neutral-surface-lighter);
  border-radius: var(--radius-popin) var(--radius-popin) var(--radius-rounded) var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-light);
  box-shadow: 0px 0px var(--spacing-xs) 0px rgba(0, 0, 0, 0.15);
  margin: auto;
  margin-bottom: var(--spacing-96);
  max-width: 675px;
}

.subscription-confirmation__content-header {
  text-align: center;
  padding: var(--spacing-xl) var(--spacing-xxl);
  background: var(--color-global-state-hover-surface-default);
  color: var(--color-neutral-text-shape-contrast-default);
  border-radius: var(--radius-popin) var(--radius-popin) 0px 0px;
  @include font-serif-xl-strong;
}

.subscription-confirmation__content-section {
  padding-bottom: var(--spacing-xxl);
}

.subscription-confirmation__content-banner-header {
  padding: var(--spacing-xl) var(--spacing-xxl);
  background: var(--color-feedback-success-surface-contrast-lighter);
  color: var(--color-feedback-success-text-shape-contrast-default);
  @include font-sans-m-semi;
  display: flex;
  align-items: start;
  gap: var(--spacing-s);

  svg {
    fill: currentColor;
    width: 2rem;
    height: 2rem;
    margin-top: 2px; // Fix svg alignement
  }

  p::first-letter {
    text-transform: uppercase;
  }
}

.subscription-confirmation__content-info {
  margin-top: var(--spacing-xl);
  padding: 0 var(--spacing-xxl);
  color: var(--color-neutral-text-shape-light);
  @include font-sans-m-default;
}

.subscription-confirmation__content-edition-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
  padding: 0px var(--spacing-xxl);
  margin-top: var(--spacing-xxl);
}

.subscription-confirmation__content-edition-card {
  border-radius: var(--radius-popin);
  padding: var(--spacing-xl);
  flex: 1 0 0;
  text-decoration: none;
  display: block;

  .subscription-confirmation__content-edition-card-title {
    display: flex;
    align-items: end;
    gap: var(--spacing-s);
    @include font-edition-xl;
    margin-bottom: var(--spacing-m);

    svg {
      fill: currentColor;
      width: 1.8rem;
      height: 1.8rem;
      margin: unset;
      margin-bottom: -2px; // Fix svg alignement
    }
  }

  .subscription-confirmation__content-edition-card-tags {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-s);
    color: var(--color-neutral-text-shape-light);

    p {
      flex: 1;
      @include font-sans-xs-default;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    svg {
      fill: currentColor;
      width: 1.6rem;
      height: 1.6rem;
      margin: unset;
    }
  }
  &:hover {
    .subscription-confirmation__content-edition-card-tags {
      color: var(--color-global-white);
    }
  }
}

.subscription-confirmation__content-feature-card-container {
  padding: 0px var(--spacing-xxl);
  margin-top: var(--spacing-xxl);

  .subscription-confirmation__content-feature-card {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--spacing-l) var(--spacing-xl);

    &:not(:last-child) {
      margin-bottom: var(--spacing-xxxxl);
    }

    .subscription-confirmation__content-feature-card-image {
      grid-row: 1 / 4;

      img {
        max-width: 16rem;
        max-height: 16rem;
      }
    }

    h3 {
      display: flex;
      align-items: center;
      gap: var(--spacing-xs);
      color: var(--color-neutral-text-shape-default);
      @include font-sans-m-strong;

      span {
        flex: 1;
      }

      svg {
        color: var(--color-global-state-hover-text-shape-default);
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    p {
      color: var(--color-neutral-text-shape-light);
      @include font-sans-m-default;
    }
  }
}

@media screen and (max-width: $breakpoint-m) {
  .subscription-confirmation .layout-stage {
    grid-template-columns: 0 minmax(0, 1fr) 0;
  }

  .subscription-confirmation__content-feature-card-container {
    .subscription-confirmation__content-feature-card {
      .subscription-confirmation__content-feature-card-image img {
        max-width: 12rem;
        max-height: 12rem;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .subscription-confirmation__content-edition-card-container {
    grid-template-columns: 1fr;
  }

  .subscription-confirmation__content-feature-card-container {
    .subscription-confirmation__content-feature-card {
      .subscription-confirmation__content-feature-card-header {
        grid-column: 1 / 3;
      }
      .subscription-confirmation__content-feature-card-image {
        grid-column: 2;
        grid-row: 2;
        img {
          max-width: 9rem;
          max-height: 9rem;
        }
      }
      .subscription-confirmation__content-feature-card-content {
        grid-column: 1;
        grid-row: 2;
      }
      .subscription-confirmation__content-feature-card-cta {
        grid-column: 1 / 3;
      }
    }
  }
}
