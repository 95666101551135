
/**
 * This file was generated automaticaly using the `extract-figma-fonts.py` script.
 * Please do not modify it by hand.
 *
 * Generated on 06/02/2025 at 13:40:23 by marie-noellefromage
 */

/* Base mixins */

@mixin font-style-underline {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2.5px;
  text-decoration-skip-ink: none;
}

/* Fonts mixins */

@mixin font-serif-5xl-strong {
  font-family: var(--font-tiempos-text);
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 1.16;
}

@mixin font-serif-5xl-medium {
  font-family: var(--font-tiempos-text);
  font-size: 4.8rem;
  font-weight: 500;
  line-height: 1.16;
}

@mixin font-serif-4xl-strong {
  font-family: var(--font-tiempos-text);
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
}

@mixin font-serif-4xl-medium {
  font-family: var(--font-tiempos-text);
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.2;
}

@mixin font-serif-3xl-strong {
  font-family: var(--font-tiempos-text);
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.25;
}

@mixin font-serif-3xl-medium {
  font-family: var(--font-tiempos-text);
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.25;
}

@mixin font-serif-xxl-strong {
  font-family: var(--font-tiempos-text);
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.33;
}

@mixin font-serif-xxl-medium {
  font-family: var(--font-tiempos-text);
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.33;
}

@mixin font-serif-xl-strong {
  font-family: var(--font-tiempos-text);
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.4;
}

@mixin font-serif-xl-medium {
  font-family: var(--font-tiempos-text);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.4;
}

@mixin font-serif-xl-default {
  font-family: var(--font-tiempos-text);
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.4;
}

@mixin font-serif-l-medium {
  font-family: var(--font-tiempos-text);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.45;
}

@mixin font-serif-l-default {
  font-family: var(--font-tiempos-text);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.45;
}

@mixin font-serif-m-medium {
  font-family: var(--font-tiempos-text);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
}

@mixin font-serif-m-default {
  font-family: var(--font-tiempos-text);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
}

@mixin font-serif-s-strong {
  font-family: var(--font-tiempos-text);
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.57;
}

@mixin font-serif-s-medium {
  font-family: var(--font-tiempos-text);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.57;
}

@mixin font-sans-4xl-strong {
  font-family: var(--font-graphik);
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
}

@mixin font-sans-4xl-semi {
  font-family: var(--font-graphik);
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.2;
}

@mixin font-sans-3xl-strong {
  font-family: var(--font-graphik);
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.25;
}

@mixin font-sans-xxl-strong {
  font-family: var(--font-graphik);
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.33;
}

@mixin font-sans-xxl-semi {
  font-family: var(--font-graphik);
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.33;
}

@mixin font-sans-xxl-default {
  font-family: var(--font-graphik);
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.33;
}

@mixin font-sans-xxl-strong-link {
  font-family: var(--font-graphik);
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.33;
  @include font-style-underline;
}

@mixin font-sans-xl-strong {
  font-family: var(--font-graphik);
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.4;
}

@mixin font-sans-xl-default {
  font-family: var(--font-graphik);
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.4;
}

@mixin font-sans-xl-strong-link {
  font-family: var(--font-graphik);
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.4;
}

@mixin font-sans-l-strong {
  font-family: var(--font-graphik);
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.45;
}

@mixin font-sans-l-semi {
  font-family: var(--font-graphik);
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.45;
}

@mixin font-sans-l-default {
  font-family: var(--font-graphik);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.45;
}

@mixin font-sans-l-italic {
  font-family: var(--font-graphik);
  font-size: 1.8rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.45;
}

@mixin font-sans-l-link {
  font-family: var(--font-graphik);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.45;
  @include font-style-underline;
}

@mixin font-sans-l-strong-link {
  font-family: var(--font-graphik);
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.45;
  @include font-style-underline;
}

@mixin font-sans-m-strong {
  font-family: var(--font-graphik);
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.5;
}

@mixin font-sans-m-semi {
  font-family: var(--font-graphik);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
}

@mixin font-sans-m-default {
  font-family: var(--font-graphik);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
}

@mixin font-sans-m-italic {
  font-family: var(--font-graphik);
  font-size: 1.6rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.5;
}

@mixin font-sans-m-link {
  font-family: var(--font-graphik);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  @include font-style-underline;
}

@mixin font-sans-m-semi-link {
  font-family: var(--font-graphik);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
  @include font-style-underline;
}

@mixin font-sans-m-strong-link {
  font-family: var(--font-graphik);
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.5;
  @include font-style-underline;
}

@mixin font-sans-s-strong {
  font-family: var(--font-graphik);
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.57;
}

@mixin font-sans-s-semi {
  font-family: var(--font-graphik);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.57;
}

@mixin font-sans-s-default {
  font-family: var(--font-graphik);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.57;
}

@mixin font-sans-s-italic {
  font-family: var(--font-graphik);
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.57;
}

@mixin font-sans-s-link {
  font-family: var(--font-graphik);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.57;
  @include font-style-underline;
}

@mixin font-sans-s-semi-link {
  font-family: var(--font-graphik);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.57;
  @include font-style-underline;
}

@mixin font-sans-xs-semi {
  font-family: var(--font-graphik);
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.66;
}

@mixin font-sans-xs-default {
  font-family: var(--font-graphik);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.66;
}

@mixin font-sans-xs-italic {
  font-family: var(--font-graphik);
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.66;
}

@mixin font-sans-xs-link {
  font-family: var(--font-graphik);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.66;
  @include font-style-underline;
}

@mixin font-sans-xs-semi-link {
  font-family: var(--font-graphik);
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.66;
}

@mixin font-sans-xxs-default {
  font-family: var(--font-graphik);
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.72;
}

@mixin font-condensed-xxl-strong {
  font-family: var(--font-graphik-cond);
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.33;
  text-transform: uppercase;
}

@mixin font-condensed-xl-strong {
  font-family: var(--font-graphik-cond);
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
}

@mixin font-condensed-l-strong {
  font-family: var(--font-graphik-cond);
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.45;
  text-transform: uppercase;
}

@mixin font-condensed-l-default {
  font-family: var(--font-graphik-cond);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.45;
  text-transform: uppercase;
}

@mixin font-condensed-m-strong {
  font-family: var(--font-graphik-cond);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
}

@mixin font-condensed-m-default {
  font-family: var(--font-graphik-cond);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
}

@mixin font-condensed-s-strong {
  font-family: var(--font-graphik-cond);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.57;
  text-transform: uppercase;
}

@mixin font-condensed-s-default {
  font-family: var(--font-graphik-cond);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.57;
  text-transform: uppercase;
}

@mixin font-condensed-xs-strong {
  font-family: var(--font-graphik-cond);
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.66;
  text-transform: uppercase;
}

@mixin font-condensed-xs-default {
  font-family: var(--font-graphik-cond);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.66;
  text-transform: uppercase;
}

@mixin font-produkt-5xl {
  font-family: var(--font-produkt);
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 1.06;
  text-transform: lowercase;
}

@mixin font-produkt-4xl {
  font-family: var(--font-produkt);
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.08;
  text-transform: lowercase;
}

@mixin font-produkt-3xl {
  font-family: var(--font-produkt);
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.11;
  text-transform: lowercase;
}

@mixin font-produkt-xxl {
  font-family: var(--font-produkt);
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.13;
  text-transform: lowercase;
}

@mixin font-produkt-xl {
  font-family: var(--font-produkt);
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.16;
  letter-spacing: 0.02rem;
  text-transform: lowercase;
}

@mixin font-produkt-l {
  font-family: var(--font-produkt);
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.45;
  letter-spacing: 0.018rem;
  text-transform: lowercase;
}

@mixin font-interface-xl-semi {
  font-family: var(--font-graphik);
  font-size: 2rem;
  font-weight: 600;
  line-height: 1;
}

@mixin font-interface-xl-default {
  font-family: var(--font-graphik);
  font-size: 2rem;
  font-weight: 400;
  line-height: 1;
}

@mixin font-interface-m-semi {
  font-family: var(--font-graphik);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1;
}

@mixin font-interface-m-default {
  font-family: var(--font-graphik);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1;
}

@mixin font-interface-s-semi {
  font-family: var(--font-graphik);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1;
}

@mixin font-interface-s-default {
  font-family: var(--font-graphik);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1;
}

@mixin font-interface-xxs-semi {
  font-family: var(--font-graphik);
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
}

@mixin font-interface-xxs-default {
  font-family: var(--font-graphik);
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1;
}

@mixin font-edition-5xl {
  font-family: var(--font-the-future);
  font-size: 4.8rem;
  font-weight: 900;
  line-height: 1.06;
  text-transform: lowercase;
}

@mixin font-edition-4xl {
  font-family: var(--font-the-future);
  font-size: 4rem;
  font-weight: 900;
  line-height: 1.08;
  text-transform: lowercase;
}

@mixin font-edition-3xl {
  font-family: var(--font-the-future);
  font-size: 2.8rem;
  font-weight: 900;
  line-height: 1.11;
  text-transform: lowercase;
}

@mixin font-edition-xxl {
  font-family: var(--font-the-future);
  font-size: 2.4rem;
  font-weight: 900;
  line-height: 1.13;
  text-transform: lowercase;
}

@mixin font-edition-xl {
  font-family: var(--font-the-future);
  font-size: 2rem;
  font-weight: 900;
  line-height: 1.16;
  letter-spacing: 0.02rem;
  text-transform: lowercase;
}

@mixin font-edition-l {
  font-family: var(--font-the-future);
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 1.45;
  letter-spacing: 0.018rem;
  text-transform: lowercase;
}

@mixin font-edition-s {
  font-family: var(--font-the-future);
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 1.57;
  letter-spacing: 0.014rem;
  text-transform: lowercase;
}
