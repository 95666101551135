.chip {
  @include font-interface-s-semi;

  display: inline-block;
  justify-content: flex-start;
  align-content: center;
  user-select: none;
  text-decoration: none !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;

  height: 3.2rem;
  border-radius: var(--radius-max);
  padding: var(--spacing-xs) calc(var(--spacing-s) + var(--spacing-xs));

  > svg {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0;
    vertical-align: middle;
    background: transparent;
    border: 1px solid transparent;
  }

  /* States */

  &.-disabled,
  &[disabled] {
    cursor: not-allowed;
    border: 1px solid var(--color-global-state-disabled-border-default);
    background: var(--color-global-state-disabled-surface-default);
    color: var(--color-global-state-disabled-text-shape-default);
    text-decoration: none !important;
  }

}

.-tag { // This composant is called tag-chip in Design
  /* Levels */

  &:not(disabled):not(.-disabled):not(:hover){

    &,
    &.-primary {
      border: 1px solid var(--color-neutral-border-darker);
      background: var(--color-neutral-surface-dark);
      color: var(--color-neutral-text-shape-inverted);

      &.-contrasted{
        border: 1px solid var(--color-neutral-border-contrast-default);
        background: var(--color-neutral-surface-contrast-light);
        color: var(--color-neutral-text-shape-contrast-lighter);
      }
    }

    &.-secondary {
      border: 1px solid var(--color-neutral-border-light);
      background: transparent;
      color: var(--color-neutral-text-shape-default);

      &.-contrasted{
        border: 1px solid var(--color-neutral-border-contrast-default);
        background: transparent;
        color: var(--color-neutral-text-shape-contrast-default);
      }
    }
  }

  &:not(.-disabled):not([disabled]) {
    &:hover{
      color: var(--color-global-white);
      border: 1px solid var(--color-global-state-hover-surface-default);
      background: var(--color-global-state-hover-surface-default);
      cursor: pointer;
    }

    &:active{
      color: var(--color-global-white);
    }

    &:active {
      border: 1px solid var(--color-global-state-hover-surface-dark);
      background: var(--color-global-state-hover-surface-dark);
    }
  }
}

.-keyword { // This composant is called keyword-chip in Design

  border: 1px solid var(--color-feedback-success-border-lighter);
  background: var(--color-feedback-success-surface-lighter);

  &,
  & > svg {
    border-radius: var(--radius-max);
    color: var(--color-feedback-success-text-shape-dark);
  }

  & > svg {
    vertical-align: -0.4rem;
    margin-left: var(--spacing-xxs);
  }

  &.-disabled,
  &[disabled] {
    & > svg {
      display: none;
    }
  }

  &:has(svg) {
    padding-right: var(--spacing-s);
  }

  &:not(.-disabled):not([disabled]) {
    &> svg:hover  {
      color: var(--color-global-white);
      border: 1px solid var(--color-global-state-hover-surface-default);
      background: var(--color-global-state-hover-surface-default);
      cursor: pointer;
    }

    & > svg:active {
      color: var(--color-global-white);
      border: 1px solid var(--color-global-state-hover-surface-dark);
      background: var(--color-global-state-hover-surface-dark);
    }

  }
}
