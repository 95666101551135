.layout-main.-search {
  // Best effort to set the height of the search page to 100vh if empty
  --layout-footer-height: 21.7rem;
  min-height: calc(100vh - var(--layout-footer-height));
}

.search-form {
  color: var(--color-neutral-text-shape-contrast-default);
  background-color: var(--color-neutral-surface-contrast-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xxl) var(--spacing-xxxl);
}

.search-form__search-zone {
  flex-grow: 1;
  max-width: 123.2rem;
}

.search-form__count, .search-form__loading {
  @include font-sans-m-strong;
  margin-bottom: var(--spacing-xl);
}

.search-form__loading {
  display: none;
}

.htmx-request .search-form__loading {
  display: block;
}

.htmx-request .search-form__count {
  display: none;
}

.search-form__field-line {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: var(--spacing-xxl);
}

.search-form__search-box {
  display: flex;
  align-items: center;
  background-color: var(--color-neutral-surface-lighter);
  border: 1px solid var(--color-neutral-text-shape-light);
  border-radius: var(--radius-max);
  padding: var(--spacing-xs) var(--spacing-s);
  border-radius: var(--radius-max);
  cursor: text;

  &:focus-within {
    outline: .1rem solid var(--color-global-state-hover-border-default);
  }
}

.search-form__search-box svg {
  width: 1.6rem;
  height: 1.6rem;
  color: var(--color-neutral-text-shape-light);
  margin-right: var(--spacing-xs);
}

.search-form__search-field {
  border: none;
  width: 100%;

  @include font-sans-m-default;
  color: var(--color-neutral-text-shape-default);
  background-color: var(--color-neutral-surface-lighter);
  padding: var(--spacing-xs) var(--spacing-s);

  &:focus {
    outline: none;
  }
}

.search-form__facets-zone {
  background-color: var(--color-neutral-surface-default);
  padding: var(--spacing-xl) var(--spacing-xxxl);
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-form__active-facets {
  @include font-sans-m-default;

  display: flex;
  gap: var(--spacing-xl);
  flex-grow: 1;
  max-width: 123.2rem;
}

.search-form__active-facet {
  display: flex;
  align-items: baseline;
  gap: var(--spacing-xs);
}

.search-form__facet-title {
  @include font-sans-m-semi;
  color: var(--color-neutral-text-shape-light);
}

.search-form__active-filters {
  color: var(--color-neutral-text-shape-default);
}

.search-form__active-filter {
  cursor: pointer;

  &:hover, &:focus {
    color: var(--color-global-state-hover-text-shape-default);
    text-decoration: line-through;
  }
}

.search-form__filters {
  margin-top: var(--spacing-xxl);
  border: none;
  padding: 0;
}

.search-form__facets {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-xl);
}

.search-form__options-label {
  color: var(--color-neutral-text-shape-light);
  font-weight: 700;
}

.search-form__options {
  display: flex;
  align-items: center;
  gap: var(--spacing-xl);
}

.search-results-stage {
  padding-top: var(--spacing-xl);
}

.suggested-tag {
  margin-top: var(--spacing-s);
  padding-bottom: var(--spacing-xl);
  border-bottom: 1px solid var(--color-neutral-border-light);
  margin-bottom: var(--spacing-xxl);
}

.suggested-tag__title {
  @include font-sans-xxl-strong;

  color: var(--color-neutral-text-shape-default);
  margin: 0;
  margin-bottom: var(--spacing-s);
}

.suggested-tag__kind {
  @include font-produkt-xxl;

  color: var(--color-brand-primary-text-shape-default);
}

.suggested-tag__summary {
  @include font-sans-m-default;

  color: var(--color-neutral-text-shape-light);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-bottom: var(--spacing-l);
}

.suggested-tag__actions {
  text-decoration: none;

  &:hover {
    color: var(--color-global-state-hover-text-shape-default);
  }
}

.search__no-results {
  margin-top: var(--spacing-xl);
}

/* Responsive */

@media (max-width: $breakpoint-m) {
  .layout-main.-search {
    --layout-footer-height: 19.4rem;
  }

  .search-form__search-field {
    @include font-interface-m-semi;
    padding-top: 0;
    padding-bottom: 0;
  }

  .search-form__field-line {
    gap: var(--spacing-xl);
  }
}

@media (max-width: $breakpoint-s) {
  .layout-main.-search {
    --layout-footer-height: 16.6rem;
  }

  .search-form__filters {
    margin-top: var(--spacing-xl);
  }

  .search-form__active-facets {
    @include font-sans-s-default;
  }

  .search-form__options .select__button-label {
    max-width: 6.8rem;
    height: 1.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .search-form__facets-zone {
    padding: var(--spacing-s) var(--spacing-xxxl);
  }
}

@media (max-width: $breakpoint-mobile) {
  .layout-main.-search {
    --layout-footer-height: 26.2rem;
  }

  .search-form {
    padding: var(--spacing-xl);
  }

  .search-form__filters {
    margin-top: var(--spacing-l);
  }

  .search-form__facets {
    gap: var(--spacing-s);
  }

  .search-form__facets-zone {
    padding: var(--spacing-s) var(--spacing-xl);
  }
}
