.lois-emptystate {
  border: 1px solid var(--color-lois--gray-green-alpha-10);
  border-radius: 8px;
  color: var(--color-lois--gray-green-light);
  padding: 3rem;
}

.lois-emptystate:hover {
  border-color: var(--color-lois--gray-green-alpha-30);
}

.lois-emptystate__title {
  font-size: 1.7rem;
  font-weight: 700;
  margin: 0.3rem 0 1.3rem;
}

.lois-emptystate__body {
  font-family: var(--font-graphik);
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5em;
}

.lois-emptystate__link {
  font-family: var(--font-inter);
  font-weight: 700;
  margin-top: 1.5rem;
  transition: all 300ms ease;
}

.lois-emptystate__link::before {
  background-color: var(--color-lois--gray-green-light);
}

.lois-emptystate__link:hover {
  color: var(--color-lois--dark);
}

.lois-emptystate__link:hover::before {
  background-color: var(--color-lois--dark);
}
