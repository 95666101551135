.showmore {
  cursor: pointer;
}

.showmore__container {
  opacity: 0;
  overflow-y: hidden;
  max-height: 0;
  transition: max-height 0.2s 0s cubic-bezier(0, 1, 0, 1), opacity 0s 0.1s ease;
  cursor: pointer;
}

.showmore__container.-no-toggle {
  cursor: auto;
}

.showmore__container.-open {
  opacity: 1;
  overflow-y: visible;
  transition: max-height 0.2s 0s ease-in-out, opacity 0.2s 0.2s ease;
  max-height: initial;
}

.showmore__button {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin: 0;
  width: 100%;
}

.showmore__indicator {
  align-items: center;
  display: flex;
  gap: 0.7rem;
}

.showmore__indicator::after {
  background-color: black;
  content: "";
  display: inline-block;
  height: 1em;
  margin-top: 0.1rem;
  margin-right: 0.5rem;
  mask-image: url("/home/runner/work/contexte/contexte/apps/ui/static_src/ui/components/showmore/images/chevron.svg");
  mask-size: cover;
  transition: transform 0.2s;
  width: 1em;
}

.showmore__indicator.-open::after {
  transform: rotate(0.5turn);
  margin-top: 0;
}
