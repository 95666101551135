.featurepreviews {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-xxl);
  gap: var(--spacing-xxl)
}

.featurepreview {
  padding: var(--spacing-xxl) var(--spacing-xxxl);
  background-color: var(--color-global-background-light);
  border: .1rem solid var(--color-neutral-border-light);
  border-radius: var(--radius-rounded);

  &.-active {
    background: var(--color-global-background-lighter);
    border-color: var(--color-neutral-border-default);

    .featurepreview__description {
      color: var(--color-neutral-text-shape-default);
    }
  }

  &:not(.-active) {
    ul,
    .featurepreview__name,
    .featurepreview__description {
      color: var(--color-global-state-disabled-text-shape-default);
    }
  }

  .featurepreview__summary {
    display: flex;
  }

  .featurepreview__name {
    @include font-sans-l-strong;
  }

  ul,
  .featurepreview__description {
    @include font-sans-m-default;
    margin-top: var(--spacing-s);
  }

  ul {
    list-style-position: inside;
    padding: 0;
    margin-bottom: 0;

    li:not(:last-child) {
      margin-bottom: var(--spacing-xxs);
    }
  }
}

.featurepreview__toggle {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: .2rem;
  margin-left: 1.5rem;
}

.featurepreview__details {
  height: auto;
  color: var(--color-feedback-success-text-shape-dark);
  border-top: .1rem solid var(--color-neutral-border-light);
  margin-top: 1.6rem;
  padding-top: 1.6rem;
  overflow-y: visible;
  visibility: visible;
}

.featurepreview__details:not(.-open) {
  height: 0;
  overflow-y: hidden;
  visibility: hidden;
  margin-top: 0;
  padding-top: 0;
}

.featurepreview__details-title {
  font-weight: 700;
  margin-bottom: .4rem;
}

.featurepreview__details-body {
  font-family: var(--font-graphik);
  font-size: 1.4rem;
}

.featurepreview__button {
  margin-top: var(--spacing-xl);
}

@media (max-width: $breakpoint-m) {
  .featurepreview {
    padding: var(--spacing-xl);
  }

  .featurepreview__name {
    font-size: 1.6rem;
  }

  .featurepreview__description {
    font-size: 1.4rem;
  }
}
