.newscard {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xxs);
  padding: var(--spacing-none);

  --left-part-max-width: 100%;
  --right-part-min-width: 0rem;
  --reverse-left-part-min-width: 0rem;
  --horizontal-chapo-line-clamp: 7;

  &.-top-article {
    .newscard__article-title {
      @include font-sans-3xl-strong;

      &.-compact {
        @include font-sans-m-strong;
      }
    }
  }

  &.-horizontal {
    flex-direction: row;
    gap: var(--spacing-xxxl);

    &.-top-article {
      flex-direction: row-reverse;

      .newscard__article-col {
        padding-bottom: var(--spacing-none);
      }

      .newscard__wrap-text {
        min-width: var(--reverse-left-part-min-width);
      }
    }

    .newscard__thumbnail {
      max-width: var(--left-part-max-width);
      flex: 2 2 0;
    }

    .newscard__wrap-text {
      flex: 1 2 0;
      min-width: var(--right-part-min-width);
    }

    .newscard__article-header {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: var(--horizontal-chapo-line-clamp);
    }

    &:not(:has(.newscard__thumbnail)) {
      .newscard__article-content {
        flex-direction: row;
        justify-content: flex-start;
        gap: var(--spacing-xxxl);
      }

      .newscard__article-title {
        max-width: var(--left-part-max-width);
        flex: 2 2 0;
      }

      .newscard__article-header {
        flex: 1 2 0;
        min-width: var(--right-part-min-width);
      }

      &.-top-article {
        .newscard__article-header {
          @include font-serif-xl-medium;
        }
      }
    }
  }

  &.-contrasted {
    .newscard__article-title {
      color: var(--color-neutral-text-shape-contrast-default);
    }

    .newscard__article-header {
      color: var(--color-neutral-text-shape-contrast-default);
    }
  }

  &:has(.newscard__thumbnail:hover) {
    .newscard__article-title,
    .newscard__article-header {
      color: var(--color-global-state-hover-text-shape-default);
    }
  }
}

.newscard__thumbnail {
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-end;
  gap: var(--spacing-xxs);
}


/* Wrap-text */

.newscard__wrap-text {
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-s);
  align-self: stretch;
}

.newscard__article-col {
  display: flex;
  padding-bottom: var(--spacing-s);
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--spacing-xxs);
  align-self: stretch;
}


/* Wrap-text article */

.newscard__article-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--spacing-s);
  align-self: stretch;

  text-decoration: none;

  &:hover {
    .newscard__article-title,
    .newscard__article-header {
      color: var(--color-global-state-hover-text-shape-default);
    }
  }
}

.newscard__article-title {
  width: 100%;
  display: block;
  text-wrap: auto;

  align-self: stretch;
  color: var(--color-neutral-text-shape-default);
  @include font-sans-xxl-strong;

  text-decoration: none;

  &.-compact {
    @include font-sans-m-strong;
  }
}

.newscard__article-header {
  align-self: stretch;
  color: var(--color-neutral-text-shape-default);
  @include font-serif-m-medium;

  &.-compact {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }
}
