.layout-footer {
  position: relative;
  flex-direction: column;
  background: var(--color-neutral-surface-contrast-darker);
  z-index: 1;
}

.layout-footer__svg {
  width: 12.6rem;
  height: 3.2rem;
  margin-left: var(--spacing-xxxs);
  padding: 0 0 var(--spacing-xs) var(--spacing-xxs);
  color: var(--color-neutral-text-shape-contrast-default);

  &.-bottom-middle {
    display: none;
  }

  &.-bottom-all-right {
    display: none;
    position: absolute;
    right: 0;
    bottom: var(--spacing-xl);
  }
}

.layout-footer__brand {
  flex-direction: column;
  align-items: flex-start;

  &.-left-column {
    display: flex;
    margin-left: calc(0rem - var(--spacing-xxl));
  }

  &.-main-column {
    display: none;
  }
}

.layout-footer__brand-text-container {
  display: flex;
  padding-left: var(--spacing-xl);
}

.layout-footer__brand-text {
  @include font-sans-m-default;
  color: var(--color-neutral-text-shape-contrast-light);
}

.layout-footer__links {
  display: flex;
  width: 100%;
  gap: var(--spacing-xxl);
  flex-direction: row;
}

.layout-footer__links-col {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

a.layout-footer__links-text {
  @include font-sans-m-default;
  color: var(--color-neutral-text-shape-contrast-default);
}

.layout-footer .select.-contrasted {
  .select__button{
    @include font-sans-m-default;

    min-height: 1.5rem;
    background-color: var(--color-neutral-surface-contrast-darker);
    border: 0;
    padding: var(--spacing-none) var(--spacing-s) var(--spacing-none) var(--spacing-none);
    align-items: center;
    gap: var(--spacing-xs);
    align-self: stretch;
    justify-content: flex-start;
  }

  .select.-contrasted:hover &,
  .select.-contrasted:focus-visible &,
  .select.-contrasted.-open & {
    background-color: var(--color-neutral-surface-contrast-darker);
    outline: none;
  }

  .select__left-icon {
    width: 20px;
    height: 20px;
  }

  .select__button-label {
    color: var(--color-neutral-text-shape-contrast-default);
    @include font-sans-m-default
  }

  .select__options {
    padding: var(--spacing-s);
  }

  .select__option {
    padding: var(--spacing-s) var(--spacing-xl) var(--spacing-s) var(--spacing-s)
  }
}

.layout-footer__bottom {
  border-top: 1px solid var(--color-neutral-border-contrast-light);
  background: var(--color-neutral-surface-contrast-darker);
}

.layout-stage.-footer-bottom {
  padding: var(--spacing-xl) 0;
}

.main-column.-layout-footer__bottom {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.main-column.-layout-footer__top {
  padding-inline: 0;
  padding-top: var(--spacing-l);

  &:has(.dropdown.-open) {
    margin-bottom: var(--spacing-xxxl);
  }
}

.layout-footer__global-bottom {
  display: flex;
  max-width: var(--breakpoint-l);
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.layout-footer__rights-text {
  align-items: flex-start;
  @include font-sans-m-default;
  color: var(--color-neutral-text-shape-contrast-light);
  white-space: nowrap;
  padding-left: var(--spacing-xl);

  &.-left-column {
    display: flex;
  }

  &.-main-column {
    display: none;
  }
}

@media (max-width: $breakpoint-m) {

  .layout-footer__brand {
    &.-left-column {
      display: none;
    }

    &.-main-column {
      display: flex;
    }
  }

  .main-column.-layout-footer__top {
    padding-top: 0;
  }

  .layout-footer__svg {
    width: 11.8rem;
    height: 2.2rem;
    margin-left: -1rem;
    padding: 0 0 var(--spacing-xxxs) 0;
  }

  .layout-footer__brand-text-container {
    display: none;
  }

  .layout-footer__links {
    display: flex;
    gap: var(--spacing-xl);
    margin-top: var(--spacing-s);
  }

  .layout-footer__links-col {
    gap: 0;
  }

  a.layout-footer__links-text {
    @include font-sans-s-default;
  }

  .layout-footer .select.-contrasted {
    .select__button-label {
      @include font-sans-s-default;
    }
  }

  .layout-footer__rights-text {
    @include font-sans-s-default;

    &.-left-column {
      display: none;
    }

    &.-main-column {
      display: flex;
      padding-left: 0;
    }
  }

  .layout-footer__svg {
    width: 8.6rem;
    height: 1.6rem;
    padding: 0;
    margin-right: var(--spacing-xl);

    &.-bottom-middle {
      display: none;
    }

    &.-bottom-all-right {
      display: flex;
    }
  }
}

@media (max-width: $breakpoint-s) {

  .main-column {
    &.-layout-footer__bottom {
      display: flex;
    }
  }

  .main-column.-layout-footer__top {
    padding-top: 0;
  }

  .layout-footer__brand {
    &.-left-column {
      display: none;
    }

    &.-main-column {
      display: none;
    }
  }

  .layout-footer__brand-text-container {
    display: none;
  }

  .layout-footer__links {
    display: flex;
    max-width: 72.3rem;
    padding: 0;
    gap: var(--spacing-xl);
    justify-content: flex-start;
  }

  .layout-footer__links-col {
    width: 100%;
    gap: 0;
  }

  a.layout-footer__links-text {
    @include font-sans-s-default;
  }

  .right-column.-layout-footer__bottom {
    display: flex;
    justify-content: flex-end;
  }

  .layout-footer__rights-text {
    @include font-sans-s-default;

    &.-main-column {
      padding: 0;
    }
  }

  .layout-footer__svg {
    width: 8.6rem;
    height: 1.6rem;
    padding: 0;
    margin-right: var(--spacing-xl);

    &.-bottom-middle {
      display: none;
    }

    &.-bottom-right {
      display: flex;
    }
  }
}

/* Form footer */

.form__footer {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-xxl);
  gap: var(--spacing-m);

  a {
    @include font-interface-s-default;
    text-decoration: none;

    &:hover {
      color: var(--color-global-state-hover-text-shape-default);
    }
  }
}

@media (max-width: $breakpoint-mobile) {

  .layout-stage.-footer-bottom {
    padding: var(--spacing-s) 0;
  }

  .main-column.-layout-footer__bottom {
    padding: 0 var(--spacing-xl);
  }

  .layout-footer__brand {
    display: none;
  }

  .layout-footer__brand-text-container {
    display: none;
  }

  .main-column.-layout-footer__top {
    padding-top: 0;

    &:has(.dropdown.-open) {
      margin-bottom: var(--spacing-48);
    }
  }

  .layout-footer__links {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    gap: var(--spacing-xs);
  }

  .layout-footer__links-col {
    width: 100%;
    gap: var(--spacing-xs);
  }

  a.layout-footer__links-text {
    @include font-sans-s-default;
  }

  .layout-footer .select.-contrasted {
    .select__button{
      @include font-sans-s-default;
    }

    .select__button-label {
      @include font-sans-s-default;
    }
  }

  .main-column.-layout-footer__bottom {
    display: flex;
  }

  .layout-footer__svg.-bottom-all-right {
    bottom: var(--spacing-m);
  }

  .layout-footer__rights-text {
    @include font-sans-s-default;
  }

  .form__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media print {
  .layout-footer {
    display: none;
  }

  .layout-footer__bottom {
    border-top: 1px solid transparent;
  }
}
