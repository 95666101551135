.chipfield {
  @include font-interface-s-semi;

  display: flex;
  align-items: center;
  user-select: none;
  text-decoration: none !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  gap: var(--spacing-xxs);

  height: 3.2rem;
  border-radius: var(--radius-max);
  padding: var(--spacing-xs) var(--spacing-m);
  border: 1px solid var(--color-neutral-border-light);
  cursor: pointer;

  > svg {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0;
    vertical-align: middle;
    background: transparent;
    border: 1px solid transparent;
  }

  &.-disabled {
    cursor: not-allowed;
    border: 1px solid var(--color-global-state-disabled-border-default);
    color: var(--color-global-state-disabled-text-shape-default);
    background: var(--color-global-state-disabled-surface-default);
  }

  &:not(.-disabled):hover {
    border: 1px solid var(--color-global-state-hover-border-default);
    background: var(--color-global-state-hover-surface-default);
    color: var(--color-global-state-hover-text-shape-inverted);
  }

  &:not(.-disabled):has(> input[type=checkbox]:checked) {
    border: 1px solid var(--color-global-state-hover-border-dark);
    background: var(--color-global-state-hover-surface-dark);
    color: var(--color-global-state-hover-text-shape-inverted);
  }
}

.chipfield__icon {
  width: 1.8rem;
  height: 1.8rem;
}
