.sticky-backlink {
  width: 100%;
  position: sticky;
  top: var(--spacing-48);
  padding: var(--spacing-xl);
  background: var(--color-global-background-light);
  cursor: pointer;
  z-index: 4;

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }

  a {
    @include font-sans-s-default;
    display: flex;
    align-items: center;
    gap: var(--spacing-xxs);
    text-decoration-line: none;
    color: var(--color-neutral-text-shape-default);
  }

  a:hover,
  a:active {
    text-decoration-line: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2.5px;
    text-decoration-skip-ink: none;
  }

  a:hover,
  a:active {
    color: var(--color-global-state-hover-text-shape-default);
  }
}

@media (max-width: $breakpoint-s) {
  .sticky-backlink {
    a {
      @include font-sans-xs-default;
    }

    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .sticky-backlink {
    padding-block: var(--spacing-m);
    margin-block: 0;
  }
}