.confirm {
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.confirm__veil::before {
  background-color: rgba(67, 90, 111, 0.7);
  content: "";
  display: block;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 20;
}

.confirm:not(.-open) {
  display: none;
}

.confirm__dialog {
  display: flex;
  flex-direction: column;
  background-color: var(--color-neutral-surface-lighter);
  margin: auto;
  z-index: 100;

  /* Sizes */

  &.-size-l,
  &.-size-l-m {
    @include font-sans-m-default;
    padding: var(--spacing-xxxl);
    gap: var(--spacing-xxl);
  }

  &.-size-m {
    @include font-sans-s-default;
    padding: var(--spacing-xxl);
    gap: var(--spacing-xl);
  }
}

.confirm__buttons {
  align-items: center;
  display: flex;
  gap: var(--spacing-xl);
}

/* Responsive */

@media (max-width: $breakpoint-m) {
  .confirm__dialog.-size-l-m {
    @include font-sans-s-default;
    padding: var(--spacing-xxl);
    gap: var(--spacing-xl);
  }
}

@media (max-width: $breakpoint-mobile) {
  .confirm__buttons {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
}
