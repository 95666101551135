.briefing-alerts__header {
  @include font-sans-3xl-strong;
  padding: var(--spacing-xxl) 0;
  padding-top: var(--scroll-padding-top);
  margin-top: calc(-1 * var(--scroll-padding-top));
}

.briefing-alerts__list {
  margin-bottom: var(--spacing-xl);

  .alerts__item-unread-wrap {
    display: none;
  }

  .alerts__item {
    margin-inline: calc(-1* var(--spacing-xl));
  }
}

.briefing-alerts__subtitle {
  border-bottom: 1px solid var(--color-neutral-border-light);
  padding-bottom: var(--spacing-m);
}

@media screen and (max-width: $breakpoint-m) {
  .briefing-alerts__header {
    @include font-sans-xxl-strong;
  }
}

@media screen and (max-width: $breakpoint-s) {
  .briefing-alerts__header {
    @include font-sans-l-strong;
  }
}
