.paywall-details__headline,
.paywall-details__note {
  @include font-sans-l-default;
}

.paywall-details__title {
  @include font-sans-m-semi;
  text-align: center;
  text-wrap: unset;
  margin-top: var(--spacing-xxl);
}

.paywall-details__body {
  padding: 0 var(--spacing-xxl);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
  text-align: center;
  @include font-sans-m-default;
}

.paywall-details__separator {
  width: 100%;
  border-color: var(--color-neutral-border-light);
  border-top: none;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-top: var(--spacing-48);
}

.paywall-details__cards {
  display: flex;
  gap: var(--spacing-xxxl);
  @include font-sans-m-default;

  p {
    flex: 1;
  }

  strong {
    @include font-sans-m-strong;
  }
}

.paywall-details__tag-title {
  @include font-sans-l-strong;
}

.paywall-details__tags {
  display: flex;
  justify-content: center;
  gap: var(--spacing-xs);
  flex-wrap: wrap;
}

.paywall-details__tag {
  @include font-interface-s-default;
  background-color: var(--color-global-background-default);
  padding: var(--spacing-s) var(--spacing-xs);
  border-radius: var(--radius-min);
}

.paywall-details__link-know-more {
  @include font-sans-m-semi-link;
  cursor: pointer;
}

.paywall__container {
  &.-details {
    margin-top: 100vh;
    transition: margin-top .3s ease;
    padding: 0;
  }
}

.paywall__contents {
  &.-details {
    flex-grow: 1;
    justify-content: center;
    width: 100%;
    margin-block: var(--spacing-48) var(--spacing-xxxl);
    padding: 0;
    gap: 0;

    &.-subscribed {
      gap: var(--spacing-xxl);
    }

    &.-unsubscribed {
      margin: var(--spacing-xxl) var(--spacing-l) var(--spacing-xxxl);
      gap: var(--spacing-xl);
      text-align: center;
    }

    &.-editorial-access {
      padding-bottom: var(--spacing-xxxxl);
    }
  }
}

.paywall-details__footer {
  font-size: 0.9rem;
  word-break: break-word;
}

@media (min-width: $breakpoint-l) {
  .paywall-details__footer {
    font-size: 1.2rem;
  }
}

@media (max-width: $breakpoint-s) {
  .paywall__contents {
    &.-subscribed {
      gap: var(--spacing-xl);
    }
  }

  .paywall-details__title {
    @include font-sans-xl-strong;
  }

  .paywall-details__body {
    font-size: 1.4rem;
  }
}

@media (max-width: $breakpoint-mobile) {
  .paywall-details__cards {
    flex-direction: column;
  }

  .paywall-details__separator {
    display: none;
  }
}
