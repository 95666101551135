.signatory__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xxs);

  color: var(--color-neutral-text-shape-default);
  @include font-sans-m-default;
}

.signatory__party {
  display: flex;
  padding: var(--spacing-xxs);
  justify-content: center;
  align-items: center;
  max-height: 1.9rem;

  border-radius: var(--radius-min);

  @include font-condensed-s-strong;
  font-weight: 500;
  line-height: 150%;

  &[hover-tooltip] {
    position: relative;
  }

  &[hover-tooltip]:hover::before {
    color: inherit;
    background-color: inherit;
    text-wrap:nowrap;
    width: unset;
  }
}
