/* Black header */
.tag-header {
  background: var(--color-neutral-surface-contrast-dark);
  color: var(--color-neutral-text-shape-contrast-default);

  &:has(.banner__container:not(.-hidden)) {
    .tag-header__image {
      margin-inline: var(--spacing-xl);
    }
  }
}

.tag-header__image {
  height: 35rem;
  background-size: cover;
  margin-bottom: calc(-1 * var(--spacing-72));
}

.tag-header__main-column {
  display: flex;
  padding-block: var(--spacing-xxl);
  gap: var(--spacing-xxl);
  flex-direction: column;
  background: var(--color-neutral-surface-contrast-dark);
  border-radius: var(--radius-rounded);
}

.tag_header__title_container,
.tag_header__current-situation_container {
  &:not(:last-child) {
    padding-bottom: var(--spacing-xxxl);
    border-bottom: 4px solid var(--color-neutral-border-contrast-default);
  }
}

.tag_header__focus_container {
  text-align: center;
}

.tag_header__focus {
  color: var(--color-brand-primary-text-shape-default);
  @include font-produkt-xxl;
}

.tag_header__edition {
  color: var(--color-neutral-text-shape-contrast-default);
  @include font-edition-xxl;
}

.tag_header__title {
  color: var(--color-neutral-text-shape-contrast-default);
  text-align: center;
  @include font-serif-3xl-strong;
}

.tag_header__summary {
  color: var(--color-neutral-text-shape-contrast-default);
  text-align: center;
  @include font-serif-l-medium;
  margin-top: var(--spacing-xl);
}

.tag_header__current-situation-title {
  color: var(--color-brand-primary-text-shape-default);
  @include font-condensed-l-strong;
}

.tag_header__current-situation-summary {
  margin: var(--spacing-xl) 0;
}

.tag_header__current-situation-updated-date {
  color: var(--color-neutral-text-shape-contrast-light);
  @include font-sans-xs-default;
}

.tag_header__must_read_container {
  margin-bottom: var(--spacing-xl);
}

.tag_header__must_read-title {
  color: var(--color-brand-primary-text-shape-default);
  @include font-condensed-l-strong;
}

.tag_header__must_read-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: var(--spacing-xxl);
  column-gap: var(--spacing-xxl);
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xxl);

  .newscard {
    gap: var(--spacing-l);
    .thumbnail {
      --copyright-line-clamp: 8;
    }
  }

  .newscard__article-content {
    gap: var(--spacing-xxs);
  }

  .newscard__article-col {
    padding-bottom: var(--spacing-none);
  }

  .newscard__article-title {
    color: var(--color-neutral-text-shape-contrast-default);
    @include font-sans-xl-strong;
  }

  .newscard__article-header {
    color: var(--color-neutral-text-shape-contrast-default);
    @include font-sans-m-default;
  }
}

/* Basic header */

.tag-basic-header {
  padding: var(--spacing-xxl) 0;
}

.tag-basic-header__title {
  color: var(--color-neutral-text-shape-default);
  @include font-sans-xxl-strong;
}

.tag-basic-header__summary {
  color: var(--color-neutral-text-shape-light);
  @include font-sans-m-default;
  margin-top: var(--spacing-s);
}


/* Editorials */

.tag__editorials {
  padding-block: var(--spacing-xxxl) var(--spacing-72);
}

.tag__editorials-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tag__editorials-header .select__options {
  right: 0;
}

/* Responsive */

@media screen and (max-width: $breakpoint-m) {
  .tag-header__main-column {
    padding-inline: var(--spacing-xl);
    gap: var(--spacing-xl);
  }

  .tag-header__image {
    height: 29.4rem;
    margin-bottom: calc(-1 * var(--spacing-72));
  }

  .tag_header__must_read-grid {
    row-gap: var(--spacing-xl);
    column-gap: var(--spacing-xl);
    margin-top: var(--spacing-xl);

    .newscard__thumbnail {
      display: none;
    }

    .newscard__article-title {
      @include font-sans-l-strong;
    }

    .newscard__article-header {
      @include font-sans-s-default;
    }

    .newscard:nth-child(n+3) .newscard__article-header {
      display: none;
    }
  }

  .tag_header__title_container,
  .tag_header__current-situation_container,
  .tag_header__current-situation_container:not(:last-child) {
    padding-bottom: var(--spacing-xl);
  }

  .tag_header__title {
    @include font-serif-xxl-strong;
  }

  .tag_header__summary {
    @include font-serif-m-medium;
  }

  .tag_header__current-situation-title,
  .tag_header__must_read-title {
    @include font-condensed-m-strong;
  }

  .tag_header__current-situation-summary {
    margin: var(--spacing-s) 0;
    @include font-sans-s-default;
  }

  .tag-header__main-column {
    @include font-sans-s-default;
  }


  .tag-basic-header {
    padding: var(--spacing-xl) 0;
  }

  .tag-basic-header__title {
    @include font-sans-l-strong;
  }

  .tag__editorials {
    padding-block: var(--spacing-xl) var(--spacing-xxxxl);
  }
}

@media screen and (max-width: $breakpoint-s) {
  .tag-header__image {
    height: 24rem;
    margin-bottom: calc(-1 * var(--spacing-xxxl));
  }

  .tag_header__focus_container,
  .tag_header__title,
  .tag_header__summary {
    text-align: left;
  }

  .tag_header__title_container,
  .tag_header__current-situation_container {
    padding-bottom: var(--spacing-xl);
  }

  .tag-basic-header {
    padding: var(--spacing-xl) 0 var(--spacing-s) 0;
  }

  .tag-basic-header__summary {
    @include font-sans-s-default;
  }

  .tag__editorials {
    padding-bottom: var(--spacing-xxxl);
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .tag-header__image {
    height: 20rem;
    margin-bottom: 0;
  }

  .tag_header__title {
    @include font-serif-xxl-strong;
  }

  .tag_header__summary {
    margin-top: var(--spacing-s);
  }

  .tag_header__must_read-grid {
    grid-template-columns: 1fr;

    .newscard__article-header {
      display: none;
    }
  }

  .tag-basic-header__title {
    @include font-sans-m-strong;
  }

  .tag-basic-header__summary {
    margin-top: var(--spacing-xxs);
  }

  .tag__editorials {
    padding-bottom: var(--spacing-xxl);
  }
}
