[hover-tooltip].subscription-tooltip:hover {
  &::before {
    @include font-sans-xxs-default;
    border-radius: var(--radius-min);
    background: var(--color-neutral-surface-contrast-dark);
    padding: var(--spacing-xxs) var(--spacing-xs);
    width: unset;
  }

  &::after {
    border-color: transparent transparent var(--color-neutral-surface-contrast-dark);
  }

  &[tooltip-position="top"]:hover::after {
        border-color: var(--color-neutral-surface-contrast-dark) transparent transparent;
  }
}
