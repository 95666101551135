.menu {
  background-color: var(--color-neutral-surface-contrast-dark);
  min-height: 100vh;
  margin: 0;
  padding: 0;
  border: none;
  overflow-y: auto;
  transition: transform 0.3s, visibility 0s 0.3s;

  &.-hidden {
    visibility: hidden;
    transform: translateX(-100%);
    pointer-events: none;
  }
}

.menu__button {
  margin: var(--spacing-xxs) var(--spacing-xl);
}

.menu__wrap {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-xxl);
  gap: var(--spacing-xxl);
}

@mixin menu-button {
  display: flex;
  align-items: center;
  background-color: var(--color-neutral-surface-contrast-dark);
  border-radius: var(--radius-min);
  color: var(--color-neutral-text-shape-contrast-default);
  border: 1px solid var(--color-neutral-border-contrast-default);
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background-color: var(--color-global-state-hover-surface-default);
    border-color: var(--color-global-state-hover-border-default);
  }

  &:active {
    background-color: var(--color-global-state-hover-surface-dark);
    border-color: var(--color-global-state-hover-surface-dark) !important;
  }
}

/* @TODO: remove all "menu__today-briefing" when augmented briefing goes live */
.menu__today-briefing {
  @include menu-button;
  margin-top: calc(-1 * var(--spacing-xl));
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-l) var(--spacing-xl);
}

.menu__today-briefing-icon {
  width: 2.4rem;
  height: 2.4rem;
}

.menu__today-briefing-text {
  @include font-produkt-xl;
  text-decoration: none;
  text-transform: none;

  .-briefings {
    color: var(--color-brand-primary-text-shape-default);
  }
}

.menu__today-briefing:hover .menu__today-briefing-text,
.menu__today-briefing:active .menu__today-briefing-text,
.menu__today-briefing:focus-visible .menu__today-briefing-text {
  text-decoration: none;

  .-briefings {
    color: var(--color-global-white);
  }
}

.button.open-modal-button {
  @include menu-button;
  @include font-interface-m-semi;

  justify-content: flex-start;
  width: 100%;
}

.menu__submenu {
  width: 28rem;

    &.-footer {
    display: flex;
    flex-direction: column;
    margin: var(--spacing-xl) 0 var(--spacing-xxxxl);
    gap: var(--spacing-s);
  }
}

.menu__submenu-title {
  @include font-condensed-m-strong;
  padding-block: var(--spacing-s);
  color: var(--color-neutral-text-shape-contrast-light);
}

.menu__submenu-items {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  margin: 0;
  padding: var(--spacing-s) 0 0;
  border-top: 1px solid var(--color-neutral-border-contrast-default);
  list-style: none;
}

.menu__submenu-item {
  display: flex;
  align-items: center;
  height: 2.4rem;

  .badge {
    margin-left: auto;
  }

  &:has(svg) {
    gap: var(--spacing-xs);
  }

  svg {
    color: var(--color-neutral-text-shape-contrast-default);
    width: 2.4rem;
    height: 2.4rem;
  }
}

.menu__edition-tag {
  @include font-sans-s-default;
}

.menu__submenu-tool, .menu__submenu-text {
  @include font-sans-m-default;
}

.menu__submenu-tool,
.menu__edition-tag,
.menu__submenu-text {
  color: var(--color-neutral-text-shape-contrast-default);
  display: block;
  text-decoration: none;
  text-transform: none;

  &.-focus-event{
    padding: var(--spacing-s) 0;
  }
}

.menu__edition-container {
  display: flex;
  padding: var(--spacing-s) 0 var(--spacing-l);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xs);
  list-style: none;
}

.menu__edition-name {
  @include font-sans-m-strong;
  color: var(--color-neutral-text-shape-contrast-default);
  text-decoration: none;
}

details > summary::-webkit-details-marker {
  display: none;
}

details > summary {
  list-style-type: none;
}

.menu__edition {
  display: flex;
  border-top: 1px solid var(--color-neutral-border-contrast-default);
  padding: var(--spacing-xs) 0 var(--spacing-l);
  flex-wrap: wrap;
}

.menu__edition-name-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.menu__edition-name-wrap:hover,
.menu__edition-name-wrap:focus-visible {
  cursor: pointer;
  text-decoration: none;

  > * {
    color: var(--color-global-state-hover-text-shape-contrast-default);
  }
}

.menu__edition-name-wrap:active .menu__submenu-chevron {
  color: var(--color-global-state-hover-text-shape-inverted);
}

.menu__edition-name-wrap:active .menu__edition-name {
  color: var(--color-global-state-hover-text-shape-inverted);
}

.menu__edition-name:hover {
  color: var(--color-global-state-hover-text-shape-contrast-default);

  + .menu__submenu-chevron {
    color: var(--color-neutral-text-shape-contrast-light);
  }
}

.menu__submenu-chevron {
  width: 2.4rem;
  height: 2.4rem;
  color: var(--color-neutral-text-shape-contrast-light);
  transition: transform 0.1s ease-out;
  transform: rotate(180deg);
}

details[open] .menu__submenu-chevron {
  transform: rotate(0);
}

.menu__edition-link {
  @include font-sans-s-link;
  color: var(--color-neutral-text-shape-contrast-light);
  text-transform: none;
}

.menu__submenu-tool:hover,
.menu__edition-tag:hover,
.menu__edition-link:hover,
.menu__submenu-text:hover {
  color: var(--color-global-state-hover-text-shape-contrast-default);
}

.menu__user-settings {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
}

@media (max-width: $breakpoint-mobile) {
  .menu,
  .menu__submenu {
    width: 100%;
    max-width: unset;
  }
}
