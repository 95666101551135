/* Dialog */

/* Disable for uncompatible browser */
dialog.dialog__modal {
  display: none;
}

dialog.dialog__modal[open] {
  display: block;
}

.dialog__modal::backdrop {
  opacity: 0.9;
  background: var(--color-neutral-text-shape-default);
  mix-blend-mode: darken;
}

/* Dialog object */

dialog[open].dialog__modal {
  display: flex;
  flex-direction: column;
  max-width: 70.4rem;
  padding: var(--spacing-xxl);
  gap: var(--spacing-none);

  border-radius: var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-light);
  background: var(--color-neutral-surface-lighter);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.20);
}

@media screen and (max-width: $breakpoint-mobile) {
  dialog[open].dialog__modal {
    max-height: none;
    height: calc(100dvh - var(--spacing-xl) * 2);
    margin: var(--spacing-xl);
  }

  .dialog__modal-content {
    display: flex;
    flex-direction: column;
  }
}

/* Dialog content */

.dialog__modal-title {
  @include font-sans-l-strong;

  color: var(--color-neutral-text-shape-default);
  margin-bottom: var(--spacing-xl);
}

.dialog__modal-content {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--spacing-xl);
  gap: var(--spacing-xl);
  align-self: stretch;
}

.dialog__modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: var(--spacing-xl);
  padding: var(--spacing-none);
  gap: var(--spacing-xl);
}

.dialog__modal-text {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.5em;
}

