.searchfacet .dropdown__button {
  @include font-interface-m-default;

  color: var(--color-neutral-text-shape-contrast-default);
  border-radius: var(--radius-min);
  border: 1px solid var(--color-neutral-border-contrast-dark);
  background: var(--color-neutral-surface-contrast-dark);
  padding: var(--spacing-s) var(--spacing-s) var(--spacing-s) var(--spacing-xl);

  display: flex;
  align-items: center;
  gap: var(--spacing-s);

  &.-light {
    background: var(--color-neutral-surface-light);
    color: var(--color-neutral-text-shape-light);
    border-color: var(--color-neutral-border-default);
  }
}

.searchfacet .dropdown__button:hover,
.searchfacet .dropdown__button:focus-visible,
.searchfacet.-open .dropdown__button {
  outline: none;
  color: var(--color-global-white);
  background-color: var(--color-global-state-hover-surface-dark);
  border-color: var(--color-global-state-hover-surface-dark);
}

.searchfacet__chevron {
  width: 2.4rem;
  height: 2.4rem;
}

.searchfacet .dropdown__body {
  top: calc(100% - 1px);
}

.searchfacet__checkbox {
  display: none;
}

.searchfacet__container {
  color: var(--color-neutral-text-shape-default);
  border-radius: var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-light);
  background: var(--color-neutral-surface-lighter);
  padding: var(--spacing-xs);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);

  &.-light {
    background: var(--color-neutral-surface-light);
  }
}

.searchfacet__searchbox {
  padding: var(--spacing-xs) var(--spacing-xl);
  gap: var(--spacing-s);
  display: flex;
  align-items: center;
  cursor: text;
  min-width: 22rem;
}

.searchfacet__searchbox svg {
  pointer-events: none;
  flex-shrink: 0;
}

.searchfacet__searchfield {
  @include font-sans-s-default;
  color: inherit;
  background-color: transparent;
  border: none;
  width: 100%;
}

.searchfacet__searchfield:focus-visible {
  outline: none;
}

.searchfacet__values {
  margin: 0;
  max-height: 30vh;
  overflow-y: auto;
  padding: 0;
}

.searchfacet__item {
  @include font-sans-s-default;

  color: var(--color-neutral-text-shape-default);
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: var(--spacing-s) var(--spacing-xl);
  border-radius: var(--radius-min);

  &.-light {
    background: var(--color-neutral-surface-light);
    color: var(--color-neutral-text-shape-light);
    border-color: var(--color-neutral-border-default);
  }
}

.searchfacet__item:hover,
.searchfacet__item:focus,
.searchfacet__item:focus-within {
  color: var(--color-global-white);
  background: var(--color-global-state-hover-surface-dark);
}

.searchfacet__name {
  max-width: 26rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchfacet__item.-selected {
  font-weight: 600;
}

.searchfacet__results {
  margin-left: var(--spacing-xl);
  font-variant-numeric: tabular-nums;
}

@media (max-width: $breakpoint-m) {
  .searchfacet .dropdown__button {
    @include font-interface-s-default;
  }
}

@media (max-width: $breakpoint-s) {
  .searchfacet .dropdown__button {
    padding: var(--spacing-s);
    gap: var(--spacing-xs);
  }
  .searchfacet__chevron {
    margin: calc(-1 * var(--spacing-xs));
    margin-left: 0;
  }
}
