@use "mixins" as *;
@import url("/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/./fonts/index.scss");

/*
  When available, it's recommended to use mixins instead of
  referencing CSS variables from this file.
  They are located in mixins/_fonts.scss.
*/

:root {
  /*
    Useful font resources:
    - The 62.5% trick: https://www.aleksandrhovhannisyan.com/blog/62-5-percent-font-size-trick/
    - Debug woff2 font: https://fontdrop.info
    - Font shorthand: https://shrthnd.volume7.i
    - How to read the font shorthand property: font-style font-weight font-size/line-height font-family;
  */

  /* Make 1rem = 10px */
  font-size: 62.5%;

  /* Enable font aliasing */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Font families of Journal */
  --font-graphik-cond: 'Graphik Cond', 'Arial', sans-serif;
  --font-graphik: 'Graphik', 'Arial', sans-serif;
  --font-produkt: 'Produkt', sans-serif;
  --font-the-future: 'The Future', sans-serif;
  --font-tiempos-text: 'Tiempos Text', 'Times New Roman', sans-serif;

  /* Font Families of Lois */
  --font-inter: 'Inter', 'Arial', sans-serif;
  --font-roboto: 'Roboto Mono', sans-serif;
  --font-tiempos-headline: 'Tiempos Headline', 'Georgia', sans-serif;
}

/* Default font */

body {
  @include font-sans-m-default;
}
