.auth {
  margin: auto;
  padding: var(--spacing-xxl);
  min-width: 35rem;
  background-color: var(--color-neutral-surface-lighter);
  border: 1px solid var(--color-neutral-border-light);

  p {
    @include font-sans-s-default;
    color: var(--color-neutral-text-shape-light);
  }
}

.auth__title {
  @include font-sans-l-strong;
  margin-bottom: var(--spacing-xl);

  &.-large {
    @include font-sans-xl-strong;
  }
}

.auth__body {
  @include font-sans-s-default;
  color: var(--color-neutral-text-shape-light);

  &.-xxl-spaced {
    margin-bottom: var(--spacing-xxl);
  }

  &.-xl-spaced {
    margin-bottom: var(--spacing-xl);
  }
}

.auth__link {
  @include font-sans-s-link;

  &:hover {
    color: var(--color-global-state-hover-text-shape-default);
  }
}

.auth__form {
  display: flex;
  flex-direction: column;

  &.-xxxl-spaced {
    gap: var(--spacing-xxxl);
  }

  &.-xxl-spaced {
    gap: var(--spacing-xxl);

    .formfield:last-of-type {
      margin-bottom: var(--spacing-s);
    }
  }

  .formfield {
    margin-bottom: 0;

    input {
      background-color: var(--color-neutral-surface-light);
    }
  }
}

.auth__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacing-xl);
  gap: var(--spacing-m);

  a {
    @include font-sans-s-default;
    text-decoration: none;
    text-align: center;

    &:hover {
      color: var(--color-global-state-hover-text-shape-default);
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .auth {
    width: 100%;
    min-width: unset;
    padding: var(--spacing-xxl);
  }

  .auth__footer {
    gap: var(--spacing-l);
  }
}
