/* BriefItems */

.briefitems {
  .briefitem-newscard {
    padding-bottom: 0 !important;
  }
}

.briefitems__grouped {
  padding: var(--spacing-s) var(--spacing-l) var(--spacing-s) var(--spacing-l);
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-s);
  gap: var(--spacing-s);
  align-self: stretch;

  &.-action {
    border-radius: var(--radius-rounded);
    border: 1px solid var(--color-neutral-border-light);
    background: var(--color-global-background-default);
  }

  &.-action:hover:not(.-expanded) {
    cursor: pointer;
    border-radius: var(--radius-rounded);
    border: 1px solid var(--color-global-state-hover-border-default);
    background: var(--color-global-state-hover-surface-default);

    &.briefitems__grouped-header,
    .briefitems__grouped-header {
      color: var(--color-global-state-hover-text-shape-inverted);
    }
  }

  .briefitem__grouped__edition-name {
    @include font-sans-s-semi;
  }
}

.briefitems__grouped-header {
  display: flex;
  @include font-sans-s-default;
  color: var(--color-neutral-text-shape-light);

  &.-action:hover {
    cursor: pointer;
    border-radius: var(--radius-rounded);
  }
}

.briefitems__grouped-message {
  flex: 1 0 0;
}

.briefitems__expand-icon {
  transform: rotate(-90deg);
  &.-animated {
    transform: rotate(0deg);
  }
}

.briefitems__items {
  display: flex;
  padding-top: var(--spacing-xl);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xxl);
  align-self: stretch;
}
