.editorial-info-badges {
  display: flex;
  padding-top: var(--spacing-xxxxs);
  align-items: flex-start;
  gap: var(--spacing-xs);

  @include font-condensed-m-strong;
}

.editorial-date {
  color: var(--color-neutral-text-shape-light);
}

.editorial-edition {
  color: var(--color-neutral-text-shape-default)
}

.editorial-typology {
  color: var(--color-brand-primary-text-shape-default);
}
