.sortable-list {
  a {
    text-decoration: none;
  }
}

.sortable-list__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--spacing-none);
  padding: var(--spacing-xl);
  color: var(--color-neutral-text-shape-contrast-default);
  background: var(--color-neutral-surface-contrast-darker);
  border-bottom: 1px solid var(--color-neutral-border-contrast-default);
}

.sortable-list__form {
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: var(--spacing-xl);
  margin: var(--spacing-l) 0 var(--spacing-xl);
}

.sortable-list__field-row {
  display: flex;
  flex: 1 0 0;
  align-items: center;
  gap: 2.4rem;
}

.sortable-list__search-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex: 0;
  width: 21.1rem;
  height: 4.0rem;
  gap: var(--spacing-xxs);
  padding: var(--spacing-xs) var(--spacing-m);
  border-radius: var(--radius-max);
  border: 1px solid var(--color-neutral-border-light);
  background: var(--color-neutral-surface-lighter);

  &:focus-within {
    outline: .1rem solid var(--color-global-state-hover-border-default);
  }

  & svg {
    width: 2.4rem;
    height: 2.4rem;
    color: var(--color-neutral-text-shape-light);
  }

  .sortable-list__search-field {
    border: none;
    color: var(--color-neutral-text-shape-light);
    background: var(--color-neutral-surface-lighter);
    @include font-sans-s-default;

    &:focus {
      outline: none;
    }
  }
}

.sortable-list__options {
  .select.-small .select__chevron {
    width: 1.6rem;
    height: 1.6rem;
  }

  & svg {
    width: 1.6rem;
    height: 1.6rem;
  }

  .select__option {
    @include font-sans-s-default;
  }
}

.sortable-list__htmx-indicator {
  &.htmx-indicator {
    display: none;
  }

  &.htmx-request {
    display: none;

    &.htmx-indicator {
      display: flex;
    }

    &.-spinner {
      &::after {
        animation: loader 1.1s infinite linear;
        content: url("/home/runner/work/contexte/contexte/apps/ui/static_src/ui/components/list/../../base/images/spinner.svg");
        display: block;
        height: 5rem;
        margin: var(--spacing-xxl) auto 0;
        width: 5rem;
      }

      + .sortable-list__content {
        display: none;
      }
    }
  }
}

.sortable-list__heading {
  @include font-sans-l-strong;
}

.sortable-list__subheading {
  @include font-sans-m-default;
}

.sortable-list__content {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--spacing-none);
  padding: var(--spacing-none);
  margin: var(--spacing-none);
}

.sortable-list__button {
  display: flex;
  flex-direction: column;

  .button {
    border: 1px solid var(--color-neutral-border-darker);
    padding: var(--spacing-xxs) var(--spacing-m);
    @include font-interface-s-semi;
  }
}

@media (max-width: $breakpoint-s) {
  .sortable-list__form {
    margin: var(--spacing-l) 0;
  }

  .sortable-list__field-row {
    justify-content: space-between;
    align-self: stretch;
  }

  .sortable-list__search-box {
    width: 14.1rem;

    .sortable-list__search-field {
      width: 9.1rem;
    }
  }

  .sortable-list__options .select__options {
    right: 0;
  }

  .sortable-list__heading {
    @include font-sans-m-strong;
  }

  .sortable-list__subheading {
    @include font-sans-s-default;
  }
}
