@import "intl-tel-input/build/css/intlTelInput.css";

.formfield.-phone {
  .form__label {
    z-index: 2;
  }
}

.iti {
  width: 100%;
}

.iti__flag-container:has(+ input[disabled] ) {
  display: none;
}

.formfield__field .iti .form__input:disabled {
  padding-left: var(--spacing-m) !important;
}

.iti__flag-container {
  & :disabled {
    display: none;
  }
  padding: 0;
  border: solid 1px transparent;
  & .iti__selected-flag{
    border-radius:  var(--radius-rounded) 0 0 var(--radius-rounded);
  }
}

.iti--separate-dial-code .iti__selected-flag {
  color: var(--color-neutral-text-shape-light);
  background-color: var(--color-neutral-surface-lighter);
}

.iti__country-list {
  background-color: var(--color-neutral-surface-light);
}

.iti__country {
  background-color: var(--color-neutral-surface-light);
  color: var(--color-neutral-text-shape-dark);

  &.iti__highlight {
    background-color: var(--color-neutral-surface-light);
  }

  &:hover {
    background-color: var(--color-neutral-surface-light);
  }
}

.iti__divider {
  border-bottom: 1px solid var(--color-neutral-border-light);
  background-color: unset;
}
