.toggle {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:has(input[type=checkbox]:disabled) {
    cursor: not-allowed;
  }

  input[type=checkbox]:disabled ~ .toggle__text {
    color: var(--color-global-state-disabled-text-shape-default);
  }

  input[type=checkbox]:disabled ~ .toggle__text {
    cursor: unset;
  }

  &:not(:has(input[type=checkbox]:disabled)) {
    &:hover,
    &:active {
      & .toggle__widget {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), var(--color-neutral-surface-default, #F0F0F5);
      }

      &:has(input[type=checkbox]:checked) .toggle__widget {
        background-color: var(--color-global-state-hover-surface-dark);
      }
    }
  }
}

.toggle input[type=checkbox] {
  display: none;
}

.toggle__widget {
  display: inline-block;
  position: relative;
  border-radius: 3rem;
  border: 0;

  width: 4.2rem;
  height: 2.5rem;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), var(--color-neutral-surface-default, #F0F0F5);

  &::after {
    content: "";
    position: absolute;
    left: .4rem;
    top: .3rem;
    right: .3rem;
    height: 1.7rem;
    width: 1.7rem;
    border-radius: var(--radius-max);
    background-color: var(--color-global-white);
    box-shadow: 0px 0px var(--spacing-XS, 6px) 0px rgba(7, 0, 46, 0.15);
    transition: left .2s ease;
  }

  /* Sizes */

  .toggle.-size-m & {
    @include font-sans-m-semi;
    width: 4.2rem;
    height: 2.4rem;

    &::after {
      height: 1.75rem;
      width: 1.75rem;
      // top: calc((2.4rem - 1.75rem) / 2);
      top: 0.325rem;
      left: 0.35rem;
    }
  }

  .toggle.-size-m:has(input[type=checkbox]:checked) &::after {
    // left: calc(4.2rem - 1.75rem - 0.35rem);
    left: 2.1rem;
    box-shadow: none;
  }
}

@mixin toggle-size-s {
  & .toggle__widget {
    @include font-sans-s-semi;
    width: 3.2rem;
    height: 1.6rem;

    &::after {
      height: 1.2rem;
      width: 1.2rem;
      // top: calc((1.6rem - 1.2rem) / 2);
      top: 0.2rem;
      left: 0.2rem;
    }
  }

  &:has(input[type=checkbox]:checked) .toggle__widget::after {
    // left: calc(3.2rem - 1.2rem - 0.2rem);
    left: 1.8rem;
  }
}

.toggle.-size-s {
  @include toggle-size-s;
}

@media (max-width: $breakpoint-m) {
  .toggle.-size-s-below-breakpoint-m {
    @include toggle-size-s;
  }
}

.toggle__widget {
  /* States */

  .toggle input[type=checkbox]:checked ~ & {
    background: var(--color-global-state-hover-surface-default);
  }

  border-radius: 3rem;

  .toggle input[type=checkbox]:disabled ~ & {
    background: var(--color-global-state-disabled-surface-default);
    pointer-events: none;

    &::after {
      background: var(--color-global-white);
    }
  }

  .toggle input[type=checkbox]:disabled:not(:checked) ~ &::after {
    opacity: 0.6;
  }
}

.toggle__text {
  margin-left: var(--spacing-s);

  .toggle.-size-m & {
    @include font-sans-m-semi;
  }

  .toggle.-size-s & {
    @include font-sans-s-semi;
  }
}
