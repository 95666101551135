.thumbnail {
  width: 100%;
  height: 100%;
  --copyright-line-clamp: 9;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;

  padding: var(--spacing-none);
  gap: var(--spacing-xxs);
  align-self: stretch;
}

.thumbnail__image {
  grid-column: 1;
  grid-row: 1;
  aspect-ratio: 16 / 9;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.thumbnail__wrap-copyright {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  pointer-events: none; // make image clickable
}

.thumbnail__wrap-copyright-hover {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: var(--spacing-s);

  pointer-events: auto; // make copyright hoverable

  &:hover,
  &:has(.thumbnail__info-icon:focus-within) {
    .thumbnail__info-text {
      display: -webkit-box;
    }

    .-on-hover {
      display: flex;
    }

    .-off-hover {
      display: none;
    }
  }
}

.thumbnail__info-text {
  display: none;
  align-items: center;
  padding: var(--spacing-xxs) var(--spacing-s);
  // padding-right is increased because of a -webkit kind of miscalculating content width
  padding-right: var(--spacing-xl);
  margin-bottom: var(--spacing-s);
  max-width: min(296px, calc(100% - 32px - var(--spacing-s) - var(--spacing-s)));
  max-height: calc(100% - var(--spacing-s) - var(--spacing-s));
  min-height: var(--spacing-xxxl);
  background: var(--color-neutral-surface-contrast-dark);
  border-radius: var(--radius-rounded);

  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--copyright-line-clamp);
  overflow: hidden;

  color: var(--color-neutral-text-shape-contrast-default);
  text-shadow: 0px 0px var(--spacing-xxs) rgba(7, 0, 46, 0.60);
  @include font-sans-xxs-default;
}

.thumbnail__info-icon {
  display: flex;
  padding: var(--spacing-s);

  svg {
    align-self: stretch;
    width: var(--spacing-xl);
    height: var(--spacing-xl);
  }
}

.-on-hover {
  display: none;
}

.-off-hover {
  display: flex;
}
