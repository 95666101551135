@use "mixins" as *;
@font-face {
  font-family: "Graphik Cond";
  src: url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./GraphikCondensed-Bold-Web.woff2) format("woff2"),
       url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./GraphikCondensed-Bold-Web.woff) format("woff");
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik Cond';
  src: url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./GraphikCondensed-Semibold-Web.woff2) format('woff2'),
       url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./GraphikCondensed-Semibold-Web.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik Cond";
  src: url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./GraphikCondensed-Regular-Web.woff2) format("woff2"),
       url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./GraphikCondensed-Regular-Web.woff) format("woff");
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./Graphik-BoldItalic-Web.woff2) format("woff2"),
       url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./Graphik-BoldItalic-Web.woff) format("woff");
  font-weight:  700;
  font-style:   italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./Graphik-Bold-Web.woff2) format("woff2"),
       url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./Graphik-Bold-Web.woff) format("woff");
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./Graphik-RegularItalic-Web.woff2) format("woff2"),
       url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./Graphik-RegularItalic-Web.woff) format("woff");
  font-weight:  400;
  font-style:   italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./Graphik-Regular-Web.woff2) format("woff2"),
       url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./Graphik-Regular-Web.woff) format("woff");
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./Graphik-SemiboldItalic-Web.woff2) format('woff2'),
       url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./Graphik-SemiboldItalic-Web.woff) format('woff');
  font-weight:  600;
  font-style:   italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./Graphik-Semibold-Web.woff2) format('woff2'),
       url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/graphik/./Graphik-Semibold-Web.woff) format('woff');
  font-weight:  600;
  font-style:   normal;
  font-stretch: normal;
}
