dialog[open].dialog__modal.feature__dialog {
  height: fit-content;
  border: 1px solid var(--color-neutral-border-contrast-light);
  background: var(--color-neutral-surface-contrast-darker);
}

.feature-dialog {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.feature-dialog__illustration {
  display: flex;
  width: 100%;
  border-radius: var(--radius-popin);
}

.feature-dialog__header {
  display: flex;
  justify-content: space-between;

  &:has(.badge) {
    gap: var(--spacing-s);
  }
}

.feature-dialog__title {
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-global-state-hover-text-shape-inverted);

  @include font-sans-xl-strong;
}

.feature-dialog__content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
  color: var(--color-neutral-text-shape-contrast-default);
}

.feature-dialog__content-heading {
  @include font-sans-m-semi;
}

.feature-dialog__text {
  @include font-sans-m-default;
}

.feature-dialog__buttons {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-l);
}

@media screen and (max-width: $breakpoint-s) {
  dialog[open].dialog__modal {
    margin: auto var(--spacing-s);
    padding: var(--spacing-xl) var(--spacing-m);
  }
}
