.formfield {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-xl);

  &:has(.form__input[type=text]),
  &:has(.form__input[type=password]),
  &:has(.form__input[type=email]),
  &:has(.form__input[type=tel]) {
    & .form__label {
      @include font-sans-m-semi;
    }
  }

  &.-checkbox {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    &:not(:has(input:disabled)) {
      &,
      & label,
      & input {
        cursor: pointer;
      }

      &:hover .form__input:checked,
      &:hover .form__input:indeterminate,
      & .form__input:checked:focus-visible,
      & .form__input:indeterminate:focus-visible  {
          border: 1px solid var(--color-global-state-hover-border-light);
          background: var(--color-global-state-hover-surface-dark);
          & ~ .form__icon {
            color: var(--color-global-white);
          }
      }

      &:hover .form__input,
      & .form__input:focus-visible {
        border: 1px solid var(--color-neutral-border-darker);
        background: rgba(5, 61, 206, 0.10);
      }
    }
  }

  /* Sizes */

  &.-size-l {
    @include font-sans-l-default;
    &:has(input[type=checkbox]) label {
      padding: 0 0 0 var(--spacing-l);
    }
  }

  &.-size-m {
    @include font-sans-m-default;
    &:has(input[type=checkbox]) label {
      padding: 0 0 0 var(--spacing-m);
    }
  }

  &.-size-s {
    @include font-sans-s-default;
    &:has(input[type=checkbox]) label {
      padding: 0 0 0 var(--spacing-s);
    }
  }

  /* States */

  &:has(input[type="password"]:disabled) {
    svg {
      fill: var(--color-global-state-disabled-text-shape-default);
      pointer-events: none;
    }
  }

  &:has(input:disabled) {
    &,
    & label{
      cursor: not-allowed;
    }

    .form__label {
      color: var(--color-global-state-disabled-text-shape-default);
    }
    &.-contrasted{
      .form__label {
        color: var(--color-global-state-disabled-text-shape-contrasted);
      }
    }
  }

  &:last-of-type {
    margin-bottom: var(--spacing-none);
  }

  &.-witherror {
    margin-bottom: var(--spacing-l);

    .form__label {
      color: var(--color-feedback-danger-text-shape-dark);
    }
  }

  &.-witherror:last-of-type {
    margin-bottom: var(--spacing-m);
  }

  /* Skin */
  &.-contrasted {
    color: var(--color-neutral-text-shape-contrast-default);

    .form__input {
      &[type=text],
      &[type=password],
      &[type=email],
      &[type=tel] {
        background: var(--color-neutral-surface-contrast-darker);
        border: 1px solid var(--color-neutral-border-contrast-default);
        color: var(--color-neutral-text-shape-contrast-default);
      }

      &:disabled{
        border: 1px solid var(--color-global-state-disabled-border-contrasted);
        background: var(--color-global-state-disabled-surface-contrasted);
        color: var(--color-global-state-disabled-text-shape-contrasted);
      }

      &:not(:disabled){
        &:hover,
        &.-witherror:hover {
          border: 1px solid var(--color-neutral-border-contrast-darker);
        }

        &:focus{
          border: 1px solid var(--color-global-state-hover-border-default);
        }
      }
    }

    .form__input[type=checkbox] {
      border: 1px solid var(--color-neutral-border-contrast-darker);

      &:not(:disabled) {

        &:hover,
        &:focus-visible {
          border: 1px solid var(--color-neutral-border-contrast-dark);
        }

        &:checked ~ .form__icon.-checked,
        &:indeterminate ~ .form__icon.-indeterminate {
          color: var(--color-global-white);
        }

        &:indeterminate {
          background-color: transparent;
          border: 1px solid var(--color-global-state-hover-border-light);
        }
      }


      &:disabled {
        background: var(--color-global-state-disabled-surface-contrasted);
        border: 1px solid var(--color-global-state-disabled-border-contrasted);

        &:checked ~ .form__icon.-checked,
        &:indeterminate ~ .form__icon.-indeterminate {
          color: var(--color-global-state-disabled-text-shape-contrasted);
        }
      }
    }

    &.-witherror {
      .form__label {
        color: var(--color-feedback-danger-text-shape-default);
      }

      .form__input {
        border: 1px solid var(--color-feedback-danger-border-contrast-default);
        background: var(--color-feedback-danger-surface-contrast-dark);
      }

      .formfield__icons > .form__icon {
        fill: var(--color-feedback-danger-text-shape-inverted);
      }
    }

  }

}

.form__label {
  display: flex;
  align-items: center;
  position: relative;

  & + .form__input {
    @include font-sans-m-semi;
  }

  &.-witherror {
    color: var(--color-feedback-danger-text-shape-default);
  }

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-left: var(--spacing-xs);
  }

  &:not(:has( .form__input[type=checkbox])) {
    padding-bottom: var(--spacing-xs);
  }
}

.formfield__field {
  position: relative;

  &.-witherror {
    .form__input {
      border: 1px solid var(--color-feedback-danger-border-default);
      background: var(--color-feedback-danger-surface-lighter);
    }

    .formfield__icons > .form__icon {
      fill: var(--color-feedback-danger-text-shape-default);
    }
  }
}

.form__input {
  &[type=text],
  &[type=password],
  &[type=email],
  &[type=tel] {
    @include font-sans-m-default;
    width: 100%;
    height: var(--spacing-48);
    padding: var(--spacing-m);
    padding-top: 0.6rem ;
    outline: none;
    appearance: none;
    box-shadow: none;
    border: 1px solid var(--color-neutral-border-light);
    border-radius: var(--radius-rounded);
    color: var(--color-neutral-text-shape-default);
    background-color: var(--color-neutral-surface-light);
    transition: border .2s ease, color .2s ease, background-color .2s ease;
    caret-color: var(--color-global-state-hover-text-shape-default);
  }

  &::placeholder {
    color: var(--color-neutral-text-shape-light);
  }

  &[type=checkbox] {
    position: relative;
    border-radius: var(--radius-min);
    margin: var(--spacing-none);
    outline: none;
    appearance: none;
    transition: border .2s ease-in, box-shadow .2s ease, background-color .2s ease-out;
    order: 1;
    vertical-align: text-bottom;
    border: 1px solid var(--color-neutral-border-dark);

    & ~ .form__icon {
      display: none;
      position: absolute;
      left: 0rem;
      pointer-events: none;
    }

  /* Sizes */

    &.-size-l {
        width: 2.2rem;
        height: 2.2rem;

        & ~ .form__icon {
          height: 2.2rem;
          width: 2.2rem;
          top: 0.2rem;
        }
      }

    &.-size-m{
      width: 1.8rem;
      height: 1.8rem;

      & ~ .form__icon {
        height: 1.8rem;
        width: 1.8rem;
        top: 0.3rem;
      }
    }

    &.-size-s{
      width: 1.6rem;
      height: 1.6rem;

      & ~ .form__icon {
        height: 1.6rem;
        width: 1.6rem;
        top: 0.3rem;
      }
    }

    /* States */

    &:checked {
      background-color: var(--color-global-state-hover-surface-default);
      border: 1px solid var(--color-global-state-hover-border-light);
      transition: border .2s ease, color .2s ease, box-shadow .2s ease, background-color .2s ease;

      & ~ .form__icon.-checked {
        display: flex;
        color: var(--color-global-white);
      }

    }

    &:indeterminate {
      border: 1px solid var(--color-global-state-hover-border-light);
      background: var(--color-global-state-hover-surface-light);

      & ~ .form__icon.-indeterminate {
        display: flex;
        color: var(--color-global-state-hover-text-shape-default);
      }
    }

    &:disabled {
      background-color: var(--color-global-state-disabled-surface-default);
      border: 1px solid var(--color-global-state-disabled-surface-default);
      pointer-events: none;

      &:checked,
      &:indeterminate {
        & ~ .form__icon{
          color: var(--color-global-state-disabled-text-shape-default);
        }
      }
    }
  }

  /* States */

  &:disabled,
  &:read-only:not(input[type="checkbox"]) {
    color: var(--color-global-state-disabled-text-shape-default);
    background-color: var(--color-global-state-disabled-surface-default);
    border: 1px solid var(--color-global-state-disabled-border-default);
    box-shadow: none;
    pointer-events: none;
  }

  &:read-only::placeholder {
    color: var(--color-global-state-disabled-text-shape-default);
  }

  &:hover,
  &:focus-visible {
    border: 1px solid var(--color-neutral-border-dark);
    background: var(--color-neutral-surface-default);
  }

  &:focus-visible {
    border: 1px solid var(--color-global-state-hover-border-default);
  }

  &:disabled {
    &::placeholder {
      color: var(--color-global-state-disabled-text-shape-default);
    }
  }
}

.formfield__icons {
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  padding: var(--spacing-m) 0;
  padding-top: 1.2rem;
  right: var(--spacing-m);

  .formfield__button {
    background: none;
    border: none !important;
    padding: 0;
    margin: var(--spacing-l) var(--spacing-m);
    height: 0;
    min-height: 0;
  }

  .form__icon {
    width: 2.4rem;
    height: 2.4rem;
    fill: var(--color-neutral-text-shape-light);

    &:hover,
    &:focus-visible {
      fill: var(--color-global-state-hover-text-shape-default);
    }
  }
}

.form__error {
  @include font-sans-s-default;
  display: flex;
  align-items: center;
  order: 3;
  color: var(--color-feedback-danger-text-shape-default);
  margin-top: var(--spacing-xxs);
  gap: var(--spacing-xxs);

  > svg {
    align-self: flex-start;
    flex-shrink: 0;
    margin-top: var(--spacing-xxxs);
    width: 2rem;
    height: 2rem;
    fill: var(--color-feedback-danger-text-shape-default)
  }
}
