.watch {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  position: relative;
  z-index: 1;

  .watch__icon {
    height: 1em;
    width: 1em;
    stroke:var(--color-neutral-text-shape-light);
    fill:transparent;
  }

  .watch__label {
    display: none;
  }

  &:not(:hover):not(.-watched) {
    .watch__label:not(.-hover):not(.-watched) {
        display:unset;
    }
  }

  &:hover:not(.-watched) {
    .watch__icon {
      fill: var(--color-neutral-text-shape-light);
    }
    .watch__label.-hover:not(.-watched) {
        display: unset;
    }
  }

  &.-watched {
    .watch__icon {
      stroke: var(--color-brand-abo-text-shape-default);
      fill: var(--color-brand-abo-text-shape-default);
    }

    &:not(:hover) {
      .watch__label.-watched:not(.-hover) {
          display: unset;
      }
    }


    &:hover {
      .watch__icon {
        fill:transparent;
      }
      .watch__label.-hover.-watched {
          display: unset;
      }
    }
  }
}

