/******** SEARCHBOX ********/

.layout-stage.-bills-search {
  background-color: var(--color-global-background-light);
  padding-bottom: var(--spacing-xxl);
}

.searchbox {
  display: flex;
  align-items: center;
  max-width: 72.3rem;
  border-radius: var(--radius-min);
  border: 1px solid var(--color-neutral-border-light);
  background: var(--color-neutral-surface-lighter);
  height: 4rem;
  padding: var(--spacing-s) var(--spacing-xl);
  gap: var(--spacing-s);
}

.searchbox__icon {
  fill: var(--color-neutral-text-shape-light);
  width: 2.4rem;
  height: 2.4rem;
}

.searchbox__input {
  @include font-sans-m-default;
  background-color: var(--color-neutral-surface-lighter);
  border: 0 none;
  outline: none;
  width: 100%;
  color: var(--color-neutral-text-shape-light);
}

main.searching .layout-stage.-bills .sidenav {
  display: none;
}

.searchbox__clear {
  background: none;
  color: var(--color-neutral-text-shape-light);
  border: none;
  cursor: pointer;
}

.search-suggest {
  @include font-sans-m-default;
  display: flex;
  padding: var(--spacing-xl);
  color: var(--color-neutral-text-shape-light);
  background: var(--color-neutral-surface-light);
  border-radius: var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-light);
}

.layout-main.searching {
  min-height: calc(100vh - 28.8rem);
}

.bills__list.search-results {
  margin-bottom: var(--spacing-none);
}

/************************* NAVIGATION *************************/
.layout-stage.-bills .left-column, .layout-stage.-bills .main-column  {
  padding-top: var(--spacing-xl);
}

.layout-main:has(.searchbox__input:not(:empty)) .sidenav {
  display: none;
}

.layout-stage.-bills .sidenav {
  @include font-sans-s-semi;
  position: sticky;
  top: 18rem;
  z-index: 2;
}

/************************* MAIN CONTAINER *************************/

.layout-main.-bills {
  grid-template-rows: auto auto 1fr;
}

.layout-stage.-bills {
  height: 100%;
}

.bills__container {
  gap: var(--spacing-xxl);
  display: flex;
  flex-direction: column;
}

.bills__list {
  margin-bottom: var(--spacing-xxl);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxl);
}

#searched-bills {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxl);
}

.bills__heading {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  align-self: stretch;
  padding-top: var(--spacing-s);

  &.-top {
    padding-top: var(--spacing-s);
  }
}

.bills__heading-title {
  @include font-sans-l-default;
  color: var(--color-neutral-text-shape-light);
  padding-top: var(--spacing-72);
  margin-top: calc(var(--spacing-72) * -1);

  scroll-margin-block: calc(var(--spacing-xxxl) + var(--spacing-m));
}

.searching .bills__heading, .searching .emptystate, .searching .bills__list:not(.search-results)  {
  display: none;
}

.bills__heading-counter {
  @include font-condensed-xs-strong;
  display: flex;
  width: 2.4rem;
  height: 2.4rem;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-max);
  background: var(--color-neutral-surface-default);
  color: var(--color-neutral-text-shape-default);
  text-align: center;
  letter-spacing: -0.036rem;
}

/************************* BILLS CARDS *************************/
.bills__card {
  display: flex;
  width: 100%;
  gap: var(--spacing-xl);
  padding: var(--spacing-xl);
  padding-bottom: var(--spacing-xxl);
  background: var(--color-neutral-surface-lighter);
  border-radius: var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-light);
  position: relative;
}

.bills__card-description {
  @include font-sans-s-default;
  color: var(--color-neutral-text-shape-light);
  padding-top: var(--spacing-s);

}

.bills__card-title {
  @include font-sans-xl-strong;
  color: var(--color-neutral-text-shape-default);
  word-wrap: break-word;
}

.bill__summary {
  overflow: hidden;
  flex: 1 1 0;
}

.bills__card-column.-main {
  flex: 1 1 0;
  gap: var(--spacing-s);
  display: flex;
}

/* card aside left */

.bills__card-column.-left {
  padding-top:var(--spacing-xxxs);
}

.bills__card .watch__icon {
  width: 2.4rem;
  height: 2.4rem;
}

/* card aside right */
.bill__phase, .bill__step--preview {
  @include font-sans-m-strong;
}

.bill__phase {
  color: var(--color-feedback-info-text-shape-default);

  &.-adopted {
    color: var(--color-feedback-success-text-shape-default);
  }

  &.-rejected {
    color: var(--color-feedback-danger-text-shape-default);
  }
}

.bill__status {
  @include font-sans-s-default;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-neutral-text-shape-light);
  word-wrap: break-word;
}

.bill__status-step p:first-letter{
  text-transform: capitalize;
}

.bill__current-chamber {
  white-space: nowrap;
}

.bills__card-column.-right {
  flex: 1 1 0;
  align-self: stretch;
  border-left: 1px solid var(--color-neutral-border-light);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-s);
  display: inline-flex;
  max-width: 26.5rem;
  padding: 0.3rem var(--spacing-none) var(--spacing-none) var(--spacing-xl);
}

.bill__link {
  text-decoration: none;
  display: block;
  position: absolute;
  inset: var(--spacing-none);
}

/* RESPONSIVE */

/* --breakpoint-m */
@media (max-width: $breakpoint-m){
  .layout-stage {
    &.-bills, &.-bills-search {
      padding: var(--spacing-xl);
    }

    &.-bills {
      .left-column {
        padding: var(--spacing-s) 0 0;
      }
      .sidenav {
        top: 14rem;
        padding: 0;
      }
    }
  }

  .bills__heading.-top {
    padding-top: var(--spacing-xl);
  }

  /* Search */
  .searchbox {
    height: 3.2rem;
  }
  .searchbox__input {
    @include font-sans-s-default;
    letter-spacing: 0.014rem;
  }

  .searchbox__icon {
    width: 2rem;
    height: 2rem;
  }

  /* Main container */
  .layout-stage.-bills .main-column {
    padding: 0 var(--spacing-xxl);
  }

  .bills__heading {
    gap: var(--spacing-xxs, 0.4rem);
  }

  .bills__heading-title {
    @include font-sans-m-default;
  }

  .bills__heading-counter {
    width: var(--spacing-xl);
    height: var(--spacing-xl);
    font-size: 0.8rem;
    letter-spacing: -0.016rem;
  }

  /* Bill card */
  .layout-stage.-bills .main-column {
    padding-top: 0;
  }

  .bills__card {
    padding: var(--spacing-xl) var(--spacing-xxl) var(--spacing-xl) var(--spacing-s);
    gap: var(--spacing-s);
  }

  .bills__card .watch__icon {
    width: 1.6rem;
    height: 1.6rem;
    padding: 0rem 0.0485rem 0.0485rem 0rem;
  }

  .bills__card-column {
    &.-left {
      padding-top: var(--spacing-xxs);
    }

    &.-main {
      flex-direction: column;
      gap: var(--spacing-l);
    }

    &.-right {
      max-width: 100%;
      padding-top: var(--spacing-s);
      gap: var(--spacing-none);
      border-top: 0.5px var(--color-neutral-border-light) solid;
      border-left: 0;
      padding-left: 0;
      justify-content: center;
    }
  }

  .bill__summary {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xxs);
    align-self: stretch;
  }

  .bills__card-title {
    @include font-sans-m-strong;
  }

  .bills__card-description {
    align-self: stretch;
    padding-top: var(--spacing-none);
  }

  .bill__phase, .bill__step--preview {
    @include font-sans-s-semi;
  }

  .bill__status {
    @include font-sans-xs-default;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--spacing-s);
    letter-spacing: 0.012rem;
  }
}

@media (max-width: $breakpoint-s){
  .layout-stage {
    &.-bills-search {
      grid-template-columns: 0rem 1fr;
      padding: var(--spacing-xl);

      .main-column {
        padding: 0;
      }
    }
    &.-bills {
      grid-template-columns: 17rem 1fr 0rem;
      padding: var(--spacing-none) var(--spacing-xl);

      .left-column {
        padding-top: var(--spacing-s);
      }

      .sidenav {
        top: 13rem;
        padding: 0;
      }

      .main-column {
        padding: 0 var(--spacing-xl);
      }
    }
  }

  .searchbox {
    height: 4rem;
    max-height: 4rem;
    margin: 0;
  }

  .bills__container {
    padding-top: 0;
  }

  .bills__heading {
    padding-top: var(--spacing-xl);
    &.-top {
      padding-top: var(--spacing-l);
    }
    :target {
      scroll-margin-block: calc(-1 * var(--spacing-m));
    }
  }

  .bills__list {
    margin-bottom: var(--spacing-none);
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .layout-main.searching {
    display: block;
  }

  .searchbox__icon {
    width: 2.4rem;
    height: 2.4rem;
  }

  .searchbox__input {
    @include font-sans-m-default;
    letter-spacing: 0.014rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .layout-stage {
    &.-bills {
      grid-template-columns: 0fr 1fr;
      padding: var(--spacing-xl);

      .sidenav {
        display: none;
      }

      .main-column {
        padding: 0;
      }
    }
  }

  .bills__container {
    gap: var(--spacing-xl);
    display: flex;
    flex-direction: column;
  }

  .bills__card-column.-right {
    padding: var(--spacing-s) 0;
    flex: 1 1 0;
  }

  .bill__status {
    flex-direction: column;
    gap: var(--spacing-none);
  }
}
