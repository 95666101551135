.watchgroups__column {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxl);
  justify-content: flex-start;
  align-items: stretch;
}

.watchgroups__add-button {
  align-self: flex-start;
}

.watchgroup {
  &.-empty {
    .watchgroup-detail {
      background-color: transparent;
    }

    .watchgroup__header .watchgroup__name {
      color: var(--color-global-state-disabled-text-shape-default)
    }
  }

  &.-open {
    .watchgroup-detail {
      box-shadow: 0px 0px var(--spacing-xs) 0px rgba(7, 0, 46, 0.15);
    }
  }
}

.watchgroup-detail {
  background-color: var(--color-neutral-surface-lighter);
  border: 1px solid var(--color-neutral-border-light);
  border-radius: var(--radius-rounded);
  transition: box-shadow 300ms ease 0s, border 300ms ease;

  &.-disabled {
    border-color: var(--color-global-state-disabled-border-default);
    background-color: var(--color-global-background-light);

    .watchgroup__header {
      border-bottom-color: var(--color-global-state-disabled-border-default);
    }

    .watchgroup__header,
    .watchgroup__name,
    .watchgroup-param__selected-prefix,
    .watchgroup-param__selected-value {
      color: var(--color-global-state-disabled-text-shape-default);
    }
  }
}

.watchgroup__header {
  padding: var(--spacing-s) var(--spacing-xl);
  border-bottom: 1px solid var(--color-neutral-border-light);

  .-unedited,
  .-edited {
    min-height: 4rem;
  }

  .-unedited {
    gap: var(--spacing-xl);
    display: flex;
    align-items: center;

    .watchgroup__name-wrap {
      display: flex;
      align-items: center;
      gap: var(--spacing-s);
      flex: 1 0 0;
    }
  }

  .-edited {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: var(--spacing-xs);

    .watchgroup__name-wrap {
      display: flex;
      align-items: flex-start;
      flex: 1 0 0;
    }
  }

  .toggle {
    margin-left: auto;
  }
}

.watchgroup__name {
  @include font-sans-l-semi;
}

.watchgroup__name-input {
  border-radius: var(--radius-rounded) var(--radius-none) var(--radius-none) var(--radius-rounded) !important;
}

.watchgroup__name-button {
  border-radius: var(--radius-none) var(--radius-min) var(--radius-min) var(--radius-none) !important;
}

.watchgroup__keywords {
  display: flex;
  align-items: center;
  padding: var(--spacing-xl);
  gap: var(--spacing-s);
  flex-wrap: wrap;
}

.watchgroup__emptystate {
  @include font-sans-m-default;

  &.-erroneous {
    color: var(--color-feedback-danger-text-shape-default);
  }
}

.watchgroup-param {
  display: flex;
  margin-bottom: var(--spacing-s);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  @include font-sans-m-semi;

  &.-disabled * {
    fill: var(--color-global-state-disabled-text-shape-default);
    color: var(--color-global-state-disabled-text-shape-default);
  }
  & > * {
    width:100%;
  }
}

.watchgroup-param__wrap,
.watchgroup-document__wrap {
  display: flex;
  padding: var(--spacing-xl) var(--spacing-xl) var(--spacing-xxl) var(--spacing-xl);
  flex-direction: column;
  justify-content: center;

  border-top: 1px solid var(--color-neutral-border-lighter);
}

.watchgroup-param__wrap {
  gap: var(--spacing-s);
}

.watchgroup-param__wrap-title {
  display: flex;
  padding-bottom: var(--spacing-s);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-xxs);
}

.watchgroup-param__title {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  @include font-sans-xl-strong;
  color: var(--color-neutral-text-shape-default);
}

.watchgroup-param__subtitle {
  @include font-sans-m-default;
  color: var(--color-neutral-text-shape-light);
}

.watchgroup-frequency__options {
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-m);
}

.watchgroup-document__wrap {
  gap: var(--spacing-xl);

  &.-disabled {
    background: var(--color-neutral-surface-light);
  }
}

.watchgroup-feature-preview {
  @include font-sans-m-default;
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-none);
  align-self: stretch;
  overflow: hidden;

  border-radius: var(--radius-rounded);
  border: 1px solid var(--color-neutral-border-lighter);
  background: var(--color-feedback-info-surface-lighter);
}

.watchgroup-feature-preview__header {
  display: flex;
  padding: var(--spacing-l) var(--spacing-xl);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-s);
  align-self: stretch;

  background: var(--color-feedback-info-surface-darker);
  color: var(--color-neutral-text-shape-inverted);

  svg {
    width: 4rem;
    height: 1.8rem;
  }
}

.watchgroup-feature-review__main {
  display: flex;
  padding: var(--spacing-s) var(--spacing-s) var(--spacing-xl) var(--spacing-s);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-XL, 1.6rem);
  align-self: stretch;
}

.beta-info {
  display: flex;
  padding: 0;
  align-items: center;
  gap: var(--spacing-xxs);
}

.beta-info__tooltip {
  color: var(--color-feedback-info-text-shape-default);

  &:hover {
    color: var(--color-feedback-info-text-shape-darker);
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;
  }

  &[hover-tooltip][tooltip-position="top"]:hover {
    &::before {
      border-radius: var(--radius-min);
      background: var(--color-feedback-info-surface-darker);
      color: var(--color-neutral-text-shape-inverted);
      left: unset;
    }

    &::after {
      margin-left: 3px;
      border-color: var(--color-feedback-info-surface-darker) transparent transparent;
    }
  }
}

.watchgroup-feature-preview__document-targets {
  display: flex;
  padding: var(--spacing-s);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xl);
  align-self: stretch;

  color: var(--color-feedback-info-text-shape-dark);

  .document-target__name {
    @include font-sans-m-semi;
  }
}

.watchgroup-document__options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.-disabled {
    .watchgroup-document__label,
    .watchgroup-document__description {
      color: var(--color-global-state-disabled-text-shape-default);
    }
  }
}

.watchgroup-document__option-wrap {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-m);
}

.watchgroup-document__label-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.watchgroup-document__label {
  color: var(--color-neutral-text-shape-default);
  @include font-sans-m-semi;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);
  align-items: center;

  .beta-flag {
    width: 3rem;
    height: 1.5rem;
    margin-left: 0;
  }
}

.watchgroup-document__description {
  color: var(--color-neutral-text-shape-default, #120B37);
  @include font-sans-m-default;
}

.watchgroup-document__beta-link {
  color: var(--color-neutral-text-shape-light);
  @include font-sans-m-link;
}

.watchgroup-param__selected {
  @include font-sans-m-semi;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--spacing-s);

  padding: var(--spacing-xs) var(--spacing-xl) var(--spacing-xs) var(--spacing-xl);
  color: var(--color-neutral-text-shape-default);
}

@media (max-width: $breakpoint-s){
  .watchgroup-param__selected {
    flex-direction: column;
    gap: var(--spacing-xxs);
  }
}

.watchgroup-param__selected-prefix {
  min-width: 16.6rem;
  color: var(--color-neutral-text-shape-light)
}

.watchgroup-param__selected-value {
  color: var(--color-neutral-text-shape-default);
}

.watchgroup__add {
  display: flex;
  align-items: center;
  gap: var(--spacing-xl);
  margin-top: var(--spacing-s);
  padding: var(--spacing-s) var(--spacing-xl);

  .formfield {
    flex-grow: 1;
  }
}

.watchgroup__modify,.watchgroup__form-end {
  padding: var(--spacing-l) var(--spacing-xl) var(--spacing-xl);
}

.watchgroup__form-end {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--color-neutral-border-light);
  gap: var(--spacing-xl);
}

.watchgroup__actions {
  display: flex;
  padding: var(--spacing-none);
  justify-content: space-between;
  align-items: flex-start;
}

.watchgroup__actions-group {
  display: flex;
  padding: var(--spacing-none);
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-xl);
}

.watchgroup__action-delete {
  display: flex;
  min-width: 4.8rem;
  min-height: 4.8rem;
  padding: var(--spacing-xxs) var(--spacing-s);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-none);
}

.watchgroup {
  .showmore__button:hover .showmore__banner-title {
    color: var(--color-feedback-info-text-shape-default);
  }
}
