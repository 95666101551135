.emptystate {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  color: var(--color-neutral-text-shape-default);
  background-color: var(--color-neutral-surface-light);
  border: 1px solid var(--color-neutral-border-light);

  .emptystate__title {
    color: var(--color-neutral-text-shape-default);
  }

    /* Sizes */

  &.-size-l,
  &.-size-l-m {
    padding: var(--spacing-xxl);
    @include font-sans-s-default;

    &:has(a) {
      padding-bottom: var(--spacing-xxl);
    }

    .emptystate__title {
      @include font-sans-l-strong;
    }

    .emptystate__body {
      @include font-sans-m-default;

      p:not(:last-of-type) {
        margin-bottom: var(--spacing-s);
      }
    }

    .emptystate__url {
      @include font-sans-m-link;
    }
  }

  &.-size-m {
    gap: var(--spacing-s);
    padding: var(--spacing-l);

    &:has(a) {
      padding-bottom: var(--spacing-xl);
    }

    .emptystate__title {
      @include font-sans-s-semi;
    }

    .emptystate__body {
      @include font-sans-s-default;

      p:not(:last-of-type) {
        margin-bottom: var(--spacing-s);
      }
    }

    .emptystate__url {
      @include font-sans-s-link;
    }
  }

  /* Level */

  &.-info {
    gap: var(--spacing-xs);
    color: var(--color-feedback-info-text-shape-dark);
    background-color: var(--color-feedback-info-surface-lighter);
    border: 1px solid var(--color-feedback-info-border-lighter);

    &.-size-m {
      gap: var(--spacing-xxs);
    }

    .emptystate__title {
      color: var(--color-feedback-info-text-shape-dark);
    }
  }
}

/* Responsive */

@media (max-width: $breakpoint-m) {
  .emptystate.-size-l-m {
    gap: var(--spacing-s);
    padding: var(--spacing-l);

    &:has(a) {
      padding-bottom: var(--spacing-xl);
    }

    .emptystate__title {
      @include font-sans-s-semi;
    }

    .emptystate__body {
      @include font-sans-s-default;

      p:not(:last-of-type) {
        margin-bottom: var(--spacing-s);
      }
    }

    .emptystate__url {
      @include font-sans-s-link;
    }
  }
}
