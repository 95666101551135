@use "mixins" as *;
@font-face {
  font-family: 'Produkt';
  src: url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/produkt/./Produkt-Bold-Web.woff2) format('woff2'),
       url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/produkt/./Produkt-Bold-Web.woff) format('woff');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Produkt';
  src: url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/produkt/./Produkt-Regular-Web.woff2) format('woff2'),
       url(/home/runner/work/contexte/contexte/apps/ui/static_src/ui/base/fonts/produkt/./Produkt-Regular-Web.woff) format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
  font-display: swap;
}
