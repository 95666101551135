/* Briefitem */


.editorial__briefitem-date {
  color: var(--color-neutral-text-shape-light);
  margin-bottom: var(--spacing-xxs);
}

.editorial__briefitem-title:visited {
  color: var(--color-neutral-text-shape-dark);
}
